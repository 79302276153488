@charset "UTF-8";
/*
 HARD Overrides
 */
.visuPlaceholder__layerstree {
  background-color: #ffffff !important; }

.visuPlaceholder {
  background-color: #ffffff !important; }

.skeleton {
  background: linear-gradient(to left, #ffffff, #ffffff);
  border: none; }

body {
  background-color: #ffffff !important; }

.bp3-dark .active .bp3-button.bp3-minimal.header-button {
  background: none !important;
  background-color: #e0896c !important; }

.bp3-dark .layerstree-group__label-button H5 {
  color: #3c3c3b !important; }

.bp3-dark .layerstree-node {
  color: #3c3c3b !important; }

.visualizer-view.is-layers-tree-visible .map-navigation {
  background: linear-gradient(to bottom, #ffffff, #ffffff) !important; }

.bp3-dark .map-navigation__content {
  scrollbar-color: #e94e1b #ffffff !important; }

.bp3-dark .filters-panel .bp3-select-popover .bp3-menu,
.bp3-dark .filters-panel__form {
  scrollbar-color: #e94e1b #ffffff !important; }

.bp3-dark .layerstree-group--active .bp3-collapse-body {
  background-color: #ffffff !important; }

.bp3-navbar.bp3-dark, .bp3-dark .bp3-navbar {
  background-color: #e94e1b !important; }

.bp3-dark .layerstree-node .layerstree-node-content__options__button--active {
  background: transparent !important; }
  .bp3-dark .layerstree-node .layerstree-node-content__options__button--active svg {
    filter: none !important;
    color: #ffffff; }

.bp3-dark .filters-panel__form {
  scrollbar-color: #ffffff !important; }

.filters-panel {
  background-color: #ffffff !important; }
  .filters-panel__arrow::after {
    background: #ffffff !important; }

.bp3-dark .mapboxgl-ctrl-search__input {
  color: #e94e1b; }

.bp3-card.bp3-elevation-0.search-results {
  background-color: #ffffff; }

.mapboxgl-ctrl .search-results__item.search-results__item--active button:not(:disabled):hover {
  background-color: #e94e1b; }

.bp3-dark .bp3-tag {
  background-color: #f4a78d;
  color: #182026; }

.bp3-dark .bp3-tag-input.bp3-active, .bp3-tag-input.bp3-dark.bp3-active {
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 0 1px #e94e1b, 0 0 0 1px #e94e1b, 0 0 0 3px rgba(233, 78, 27, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-input {
  background: white;
  box-shadow: 0 0 0 0 rgba(233, 78, 27, 0), 0 0 0 0 rgba(233, 78, 27, 0), 0 0 0 0 rgba(233, 78, 27, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
  color: #3c3c3b; }

.bp3-popover.bp3-dark .bp3-popover-content,
.bp3-dark .bp3-popover .bp3-popover-content {
  background-color: #e94e1b;
  color: #ffffff; }

.bp3-tooltip.bp3-dark .bp3-popover-arrow-fill,
.bp3-dark .bp3-tooltip .bp3-popover-arrow-fill {
  fill: #dadada; }

.visualizer .mapboxgl-popup .mapboxgl-popup-content {
  color: #fff;
  background-color: #e94e1b !important;
  font-size: 1.2em;
  padding: 5px 15px; }

.visualizer .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip, .visualizer .mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip, .visualizer .mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #e94e1b !important; }

.bp3-dark .bp3-button:not([class*="bp3-intent-"]) .bp3-icon.bp3-icon-info-sign, .bp3-dark .bp3-button:not([class*="bp3-intent-"]) .bp3-icon-large, .bp3-dark .bp3-button:not([class*="bp3-intent-"]) .bp3-icon-standard, .bp3-dark .bp3-button:not([class*="bp3-intent-"])[class*="bp3-icon-"]::before {
  color: #ffffff; }

.bp3-dark .bp3-button:not([class*="bp3-intent-"]) .bp3-icon.bp3-icon-log-in, .bp3-dark .bp3-button:not([class*="bp3-intent-"]) .bp3-icon-large, .bp3-dark .bp3-button:not([class*="bp3-intent-"]) .bp3-icon-standard, .bp3-dark .bp3-button:not([class*="bp3-intent-"])[class*="bp3-icon-"]::before {
  color: #ffffff; }

.bp3-dark .bp3-menu {
  background-color: #ffffff !important; }

.modal-mentions-legales .mentions-legales {
  color: #ffffff; }

.bp3-button:not([class*="bp3-intent-"]) {
  background-color: #e94e1b; }

label.bp3-control.bp3-radio.bgLayer-radio {
  color: #ffffff; }

/* Carte */
.bp3-dark .mapboxgl-ctrl-group {
  background-color: #e94e1b !important; }

.bp3-dark .mapboxgl-ctrl-group.mapboxgl-ctrl-home button:hover,
.bp3-dark .mapboxgl-ctrl-group.mapboxgl-ctrl-home button:focus,
.bp3-dark .mapboxgl-ctrl-group.mapboxgl-ctrl-print button:hover,
.bp3-dark .mapboxgl-ctrl-group.mapboxgl-ctrl-print button:focus,
.bp3-dark .mapboxgl-ctrl-group.mapboxgl-ctrl-share button:hover,
.bp3-dark .mapboxgl-ctrl-group.mapboxgl-ctrl-share button:focus,
.bp3-dark .mapboxgl-ctrl-group.mapboxgl-ctrl-report button:hover,
.bp3-dark .mapboxgl-ctrl-group.mapboxgl-ctrl-report button:focus,
.bp3-dark .mapboxgl-ctrl-group .mapboxgl-ctrl-background-styles button:hover,
.bp3-dark .mapboxgl-ctrl-group .mapboxgl-ctrl-background-styles button:focus {
  background-color: #e94e1b; }

/* Mini fiche */
.view-details .details__subtitle {
  color: #e94e1b; }

/*

Copyright 2015-present Palantir Technologies, Inc. All rights reserved.
Licensed under the Apache License, Version 2.0.

*/
html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.28581;
  text-transform: none;
  color: #182026;
  font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "Icons16", sans-serif; }

p {
  margin-bottom: 10px;
  margin-top: 0; }

small {
  font-size: 12px; }

strong {
  font-weight: 600; }

::selection {
  background: rgba(125, 188, 255, 0.6); }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
Headings

Markup:
<div>
  <h1 class="@ns-heading">H1 heading</h1>
  <h2 class="@ns-heading">H2 heading</h2>
  <h3 class="@ns-heading">H3 heading</h3>
  <h4 class="@ns-heading">H4 heading</h4>
  <h5 class="@ns-heading">H5 heading</h5>
  <h6 class="@ns-heading">H6 heading</h6>
</div>

Styleguide headings
*/
.bp3-heading {
  color: #182026;
  font-weight: 600;
  margin: 0 0 10px;
  padding: 0; }
  .bp3-dark .bp3-heading {
    color: #3c3c3b; }

h1.bp3-heading, .bp3-running-text h1 {
  font-size: 36px;
  line-height: 40px; }

h2.bp3-heading, .bp3-running-text h2 {
  font-size: 28px;
  line-height: 32px; }

h3.bp3-heading, .bp3-running-text h3 {
  font-size: 22px;
  line-height: 25px; }

h4.bp3-heading, .bp3-running-text h4 {
  font-size: 18px;
  line-height: 21px; }

h5.bp3-heading, .bp3-running-text h5 {
  font-size: 16px;
  line-height: 19px; }

h6.bp3-heading, .bp3-running-text h6 {
  font-size: 14px;
  line-height: 16px; }

/*
UI text

Markup:
<div class="{{.modifier}}">
  More than a decade ago, we set out to create products that would transform
  the way organizations use their data. Today, our products are deployed at
  the most critical government, commercial, and non-profit institutions in
  the world to solve problems we hadn’t even dreamed of back then.
</div>

.bp3-ui-text - Default Blueprint font styles, applied to the `<body>` tag and available as a class for nested resets.
.bp3-monospace-text - Use a monospace font (ideal for code).
.bp3-running-text - Increase line height ideal for longform text. See [Running text](#core/typography.running-text) below for additional features.
.bp3-text-large - Use a larger font size.
.bp3-text-small - Use a smaller font size.
.bp3-text-muted - Change text color to a gentler gray.
.bp3-text-disabled - Change text color to a transparent, faded gray.
.bp3-text-overflow-ellipsis - Truncate a single line of text with an ellipsis if it overflows its container.

Styleguide ui-text
*/
.bp3-ui-text {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.28581;
  text-transform: none; }

.bp3-monospace-text {
  font-family: monospace;
  text-transform: none; }

.bp3-text-muted {
  color: #ffffff; }
  .bp3-dark .bp3-text-muted {
    color: #e94e1b; }

.bp3-text-disabled {
  color: rgba(255, 255, 255, 0.6); }
  .bp3-dark .bp3-text-disabled {
    color: rgba(233, 78, 27, 0.6); }

.bp3-text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal; }

/*
Running text

Markup:
<div class="@ns-running-text {{.modifier}}">
  <p>
    We build products that make people better at their most important
    work — the kind of work you read about on the front page of the
    newspaper, not just the technology section.
  </p>
  <ul>
    <li>Item the <code>first</code>.</li>
    <li>Item the <strong>second</strong></code>.</li>
    <li>Item the <a href="#core/typography.running-text">third</a>.</li>
  </ul>
  <h3>Scale, Speed, Agility</h3>
  <p>
    A successful data transformation requires the whole organization — users, the IT shop, and
    leadership — to operate in lockstep. With Foundry, the enterprise comes together to
    transform the organization and turn data into a competitive advantage.
  </p>
</div>

.bp3-text-large - Use larger font size.

Styleguide running-text
*/
.bp3-running-text {
  font-size: 14px;
  line-height: 1.5; }
  .bp3-running-text h1 {
    color: #182026;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 40px; }
    .bp3-dark .bp3-running-text h1 {
      color: #3c3c3b; }
  .bp3-running-text h2 {
    color: #182026;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 40px; }
    .bp3-dark .bp3-running-text h2 {
      color: #3c3c3b; }
  .bp3-running-text h3 {
    color: #182026;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 40px; }
    .bp3-dark .bp3-running-text h3 {
      color: #3c3c3b; }
  .bp3-running-text h4 {
    color: #182026;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 40px; }
    .bp3-dark .bp3-running-text h4 {
      color: #3c3c3b; }
  .bp3-running-text h5 {
    color: #182026;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 40px; }
    .bp3-dark .bp3-running-text h5 {
      color: #3c3c3b; }
  .bp3-running-text h6 {
    color: #182026;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 40px; }
    .bp3-dark .bp3-running-text h6 {
      color: #3c3c3b; }
  .bp3-running-text hr {
    border: none;
    border-bottom: 1px solid rgba(16, 22, 26, 0.15);
    margin: 20px 0; }
    .bp3-dark .bp3-running-text hr {
      border-color: rgba(255, 255, 255, 0.15); }
  .bp3-running-text p {
    margin: 0 0 10px;
    padding: 0; }

.bp3-text-large {
  font-size: 16px; }

.bp3-text-small {
  font-size: 12px; }

/*
Links

Simply use an `<a href="">` tag as you normally would. No class is necessary for Blueprint styles.
Links are underlined only when hovered.

Putting an icon inside a link will cause it to inherit the link's text color.

Styleguide typography.links
*/
a {
  color: #e94e1b;
  text-decoration: none; }
  a:hover {
    color: #e94e1b;
    cursor: pointer;
    text-decoration: underline; }
  a .bp3-icon, a .bp3-icon-standard, a .bp3-icon-large {
    color: inherit; }
  a code,
  .bp3-dark a code {
    color: inherit; }
  .bp3-dark a,
  .bp3-dark a:hover {
    color: #ffffff; }
    .bp3-dark a .bp3-icon, .bp3-dark a .bp3-icon-standard, .bp3-dark a .bp3-icon-large,
    .bp3-dark a:hover .bp3-icon,
    .bp3-dark a:hover .bp3-icon-standard,
    .bp3-dark a:hover .bp3-icon-large {
      color: inherit; }

/*
Preformatted text

Markup:
<div>
  <p>Use the <code class="@ns-code">&lt;code></code> tag for snippets of code.</p>
  <pre class="@ns-code-block">Use the &lt;pre> tag for blocks of code.</pre>
  <pre class="@ns-code-block"><code>// code sample
export function hasModifier(
  modifiers: ts.ModifiersArray,
  ...modifierKinds: ts.SyntaxKind[],
) {
  if (modifiers == null || modifierKinds == null) {
    return false;
  }
  return modifiers.some(m => modifierKinds.some(k => m.kind === k));
}</code></pre>
</div>

Styleguide preformatted
*/
.bp3-running-text code, .bp3-code {
  font-family: monospace;
  text-transform: none;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2);
  color: #ffffff;
  font-size: smaller;
  padding: 2px 5px; }
  .bp3-dark .bp3-running-text code, .bp3-running-text .bp3-dark code, .bp3-dark .bp3-code {
    background: rgba(16, 22, 26, 0.3);
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4);
    color: #e94e1b; }
  .bp3-running-text a > code, a > .bp3-code {
    color: #e94e1b; }
    .bp3-dark .bp3-running-text a > code, .bp3-running-text .bp3-dark a > code, .bp3-dark a > .bp3-code {
      color: inherit; }

.bp3-running-text pre, .bp3-code-block {
  font-family: monospace;
  text-transform: none;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.15);
  color: #182026;
  display: block;
  font-size: 13px;
  line-height: 1.4;
  margin: 10px 0;
  padding: 13px 15px 12px;
  word-break: break-all;
  word-wrap: break-word; }
  .bp3-dark .bp3-running-text pre, .bp3-running-text .bp3-dark pre, .bp3-dark .bp3-code-block {
    background: rgba(16, 22, 26, 0.3);
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4);
    color: #3c3c3b; }
  .bp3-running-text pre > code, .bp3-code-block > code {
    background: none;
    box-shadow: none;
    color: inherit;
    font-size: inherit;
    padding: 0; }

.bp3-running-text kbd, .bp3-key {
  align-items: center;
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
  color: #ffffff;
  display: inline-flex;
  font-family: inherit;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 24px;
  min-width: 24px;
  padding: 3px 6px;
  vertical-align: middle; }
  .bp3-running-text kbd .bp3-icon, .bp3-key .bp3-icon, .bp3-running-text kbd .bp3-icon-standard, .bp3-key .bp3-icon-standard, .bp3-running-text kbd .bp3-icon-large, .bp3-key .bp3-icon-large {
    margin-right: 5px; }
  .bp3-dark .bp3-running-text kbd, .bp3-running-text .bp3-dark kbd, .bp3-dark .bp3-key {
    background: #ffffff;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4);
    color: #e94e1b; }

/*
Block quotes

Markup:
<blockquote class="@ns-blockquote">
  Premium Aerotec is a key supplier for Airbus, producing 30 million parts per year,
  which is huge complexity. Skywise helps us manage all the production steps.
  It gives Airbus much better visibility into where the product is in the supply chain,
  and it lets us immediately see our weak points so we can react on the spot.
</blockquote>

Styleguide blockquote
*/
.bp3-running-text blockquote, .bp3-blockquote {
  border-left: solid 4px rgba(233, 78, 27, 0.5);
  margin: 0 0 10px;
  padding: 0 20px; }
  .bp3-dark .bp3-running-text blockquote, .bp3-running-text .bp3-dark blockquote, .bp3-dark .bp3-blockquote {
    border-color: rgba(233, 78, 27, 0.5); }

/*
Lists

Markup:
<ol class="{{.modifier}}">
  <li>Item the first</li>
  <li>Item the second</li>
  <li>
    Item the third
    <ul class="{{.modifier}}">
      <li>Item the fourth</li>
      <li>Item the fifth</li>
    </ul>
  </li>
</ol>

.bp3-list - Add a little spacing between items for readability.
.bp3-list-unstyled - Remove all list styling (including indicators) so you can add your own.

Styleguide lists
*/
.bp3-running-text ul,
.bp3-running-text ol, .bp3-list {
  margin: 10px 0;
  padding-left: 30px; }
  .bp3-running-text ul li:not(:last-child), .bp3-running-text ol li:not(:last-child), .bp3-list li:not(:last-child) {
    margin-bottom: 5px; }
  .bp3-running-text ul ol, .bp3-running-text ol ol, .bp3-list ol,
  .bp3-running-text ul ul,
  .bp3-running-text ol ul,
  .bp3-list ul {
    margin-top: 5px; }

.bp3-list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0; }
  .bp3-list-unstyled li {
    padding: 0; }

/*
Right-to-left text

Markup:
<h5 class="@ns-heading">Arabic:</h5>
<p class="@ns-rtl">
  لكل لأداء بمحاولة من. مدينة الواقعة يبق أي, وإعلان وقوعها، حول كل, حدى عجّل مشروط الخاسرة قد.
  من الذود تكبّد بين, و لها واحدة الأراضي. عل الصفحة والروسية يتم, أي للحكومة استعملت شيء. أم وصل زهاء اليا
</p>
<h5 class="@ns-heading">Hebrew:</h5>
<p class="@ns-rtl">
  כדי על עזרה יידיש הבהרה, מלא באגים טכניים דת. תנך או ברית ביולי. כתב בה הטבע למנוע, דת כלים פיסיקה החופשית זכר.
  מתן החלל מאמרשיחהצפה ב. הספרות אנציקלופדיה אם זכר, על שימושי שימושיים תאולוגיה עזה
</p>

Styleguide rtl
*/
.bp3-rtl {
  text-align: right; }

.bp3-dark {
  color: #3c3c3b; }

:focus {
  outline: rgba(233, 78, 27, 0.6) auto 2px;
  outline-offset: 2px;
  -moz-outline-radius: 6px; }

.bp3-focus-disabled :focus {
  outline: none !important; }
  .bp3-focus-disabled :focus ~ .bp3-control-indicator {
    outline: none !important; }

.bp3-alert {
  max-width: 400px;
  padding: 20px; }

.bp3-alert-body {
  display: flex; }
  .bp3-alert-body .bp3-icon {
    font-size: 40px;
    margin-right: 20px;
    margin-top: 0; }

.bp3-alert-contents {
  word-break: break-word; }

.bp3-alert-footer {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px; }
  .bp3-alert-footer .bp3-button {
    margin-left: 10px; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
Breadcrumbs

Markup:
<ul class="bp3-breadcrumbs">
  <li><a class="bp3-breadcrumbs-collapsed" href="#"></a></li>
  <li><a class="bp3-breadcrumb bp3-disabled">Folder one</a></li>
  <li><a class="bp3-breadcrumb" href="#">Folder two</a></li>
  <li><a class="bp3-breadcrumb" href="#">Folder three</a></li>
  <li><span class="bp3-breadcrumb bp3-breadcrumb-current">File</span></li>
</ul>

Styleguide breadcrumbs
*/
.bp3-breadcrumbs {
  align-items: center;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  height: 30px;
  list-style: none;
  margin: 0;
  padding: 0; }
  .bp3-breadcrumbs > li {
    align-items: center;
    display: flex; }
    .bp3-breadcrumbs > li::after {
      background: svg-icon("16px/chevron-right.svg", (path: (fill: #ffffff)));
      content: "";
      display: block;
      height: 16px;
      margin: 0 5px;
      width: 16px; }
    .bp3-breadcrumbs > li:last-of-type::after {
      display: none; }

.bp3-breadcrumb,
.bp3-breadcrumb-current,
.bp3-breadcrumbs-collapsed {
  align-items: center;
  display: inline-flex;
  font-size: 16px; }

.bp3-breadcrumb,
.bp3-breadcrumbs-collapsed {
  color: #ffffff; }

.bp3-breadcrumb:hover {
  text-decoration: none; }

.bp3-breadcrumb.bp3-disabled {
  color: rgba(255, 255, 255, 0.6);
  cursor: not-allowed; }

.bp3-breadcrumb .bp3-icon {
  margin-right: 5px; }

.bp3-breadcrumb-current {
  color: inherit;
  font-weight: 600; }
  .bp3-breadcrumb-current .bp3-input {
    font-size: inherit;
    font-weight: inherit;
    vertical-align: baseline; }

.bp3-breadcrumbs-collapsed {
  background: #ffffff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 2px;
  padding: 1px 5px;
  vertical-align: text-bottom; }
  .bp3-breadcrumbs-collapsed::before {
    background: svg-icon("16px/more.svg", (circle: (fill: #ffffff))) center no-repeat;
    content: "";
    display: block;
    height: 16px;
    width: 16px; }
  .bp3-breadcrumbs-collapsed:hover {
    background: #ffffff;
    color: #182026;
    text-decoration: none; }

.bp3-dark .bp3-breadcrumb,
.bp3-dark .bp3-breadcrumbs-collapsed {
  color: #e94e1b; }

.bp3-dark .bp3-breadcrumbs > li::after {
  color: #e94e1b; }

.bp3-dark .bp3-breadcrumb.bp3-disabled {
  color: rgba(233, 78, 27, 0.6); }

.bp3-dark .bp3-breadcrumb-current {
  color: #3c3c3b; }

.bp3-dark .bp3-breadcrumbs-collapsed {
  background: rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-breadcrumbs-collapsed:hover {
    background: rgba(16, 22, 26, 0.6);
    color: #3c3c3b; }

/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/*
Button

Markup:
<a role="button" class="bp3-button {{.modifier}}" {{:modifier}} tabindex="0">Anchor</a>
<button type="button" class="bp3-button bp3-icon-add {{.modifier}}" {{:modifier}}>Button</button>

:disabled - Disabled state
.bp3-active - Active appearance
.bp3-disabled - Disabled appearance
.bp3-intent-primary - Primary intent
.bp3-intent-success - Success intent
.bp3-intent-warning - Warning intent
.bp3-intent-danger - Danger intent
.bp3-minimal - More subtle appearance
.bp3-outlined - Subtle yet structured appearance
.bp3-large - Larger size
.bp3-small - Smaller size
.bp3-fill - Fill parent container

Styleguide button
*/
.bp3-button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  justify-content: center;
  padding: 5px 10px;
  text-align: left;
  vertical-align: middle;
  min-height: 30px;
  min-width: 30px; }
  .bp3-button > * {
    flex-grow: 0;
    flex-shrink: 0; }
  .bp3-button > .bp3-fill {
    flex-grow: 1;
    flex-shrink: 1; }
  .bp3-button::before,
  .bp3-button > * {
    margin-right: 7px; }
  .bp3-button:empty::before,
  .bp3-button > :last-child {
    margin-right: 0; }
  .bp3-button:empty {
    padding: 0 !important; }
  .bp3-button:disabled, .bp3-button.bp3-disabled {
    cursor: not-allowed; }
  .bp3-button.bp3-fill {
    display: flex;
    width: 100%; }
  .bp3-button.bp3-align-right,
  .bp3-align-right .bp3-button {
    text-align: right; }
  .bp3-button.bp3-align-left,
  .bp3-align-left .bp3-button {
    text-align: left; }
  .bp3-button:not([class*="bp3-intent-"]) {
    background-color: #3c3c3b;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
    color: #182026; }
    .bp3-button:not([class*="bp3-intent-"]):hover {
      background-clip: padding-box;
      background-color: #e94e1b;
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1); }
    .bp3-button:not([class*="bp3-intent-"]):active, .bp3-button:not([class*="bp3-intent-"]).bp3-active {
      background-color: #e94e1b;
      background-image: none;
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
    .bp3-button:not([class*="bp3-intent-"]):disabled, .bp3-button:not([class*="bp3-intent-"]).bp3-disabled {
      background-color: rgba(255, 255, 255, 0.5);
      background-image: none;
      box-shadow: none;
      color: rgba(255, 255, 255, 0.6);
      cursor: not-allowed;
      outline: none; }
      .bp3-button:not([class*="bp3-intent-"]):disabled.bp3-active, .bp3-button:not([class*="bp3-intent-"]):disabled.bp3-active:hover, .bp3-button:not([class*="bp3-intent-"]).bp3-disabled.bp3-active, .bp3-button:not([class*="bp3-intent-"]).bp3-disabled.bp3-active:hover {
        background: rgba(255, 255, 255, 0.7); }
  .bp3-button.bp3-intent-primary {
    background-color: #e94e1b;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
    color: #ffffff; }
    .bp3-button.bp3-intent-primary:hover, .bp3-button.bp3-intent-primary:active, .bp3-button.bp3-intent-primary.bp3-active {
      color: #ffffff; }
    .bp3-button.bp3-intent-primary:hover {
      background-color: #e94e1b;
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2); }
    .bp3-button.bp3-intent-primary:active, .bp3-button.bp3-intent-primary.bp3-active {
      background-color: #ffffff;
      background-image: none;
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
    .bp3-button.bp3-intent-primary:disabled, .bp3-button.bp3-intent-primary.bp3-disabled {
      background-color: rgba(233, 78, 27, 0.5);
      background-image: none;
      border-color: transparent;
      box-shadow: none;
      color: rgba(255, 255, 255, 0.6); }
  .bp3-button.bp3-intent-success {
    background-color: #0f9960;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
    color: #ffffff; }
    .bp3-button.bp3-intent-success:hover, .bp3-button.bp3-intent-success:active, .bp3-button.bp3-intent-success.bp3-active {
      color: #ffffff; }
    .bp3-button.bp3-intent-success:hover {
      background-color: #0d8050;
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2); }
    .bp3-button.bp3-intent-success:active, .bp3-button.bp3-intent-success.bp3-active {
      background-color: #0a6640;
      background-image: none;
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
    .bp3-button.bp3-intent-success:disabled, .bp3-button.bp3-intent-success.bp3-disabled {
      background-color: rgba(15, 153, 96, 0.5);
      background-image: none;
      border-color: transparent;
      box-shadow: none;
      color: rgba(255, 255, 255, 0.6); }
  .bp3-button.bp3-intent-warning {
    background-color: #d9822b;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
    color: #ffffff; }
    .bp3-button.bp3-intent-warning:hover, .bp3-button.bp3-intent-warning:active, .bp3-button.bp3-intent-warning.bp3-active {
      color: #ffffff; }
    .bp3-button.bp3-intent-warning:hover {
      background-color: #bf7326;
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2); }
    .bp3-button.bp3-intent-warning:active, .bp3-button.bp3-intent-warning.bp3-active {
      background-color: #a66321;
      background-image: none;
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
    .bp3-button.bp3-intent-warning:disabled, .bp3-button.bp3-intent-warning.bp3-disabled {
      background-color: rgba(217, 130, 43, 0.5);
      background-image: none;
      border-color: transparent;
      box-shadow: none;
      color: rgba(255, 255, 255, 0.6); }
  .bp3-button.bp3-intent-danger {
    background-color: #db3737;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
    color: #ffffff; }
    .bp3-button.bp3-intent-danger:hover, .bp3-button.bp3-intent-danger:active, .bp3-button.bp3-intent-danger.bp3-active {
      color: #ffffff; }
    .bp3-button.bp3-intent-danger:hover {
      background-color: #c23030;
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2); }
    .bp3-button.bp3-intent-danger:active, .bp3-button.bp3-intent-danger.bp3-active {
      background-color: #a82a2a;
      background-image: none;
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
    .bp3-button.bp3-intent-danger:disabled, .bp3-button.bp3-intent-danger.bp3-disabled {
      background-color: rgba(219, 55, 55, 0.5);
      background-image: none;
      border-color: transparent;
      box-shadow: none;
      color: rgba(255, 255, 255, 0.6); }
  .bp3-button[class*="bp3-intent-"] .bp3-button-spinner .bp3-spinner-head {
    stroke: #ffffff; }
  .bp3-button.bp3-large,
  .bp3-large .bp3-button {
    min-height: 40px;
    min-width: 40px;
    font-size: 16px;
    padding: 5px 15px; }
    .bp3-button.bp3-large::before,
    .bp3-button.bp3-large > *,
    .bp3-large .bp3-button::before,
    .bp3-large .bp3-button > * {
      margin-right: 10px; }
    .bp3-button.bp3-large:empty::before,
    .bp3-button.bp3-large > :last-child,
    .bp3-large .bp3-button:empty::before,
    .bp3-large .bp3-button > :last-child {
      margin-right: 0; }
  .bp3-button.bp3-small,
  .bp3-small .bp3-button {
    min-height: 24px;
    min-width: 24px;
    padding: 0 7px; }
  .bp3-button.bp3-loading {
    position: relative; }
    .bp3-button.bp3-loading[class*="bp3-icon-"]::before {
      visibility: hidden; }
    .bp3-button.bp3-loading .bp3-button-spinner {
      margin: 0;
      position: absolute; }
    .bp3-button.bp3-loading > :not(.bp3-button-spinner) {
      visibility: hidden; }
  .bp3-button[class*="bp3-icon-"]::before {
    font-family: "Icons16", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: #ffffff; }
  .bp3-button .bp3-icon, .bp3-button .bp3-icon-standard, .bp3-button .bp3-icon-large {
    color: #ffffff; }
    .bp3-button .bp3-icon.bp3-align-right, .bp3-button .bp3-icon-standard.bp3-align-right, .bp3-button .bp3-icon-large.bp3-align-right {
      margin-left: 7px; }
  .bp3-button .bp3-icon:first-child:last-child,
  .bp3-button .bp3-spinner + .bp3-icon:last-child {
    margin: 0 -7px; }
  .bp3-dark .bp3-button:not([class*="bp3-intent-"]) {
    background-color: #ffffff;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
    color: #3c3c3b; }
    .bp3-dark .bp3-button:not([class*="bp3-intent-"]):hover, .bp3-dark .bp3-button:not([class*="bp3-intent-"]):active, .bp3-dark .bp3-button:not([class*="bp3-intent-"]).bp3-active {
      color: #3c3c3b; }
    .bp3-dark .bp3-button:not([class*="bp3-intent-"]):hover {
      background-color: #dadada;
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-button:not([class*="bp3-intent-"]):active, .bp3-dark .bp3-button:not([class*="bp3-intent-"]).bp3-active {
      background-color: #ffffff;
      background-image: none;
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
    .bp3-dark .bp3-button:not([class*="bp3-intent-"]):disabled, .bp3-dark .bp3-button:not([class*="bp3-intent-"]).bp3-disabled {
      background-color: rgba(255, 255, 255, 0.5);
      background-image: none;
      box-shadow: none;
      color: rgba(233, 78, 27, 0.6); }
      .bp3-dark .bp3-button:not([class*="bp3-intent-"]):disabled.bp3-active, .bp3-dark .bp3-button:not([class*="bp3-intent-"]).bp3-disabled.bp3-active {
        background: rgba(255, 255, 255, 0.7); }
    .bp3-dark .bp3-button:not([class*="bp3-intent-"]) .bp3-button-spinner .bp3-spinner-head {
      background: rgba(16, 22, 26, 0.5);
      stroke: #e94e1b; }
    .bp3-dark .bp3-button:not([class*="bp3-intent-"])[class*="bp3-icon-"]::before {
      color: #e94e1b; }
    .bp3-dark .bp3-button:not([class*="bp3-intent-"]) .bp3-icon, .bp3-dark .bp3-button:not([class*="bp3-intent-"]) .bp3-icon-standard, .bp3-dark .bp3-button:not([class*="bp3-intent-"]) .bp3-icon-large {
      color: #e94e1b; }
  .bp3-dark .bp3-button[class*="bp3-intent-"] {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-button[class*="bp3-intent-"]:hover {
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-button[class*="bp3-intent-"]:active, .bp3-dark .bp3-button[class*="bp3-intent-"].bp3-active {
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
    .bp3-dark .bp3-button[class*="bp3-intent-"]:disabled, .bp3-dark .bp3-button[class*="bp3-intent-"].bp3-disabled {
      background-image: none;
      box-shadow: none;
      color: rgba(255, 255, 255, 0.3); }
    .bp3-dark .bp3-button[class*="bp3-intent-"] .bp3-button-spinner .bp3-spinner-head {
      stroke: #e94e1b; }
  .bp3-button:disabled::before,
  .bp3-button:disabled .bp3-icon, .bp3-button:disabled .bp3-icon-standard, .bp3-button:disabled .bp3-icon-large, .bp3-button.bp3-disabled::before,
  .bp3-button.bp3-disabled .bp3-icon, .bp3-button.bp3-disabled .bp3-icon-standard, .bp3-button.bp3-disabled .bp3-icon-large, .bp3-button[class*="bp3-intent-"]::before,
  .bp3-button[class*="bp3-intent-"] .bp3-icon, .bp3-button[class*="bp3-intent-"] .bp3-icon-standard, .bp3-button[class*="bp3-intent-"] .bp3-icon-large {
    color: inherit !important; }
  .bp3-button.bp3-minimal {
    background: none;
    box-shadow: none; }
    .bp3-button.bp3-minimal:hover {
      background: rgba(233, 78, 27, 0.3);
      box-shadow: none;
      color: #182026;
      text-decoration: none; }
    .bp3-button.bp3-minimal:active, .bp3-button.bp3-minimal.bp3-active {
      background: rgba(233, 78, 27, 0.3);
      box-shadow: none;
      color: #182026; }
    .bp3-button.bp3-minimal:disabled, .bp3-button.bp3-minimal:disabled:hover, .bp3-button.bp3-minimal.bp3-disabled, .bp3-button.bp3-minimal.bp3-disabled:hover {
      background: none;
      color: rgba(255, 255, 255, 0.6);
      cursor: not-allowed; }
      .bp3-button.bp3-minimal:disabled.bp3-active, .bp3-button.bp3-minimal:disabled:hover.bp3-active, .bp3-button.bp3-minimal.bp3-disabled.bp3-active, .bp3-button.bp3-minimal.bp3-disabled:hover.bp3-active {
        background: rgba(233, 78, 27, 0.3); }
    .bp3-dark .bp3-button.bp3-minimal {
      background: none;
      box-shadow: none;
      color: inherit; }
      .bp3-dark .bp3-button.bp3-minimal:hover, .bp3-dark .bp3-button.bp3-minimal:active, .bp3-dark .bp3-button.bp3-minimal.bp3-active {
        background: none;
        box-shadow: none; }
      .bp3-dark .bp3-button.bp3-minimal:hover {
        background: rgba(233, 78, 27, 0.15); }
      .bp3-dark .bp3-button.bp3-minimal:active, .bp3-dark .bp3-button.bp3-minimal.bp3-active {
        background: rgba(233, 78, 27, 0.3);
        color: #3c3c3b; }
      .bp3-dark .bp3-button.bp3-minimal:disabled, .bp3-dark .bp3-button.bp3-minimal:disabled:hover, .bp3-dark .bp3-button.bp3-minimal.bp3-disabled, .bp3-dark .bp3-button.bp3-minimal.bp3-disabled:hover {
        background: none;
        color: rgba(233, 78, 27, 0.6);
        cursor: not-allowed; }
        .bp3-dark .bp3-button.bp3-minimal:disabled.bp3-active, .bp3-dark .bp3-button.bp3-minimal:disabled:hover.bp3-active, .bp3-dark .bp3-button.bp3-minimal.bp3-disabled.bp3-active, .bp3-dark .bp3-button.bp3-minimal.bp3-disabled:hover.bp3-active {
          background: rgba(233, 78, 27, 0.3); }
    .bp3-button.bp3-minimal.bp3-intent-primary {
      color: #e94e1b; }
      .bp3-button.bp3-minimal.bp3-intent-primary:hover, .bp3-button.bp3-minimal.bp3-intent-primary:active, .bp3-button.bp3-minimal.bp3-intent-primary.bp3-active {
        background: none;
        box-shadow: none;
        color: #e94e1b; }
      .bp3-button.bp3-minimal.bp3-intent-primary:hover {
        background: rgba(233, 78, 27, 0.15);
        color: #e94e1b; }
      .bp3-button.bp3-minimal.bp3-intent-primary:active, .bp3-button.bp3-minimal.bp3-intent-primary.bp3-active {
        background: rgba(233, 78, 27, 0.3);
        color: #e94e1b; }
      .bp3-button.bp3-minimal.bp3-intent-primary:disabled, .bp3-button.bp3-minimal.bp3-intent-primary.bp3-disabled {
        background: none;
        color: rgba(233, 78, 27, 0.5); }
        .bp3-button.bp3-minimal.bp3-intent-primary:disabled.bp3-active, .bp3-button.bp3-minimal.bp3-intent-primary.bp3-disabled.bp3-active {
          background: rgba(233, 78, 27, 0.3); }
      .bp3-button.bp3-minimal.bp3-intent-primary .bp3-button-spinner .bp3-spinner-head {
        stroke: #e94e1b; }
      .bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary {
        color: #ffffff; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary:hover {
          background: rgba(233, 78, 27, 0.2);
          color: #ffffff; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary:active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary.bp3-active {
          background: rgba(233, 78, 27, 0.3);
          color: #ffffff; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary:disabled, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary.bp3-disabled {
          background: none;
          color: rgba(255, 255, 255, 0.5); }
          .bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary:disabled.bp3-active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-primary.bp3-disabled.bp3-active {
            background: rgba(233, 78, 27, 0.3); }
    .bp3-button.bp3-minimal.bp3-intent-success {
      color: #0d8050; }
      .bp3-button.bp3-minimal.bp3-intent-success:hover, .bp3-button.bp3-minimal.bp3-intent-success:active, .bp3-button.bp3-minimal.bp3-intent-success.bp3-active {
        background: none;
        box-shadow: none;
        color: #0d8050; }
      .bp3-button.bp3-minimal.bp3-intent-success:hover {
        background: rgba(15, 153, 96, 0.15);
        color: #0d8050; }
      .bp3-button.bp3-minimal.bp3-intent-success:active, .bp3-button.bp3-minimal.bp3-intent-success.bp3-active {
        background: rgba(15, 153, 96, 0.3);
        color: #0d8050; }
      .bp3-button.bp3-minimal.bp3-intent-success:disabled, .bp3-button.bp3-minimal.bp3-intent-success.bp3-disabled {
        background: none;
        color: rgba(13, 128, 80, 0.5); }
        .bp3-button.bp3-minimal.bp3-intent-success:disabled.bp3-active, .bp3-button.bp3-minimal.bp3-intent-success.bp3-disabled.bp3-active {
          background: rgba(15, 153, 96, 0.3); }
      .bp3-button.bp3-minimal.bp3-intent-success .bp3-button-spinner .bp3-spinner-head {
        stroke: #0d8050; }
      .bp3-dark .bp3-button.bp3-minimal.bp3-intent-success {
        color: #3dcc91; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-success:hover {
          background: rgba(15, 153, 96, 0.2);
          color: #3dcc91; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-success:active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-success.bp3-active {
          background: rgba(15, 153, 96, 0.3);
          color: #3dcc91; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-success:disabled, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-success.bp3-disabled {
          background: none;
          color: rgba(61, 204, 145, 0.5); }
          .bp3-dark .bp3-button.bp3-minimal.bp3-intent-success:disabled.bp3-active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-success.bp3-disabled.bp3-active {
            background: rgba(15, 153, 96, 0.3); }
    .bp3-button.bp3-minimal.bp3-intent-warning {
      color: #bf7326; }
      .bp3-button.bp3-minimal.bp3-intent-warning:hover, .bp3-button.bp3-minimal.bp3-intent-warning:active, .bp3-button.bp3-minimal.bp3-intent-warning.bp3-active {
        background: none;
        box-shadow: none;
        color: #bf7326; }
      .bp3-button.bp3-minimal.bp3-intent-warning:hover {
        background: rgba(217, 130, 43, 0.15);
        color: #bf7326; }
      .bp3-button.bp3-minimal.bp3-intent-warning:active, .bp3-button.bp3-minimal.bp3-intent-warning.bp3-active {
        background: rgba(217, 130, 43, 0.3);
        color: #bf7326; }
      .bp3-button.bp3-minimal.bp3-intent-warning:disabled, .bp3-button.bp3-minimal.bp3-intent-warning.bp3-disabled {
        background: none;
        color: rgba(191, 115, 38, 0.5); }
        .bp3-button.bp3-minimal.bp3-intent-warning:disabled.bp3-active, .bp3-button.bp3-minimal.bp3-intent-warning.bp3-disabled.bp3-active {
          background: rgba(217, 130, 43, 0.3); }
      .bp3-button.bp3-minimal.bp3-intent-warning .bp3-button-spinner .bp3-spinner-head {
        stroke: #bf7326; }
      .bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning {
        color: #ffb366; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning:hover {
          background: rgba(217, 130, 43, 0.2);
          color: #ffb366; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning:active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning.bp3-active {
          background: rgba(217, 130, 43, 0.3);
          color: #ffb366; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning:disabled, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning.bp3-disabled {
          background: none;
          color: rgba(255, 179, 102, 0.5); }
          .bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning:disabled.bp3-active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-warning.bp3-disabled.bp3-active {
            background: rgba(217, 130, 43, 0.3); }
    .bp3-button.bp3-minimal.bp3-intent-danger {
      color: #c23030; }
      .bp3-button.bp3-minimal.bp3-intent-danger:hover, .bp3-button.bp3-minimal.bp3-intent-danger:active, .bp3-button.bp3-minimal.bp3-intent-danger.bp3-active {
        background: none;
        box-shadow: none;
        color: #c23030; }
      .bp3-button.bp3-minimal.bp3-intent-danger:hover {
        background: rgba(219, 55, 55, 0.15);
        color: #c23030; }
      .bp3-button.bp3-minimal.bp3-intent-danger:active, .bp3-button.bp3-minimal.bp3-intent-danger.bp3-active {
        background: rgba(219, 55, 55, 0.3);
        color: #c23030; }
      .bp3-button.bp3-minimal.bp3-intent-danger:disabled, .bp3-button.bp3-minimal.bp3-intent-danger.bp3-disabled {
        background: none;
        color: rgba(194, 48, 48, 0.5); }
        .bp3-button.bp3-minimal.bp3-intent-danger:disabled.bp3-active, .bp3-button.bp3-minimal.bp3-intent-danger.bp3-disabled.bp3-active {
          background: rgba(219, 55, 55, 0.3); }
      .bp3-button.bp3-minimal.bp3-intent-danger .bp3-button-spinner .bp3-spinner-head {
        stroke: #c23030; }
      .bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger {
        color: #ff7373; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger:hover {
          background: rgba(219, 55, 55, 0.2);
          color: #ff7373; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger:active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger.bp3-active {
          background: rgba(219, 55, 55, 0.3);
          color: #ff7373; }
        .bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger:disabled, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger.bp3-disabled {
          background: none;
          color: rgba(255, 115, 115, 0.5); }
          .bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger:disabled.bp3-active, .bp3-dark .bp3-button.bp3-minimal.bp3-intent-danger.bp3-disabled.bp3-active {
            background: rgba(219, 55, 55, 0.3); }
  .bp3-button.bp3-outlined {
    background: none;
    box-shadow: none;
    border: 1px solid rgba(24, 32, 38, 0.2);
    box-sizing: border-box; }
    .bp3-button.bp3-outlined:hover {
      background: rgba(233, 78, 27, 0.3);
      box-shadow: none;
      color: #182026;
      text-decoration: none; }
    .bp3-button.bp3-outlined:active, .bp3-button.bp3-outlined.bp3-active {
      background: rgba(233, 78, 27, 0.3);
      box-shadow: none;
      color: #182026; }
    .bp3-button.bp3-outlined:disabled, .bp3-button.bp3-outlined:disabled:hover, .bp3-button.bp3-outlined.bp3-disabled, .bp3-button.bp3-outlined.bp3-disabled:hover {
      background: none;
      color: rgba(255, 255, 255, 0.6);
      cursor: not-allowed; }
      .bp3-button.bp3-outlined:disabled.bp3-active, .bp3-button.bp3-outlined:disabled:hover.bp3-active, .bp3-button.bp3-outlined.bp3-disabled.bp3-active, .bp3-button.bp3-outlined.bp3-disabled:hover.bp3-active {
        background: rgba(233, 78, 27, 0.3); }
    .bp3-dark .bp3-button.bp3-outlined {
      background: none;
      box-shadow: none;
      color: inherit; }
      .bp3-dark .bp3-button.bp3-outlined:hover, .bp3-dark .bp3-button.bp3-outlined:active, .bp3-dark .bp3-button.bp3-outlined.bp3-active {
        background: none;
        box-shadow: none; }
      .bp3-dark .bp3-button.bp3-outlined:hover {
        background: rgba(233, 78, 27, 0.15); }
      .bp3-dark .bp3-button.bp3-outlined:active, .bp3-dark .bp3-button.bp3-outlined.bp3-active {
        background: rgba(233, 78, 27, 0.3);
        color: #3c3c3b; }
      .bp3-dark .bp3-button.bp3-outlined:disabled, .bp3-dark .bp3-button.bp3-outlined:disabled:hover, .bp3-dark .bp3-button.bp3-outlined.bp3-disabled, .bp3-dark .bp3-button.bp3-outlined.bp3-disabled:hover {
        background: none;
        color: rgba(233, 78, 27, 0.6);
        cursor: not-allowed; }
        .bp3-dark .bp3-button.bp3-outlined:disabled.bp3-active, .bp3-dark .bp3-button.bp3-outlined:disabled:hover.bp3-active, .bp3-dark .bp3-button.bp3-outlined.bp3-disabled.bp3-active, .bp3-dark .bp3-button.bp3-outlined.bp3-disabled:hover.bp3-active {
          background: rgba(233, 78, 27, 0.3); }
    .bp3-button.bp3-outlined.bp3-intent-primary {
      color: #e94e1b; }
      .bp3-button.bp3-outlined.bp3-intent-primary:hover, .bp3-button.bp3-outlined.bp3-intent-primary:active, .bp3-button.bp3-outlined.bp3-intent-primary.bp3-active {
        background: none;
        box-shadow: none;
        color: #e94e1b; }
      .bp3-button.bp3-outlined.bp3-intent-primary:hover {
        background: rgba(233, 78, 27, 0.15);
        color: #e94e1b; }
      .bp3-button.bp3-outlined.bp3-intent-primary:active, .bp3-button.bp3-outlined.bp3-intent-primary.bp3-active {
        background: rgba(233, 78, 27, 0.3);
        color: #e94e1b; }
      .bp3-button.bp3-outlined.bp3-intent-primary:disabled, .bp3-button.bp3-outlined.bp3-intent-primary.bp3-disabled {
        background: none;
        color: rgba(233, 78, 27, 0.5); }
        .bp3-button.bp3-outlined.bp3-intent-primary:disabled.bp3-active, .bp3-button.bp3-outlined.bp3-intent-primary.bp3-disabled.bp3-active {
          background: rgba(233, 78, 27, 0.3); }
      .bp3-button.bp3-outlined.bp3-intent-primary .bp3-button-spinner .bp3-spinner-head {
        stroke: #e94e1b; }
      .bp3-dark .bp3-button.bp3-outlined.bp3-intent-primary {
        color: #ffffff; }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-primary:hover {
          background: rgba(233, 78, 27, 0.2);
          color: #ffffff; }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-primary:active, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-primary.bp3-active {
          background: rgba(233, 78, 27, 0.3);
          color: #ffffff; }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-primary:disabled, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-primary.bp3-disabled {
          background: none;
          color: rgba(255, 255, 255, 0.5); }
          .bp3-dark .bp3-button.bp3-outlined.bp3-intent-primary:disabled.bp3-active, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-primary.bp3-disabled.bp3-active {
            background: rgba(233, 78, 27, 0.3); }
    .bp3-button.bp3-outlined.bp3-intent-success {
      color: #0d8050; }
      .bp3-button.bp3-outlined.bp3-intent-success:hover, .bp3-button.bp3-outlined.bp3-intent-success:active, .bp3-button.bp3-outlined.bp3-intent-success.bp3-active {
        background: none;
        box-shadow: none;
        color: #0d8050; }
      .bp3-button.bp3-outlined.bp3-intent-success:hover {
        background: rgba(15, 153, 96, 0.15);
        color: #0d8050; }
      .bp3-button.bp3-outlined.bp3-intent-success:active, .bp3-button.bp3-outlined.bp3-intent-success.bp3-active {
        background: rgba(15, 153, 96, 0.3);
        color: #0d8050; }
      .bp3-button.bp3-outlined.bp3-intent-success:disabled, .bp3-button.bp3-outlined.bp3-intent-success.bp3-disabled {
        background: none;
        color: rgba(13, 128, 80, 0.5); }
        .bp3-button.bp3-outlined.bp3-intent-success:disabled.bp3-active, .bp3-button.bp3-outlined.bp3-intent-success.bp3-disabled.bp3-active {
          background: rgba(15, 153, 96, 0.3); }
      .bp3-button.bp3-outlined.bp3-intent-success .bp3-button-spinner .bp3-spinner-head {
        stroke: #0d8050; }
      .bp3-dark .bp3-button.bp3-outlined.bp3-intent-success {
        color: #3dcc91; }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-success:hover {
          background: rgba(15, 153, 96, 0.2);
          color: #3dcc91; }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-success:active, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-success.bp3-active {
          background: rgba(15, 153, 96, 0.3);
          color: #3dcc91; }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-success:disabled, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-success.bp3-disabled {
          background: none;
          color: rgba(61, 204, 145, 0.5); }
          .bp3-dark .bp3-button.bp3-outlined.bp3-intent-success:disabled.bp3-active, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-success.bp3-disabled.bp3-active {
            background: rgba(15, 153, 96, 0.3); }
    .bp3-button.bp3-outlined.bp3-intent-warning {
      color: #bf7326; }
      .bp3-button.bp3-outlined.bp3-intent-warning:hover, .bp3-button.bp3-outlined.bp3-intent-warning:active, .bp3-button.bp3-outlined.bp3-intent-warning.bp3-active {
        background: none;
        box-shadow: none;
        color: #bf7326; }
      .bp3-button.bp3-outlined.bp3-intent-warning:hover {
        background: rgba(217, 130, 43, 0.15);
        color: #bf7326; }
      .bp3-button.bp3-outlined.bp3-intent-warning:active, .bp3-button.bp3-outlined.bp3-intent-warning.bp3-active {
        background: rgba(217, 130, 43, 0.3);
        color: #bf7326; }
      .bp3-button.bp3-outlined.bp3-intent-warning:disabled, .bp3-button.bp3-outlined.bp3-intent-warning.bp3-disabled {
        background: none;
        color: rgba(191, 115, 38, 0.5); }
        .bp3-button.bp3-outlined.bp3-intent-warning:disabled.bp3-active, .bp3-button.bp3-outlined.bp3-intent-warning.bp3-disabled.bp3-active {
          background: rgba(217, 130, 43, 0.3); }
      .bp3-button.bp3-outlined.bp3-intent-warning .bp3-button-spinner .bp3-spinner-head {
        stroke: #bf7326; }
      .bp3-dark .bp3-button.bp3-outlined.bp3-intent-warning {
        color: #ffb366; }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-warning:hover {
          background: rgba(217, 130, 43, 0.2);
          color: #ffb366; }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-warning:active, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-warning.bp3-active {
          background: rgba(217, 130, 43, 0.3);
          color: #ffb366; }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-warning:disabled, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-warning.bp3-disabled {
          background: none;
          color: rgba(255, 179, 102, 0.5); }
          .bp3-dark .bp3-button.bp3-outlined.bp3-intent-warning:disabled.bp3-active, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-warning.bp3-disabled.bp3-active {
            background: rgba(217, 130, 43, 0.3); }
    .bp3-button.bp3-outlined.bp3-intent-danger {
      color: #c23030; }
      .bp3-button.bp3-outlined.bp3-intent-danger:hover, .bp3-button.bp3-outlined.bp3-intent-danger:active, .bp3-button.bp3-outlined.bp3-intent-danger.bp3-active {
        background: none;
        box-shadow: none;
        color: #c23030; }
      .bp3-button.bp3-outlined.bp3-intent-danger:hover {
        background: rgba(219, 55, 55, 0.15);
        color: #c23030; }
      .bp3-button.bp3-outlined.bp3-intent-danger:active, .bp3-button.bp3-outlined.bp3-intent-danger.bp3-active {
        background: rgba(219, 55, 55, 0.3);
        color: #c23030; }
      .bp3-button.bp3-outlined.bp3-intent-danger:disabled, .bp3-button.bp3-outlined.bp3-intent-danger.bp3-disabled {
        background: none;
        color: rgba(194, 48, 48, 0.5); }
        .bp3-button.bp3-outlined.bp3-intent-danger:disabled.bp3-active, .bp3-button.bp3-outlined.bp3-intent-danger.bp3-disabled.bp3-active {
          background: rgba(219, 55, 55, 0.3); }
      .bp3-button.bp3-outlined.bp3-intent-danger .bp3-button-spinner .bp3-spinner-head {
        stroke: #c23030; }
      .bp3-dark .bp3-button.bp3-outlined.bp3-intent-danger {
        color: #ff7373; }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-danger:hover {
          background: rgba(219, 55, 55, 0.2);
          color: #ff7373; }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-danger:active, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-danger.bp3-active {
          background: rgba(219, 55, 55, 0.3);
          color: #ff7373; }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-danger:disabled, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-danger.bp3-disabled {
          background: none;
          color: rgba(255, 115, 115, 0.5); }
          .bp3-dark .bp3-button.bp3-outlined.bp3-intent-danger:disabled.bp3-active, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-danger.bp3-disabled.bp3-active {
            background: rgba(219, 55, 55, 0.3); }
    .bp3-button.bp3-outlined:disabled, .bp3-button.bp3-outlined.bp3-disabled, .bp3-button.bp3-outlined:disabled:hover, .bp3-button.bp3-outlined.bp3-disabled:hover {
      border-color: rgba(255, 255, 255, 0.1); }
    .bp3-dark .bp3-button.bp3-outlined {
      border-color: rgba(255, 255, 255, 0.4); }
      .bp3-dark .bp3-button.bp3-outlined:disabled, .bp3-dark .bp3-button.bp3-outlined:disabled:hover, .bp3-dark .bp3-button.bp3-outlined.bp3-disabled, .bp3-dark .bp3-button.bp3-outlined.bp3-disabled:hover {
        border-color: rgba(255, 255, 255, 0.2); }
    .bp3-button.bp3-outlined.bp3-intent-primary {
      border-color: rgba(233, 78, 27, 0.6); }
      .bp3-button.bp3-outlined.bp3-intent-primary:disabled, .bp3-button.bp3-outlined.bp3-intent-primary.bp3-disabled {
        border-color: rgba(233, 78, 27, 0.2); }
      .bp3-dark .bp3-button.bp3-outlined.bp3-intent-primary {
        border-color: rgba(255, 255, 255, 0.6); }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-primary:disabled, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-primary.bp3-disabled {
          border-color: rgba(255, 255, 255, 0.2); }
    .bp3-button.bp3-outlined.bp3-intent-success {
      border-color: rgba(13, 128, 80, 0.6); }
      .bp3-button.bp3-outlined.bp3-intent-success:disabled, .bp3-button.bp3-outlined.bp3-intent-success.bp3-disabled {
        border-color: rgba(13, 128, 80, 0.2); }
      .bp3-dark .bp3-button.bp3-outlined.bp3-intent-success {
        border-color: rgba(61, 204, 145, 0.6); }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-success:disabled, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-success.bp3-disabled {
          border-color: rgba(61, 204, 145, 0.2); }
    .bp3-button.bp3-outlined.bp3-intent-warning {
      border-color: rgba(191, 115, 38, 0.6); }
      .bp3-button.bp3-outlined.bp3-intent-warning:disabled, .bp3-button.bp3-outlined.bp3-intent-warning.bp3-disabled {
        border-color: rgba(191, 115, 38, 0.2); }
      .bp3-dark .bp3-button.bp3-outlined.bp3-intent-warning {
        border-color: rgba(255, 179, 102, 0.6); }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-warning:disabled, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-warning.bp3-disabled {
          border-color: rgba(255, 179, 102, 0.2); }
    .bp3-button.bp3-outlined.bp3-intent-danger {
      border-color: rgba(194, 48, 48, 0.6); }
      .bp3-button.bp3-outlined.bp3-intent-danger:disabled, .bp3-button.bp3-outlined.bp3-intent-danger.bp3-disabled {
        border-color: rgba(194, 48, 48, 0.2); }
      .bp3-dark .bp3-button.bp3-outlined.bp3-intent-danger {
        border-color: rgba(255, 115, 115, 0.6); }
        .bp3-dark .bp3-button.bp3-outlined.bp3-intent-danger:disabled, .bp3-dark .bp3-button.bp3-outlined.bp3-intent-danger.bp3-disabled {
          border-color: rgba(255, 115, 115, 0.2); }

a.bp3-button {
  text-align: center;
  text-decoration: none;
  transition: none; }
  a.bp3-button, a.bp3-button:hover, a.bp3-button:active {
    color: #182026; }
  a.bp3-button.bp3-disabled {
    color: rgba(255, 255, 255, 0.6); }

.bp3-button-text {
  flex: 0 1 auto; }

.bp3-button.bp3-align-left .bp3-button-text, .bp3-button.bp3-align-right .bp3-button-text,
.bp3-button-group.bp3-align-left .bp3-button-text,
.bp3-button-group.bp3-align-right .bp3-button-text {
  flex: 1 1 auto; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/*
Button groups

Markup:
<div class="bp3-button-group {{.modifier}}">
  <button type="button" class="bp3-button bp3-intent-success">Save</button>
  <button type="button" class="bp3-button bp3-intent-success bp3-icon-caret-down"></button>
</div>
<div class="bp3-button-group {{.modifier}}">
  <a class="bp3-button bp3-icon-database" tabindex="0" role="button">Queries</a>
  <a class="bp3-button bp3-icon-function" tabindex="0" role="button">Functions</a>
  <a class="bp3-button" tabindex="0" role="button">
    Options <span class="bp3-icon-standard bp3-icon-caret-down bp3-align-right"></span>
  </a>
</div>
<div class="bp3-button-group {{.modifier}}">
  <a class="bp3-button bp3-icon-chart" tabindex="0" role="button"></a>
  <a class="bp3-button bp3-icon-control" tabindex="0" role="button"></a>
  <a class="bp3-button bp3-icon-graph" tabindex="0" role="button"></a>
  <a class="bp3-button bp3-icon-camera" tabindex="0" role="button"></a>
  <a class="bp3-button bp3-icon-map" tabindex="0" role="button"></a>
  <a class="bp3-button bp3-icon-code" tabindex="0" role="button"></a>
  <a class="bp3-button bp3-icon-th" tabindex="0" role="button"></a>
  <a class="bp3-button bp3-icon-time" tabindex="0" role="button"></a>
  <a class="bp3-button bp3-icon-compressed" tabindex="0" role="button"></a>
</div>

.bp3-fill - Buttons expand equally to fill container
.bp3-large - Use large buttons
.bp3-minimal - Use minimal buttons
.bp3-vertical - Vertical layout

Styleguide button-group
*/
.bp3-button-group {
  display: inline-flex;
  /*
  Responsive

  Markup:
  <div class="bp3-button-group bp3-large bp3-fill">
    <a class="bp3-button bp3-intent-primary bp3-fixed" tabindex="0" role="button">Start</a>
    <a class="bp3-button bp3-intent-primary" tabindex="0" role="button">Left</a>
    <a class="bp3-button bp3-intent-primary bp3-active" tabindex="0" role="button">Middle</a>
    <a class="bp3-button bp3-intent-primary" tabindex="0" role="button">Right</a>
    <a class="bp3-button bp3-intent-primary bp3-fixed" tabindex="0" role="button">End</a>
  </div>
  <br />
  <div class="bp3-button-group bp3-fill">
    <button class="bp3-button bp3-icon-arrow-left"></button>
    <button class="bp3-button bp3-fill">Middle</button>
    <button class="bp3-button bp3-icon-arrow-right"></button>
  </div>

  Styleguide button-group}-fill
  */
  /*
  Vertical button groups

  Markup:
  <div class="bp3-button-group bp3-vertical">
    <a class="bp3-button bp3-icon-search-template" role="button" tabindex="0"></a>
    <a class="bp3-button bp3-icon-zoom-in" role="button" tabindex="0"></a>
    <a class="bp3-button bp3-icon-zoom-out" role="button" tabindex="0"></a>
    <a class="bp3-button bp3-icon-zoom-to-fit" role="button" tabindex="0"></a>
  </div>
  <div class="bp3-button-group bp3-vertical">
    <button type="button" class="bp3-button bp3-active">Home</button>
    <button type="button" class="bp3-button">Pages</button>
    <button type="button" class="bp3-button">Blog</button>
    <button type="button" class="bp3-button">Calendar</button>
  </div>
  <div class="bp3-button-group bp3-vertical bp3-align-left bp3-large">
    <button type="button" class="bp3-button bp3-intent-primary bp3-icon-document">Text</button>
    <button type="button" class="bp3-button bp3-intent-primary bp3-icon-media bp3-active">Media</button>
    <button type="button" class="bp3-button bp3-intent-primary bp3-icon-link">Sources</button>
  </div>

  Styleguide button-group-vertical
  */ }
  .bp3-button-group .bp3-button {
    flex: 0 0 auto;
    position: relative;
    z-index: 4; }
    .bp3-button-group .bp3-button:focus {
      z-index: 5; }
    .bp3-button-group .bp3-button:hover {
      z-index: 6; }
    .bp3-button-group .bp3-button:active, .bp3-button-group .bp3-button.bp3-active {
      z-index: 7; }
    .bp3-button-group .bp3-button:disabled, .bp3-button-group .bp3-button.bp3-disabled {
      z-index: 3; }
    .bp3-button-group .bp3-button[class*="bp3-intent-"] {
      z-index: 9; }
      .bp3-button-group .bp3-button[class*="bp3-intent-"]:focus {
        z-index: 10; }
      .bp3-button-group .bp3-button[class*="bp3-intent-"]:hover {
        z-index: 11; }
      .bp3-button-group .bp3-button[class*="bp3-intent-"]:active, .bp3-button-group .bp3-button[class*="bp3-intent-"].bp3-active {
        z-index: 12; }
      .bp3-button-group .bp3-button[class*="bp3-intent-"]:disabled, .bp3-button-group .bp3-button[class*="bp3-intent-"].bp3-disabled {
        z-index: 8; }
  .bp3-button-group:not(.bp3-minimal) > .bp3-popover-wrapper:not(:first-child) .bp3-button,
  .bp3-button-group:not(.bp3-minimal) > .bp3-button:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .bp3-button-group:not(.bp3-minimal) > .bp3-popover-wrapper:not(:last-child) .bp3-button,
  .bp3-button-group:not(.bp3-minimal) > .bp3-button:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    margin-right: -1px; }
  .bp3-button-group.bp3-minimal .bp3-button {
    background: none;
    box-shadow: none; }
    .bp3-button-group.bp3-minimal .bp3-button:hover {
      background: rgba(233, 78, 27, 0.3);
      box-shadow: none;
      color: #182026;
      text-decoration: none; }
    .bp3-button-group.bp3-minimal .bp3-button:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-active {
      background: rgba(233, 78, 27, 0.3);
      box-shadow: none;
      color: #182026; }
    .bp3-button-group.bp3-minimal .bp3-button:disabled, .bp3-button-group.bp3-minimal .bp3-button:disabled:hover, .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled, .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled:hover {
      background: none;
      color: rgba(255, 255, 255, 0.6);
      cursor: not-allowed; }
      .bp3-button-group.bp3-minimal .bp3-button:disabled.bp3-active, .bp3-button-group.bp3-minimal .bp3-button:disabled:hover.bp3-active, .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled.bp3-active, .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled:hover.bp3-active {
        background: rgba(233, 78, 27, 0.3); }
    .bp3-dark .bp3-button-group.bp3-minimal .bp3-button {
      background: none;
      box-shadow: none;
      color: inherit; }
      .bp3-dark .bp3-button-group.bp3-minimal .bp3-button:hover, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button:active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-active {
        background: none;
        box-shadow: none; }
      .bp3-dark .bp3-button-group.bp3-minimal .bp3-button:hover {
        background: rgba(233, 78, 27, 0.15); }
      .bp3-dark .bp3-button-group.bp3-minimal .bp3-button:active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-active {
        background: rgba(233, 78, 27, 0.3);
        color: #3c3c3b; }
      .bp3-dark .bp3-button-group.bp3-minimal .bp3-button:disabled, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button:disabled:hover, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled:hover {
        background: none;
        color: rgba(233, 78, 27, 0.6);
        cursor: not-allowed; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button:disabled.bp3-active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button:disabled:hover.bp3-active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled.bp3-active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-disabled:hover.bp3-active {
          background: rgba(233, 78, 27, 0.3); }
    .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary {
      color: #e94e1b; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:hover, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary.bp3-active {
        background: none;
        box-shadow: none;
        color: #e94e1b; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:hover {
        background: rgba(233, 78, 27, 0.15);
        color: #e94e1b; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary.bp3-active {
        background: rgba(233, 78, 27, 0.3);
        color: #e94e1b; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:disabled, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary.bp3-disabled {
        background: none;
        color: rgba(233, 78, 27, 0.5); }
        .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:disabled.bp3-active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary.bp3-disabled.bp3-active {
          background: rgba(233, 78, 27, 0.3); }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary .bp3-button-spinner .bp3-spinner-head {
        stroke: #e94e1b; }
      .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary {
        color: #ffffff; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:hover {
          background: rgba(233, 78, 27, 0.2);
          color: #ffffff; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary.bp3-active {
          background: rgba(233, 78, 27, 0.3);
          color: #ffffff; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:disabled, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary.bp3-disabled {
          background: none;
          color: rgba(255, 255, 255, 0.5); }
          .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary:disabled.bp3-active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-primary.bp3-disabled.bp3-active {
            background: rgba(233, 78, 27, 0.3); }
    .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success {
      color: #0d8050; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:hover, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success.bp3-active {
        background: none;
        box-shadow: none;
        color: #0d8050; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:hover {
        background: rgba(15, 153, 96, 0.15);
        color: #0d8050; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success.bp3-active {
        background: rgba(15, 153, 96, 0.3);
        color: #0d8050; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:disabled, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success.bp3-disabled {
        background: none;
        color: rgba(13, 128, 80, 0.5); }
        .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:disabled.bp3-active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success.bp3-disabled.bp3-active {
          background: rgba(15, 153, 96, 0.3); }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success .bp3-button-spinner .bp3-spinner-head {
        stroke: #0d8050; }
      .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success {
        color: #3dcc91; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:hover {
          background: rgba(15, 153, 96, 0.2);
          color: #3dcc91; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success.bp3-active {
          background: rgba(15, 153, 96, 0.3);
          color: #3dcc91; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:disabled, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success.bp3-disabled {
          background: none;
          color: rgba(61, 204, 145, 0.5); }
          .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success:disabled.bp3-active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-success.bp3-disabled.bp3-active {
            background: rgba(15, 153, 96, 0.3); }
    .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning {
      color: #bf7326; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:hover, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning.bp3-active {
        background: none;
        box-shadow: none;
        color: #bf7326; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:hover {
        background: rgba(217, 130, 43, 0.15);
        color: #bf7326; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning.bp3-active {
        background: rgba(217, 130, 43, 0.3);
        color: #bf7326; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:disabled, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning.bp3-disabled {
        background: none;
        color: rgba(191, 115, 38, 0.5); }
        .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:disabled.bp3-active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning.bp3-disabled.bp3-active {
          background: rgba(217, 130, 43, 0.3); }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning .bp3-button-spinner .bp3-spinner-head {
        stroke: #bf7326; }
      .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning {
        color: #ffb366; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:hover {
          background: rgba(217, 130, 43, 0.2);
          color: #ffb366; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning.bp3-active {
          background: rgba(217, 130, 43, 0.3);
          color: #ffb366; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:disabled, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning.bp3-disabled {
          background: none;
          color: rgba(255, 179, 102, 0.5); }
          .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning:disabled.bp3-active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-warning.bp3-disabled.bp3-active {
            background: rgba(217, 130, 43, 0.3); }
    .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger {
      color: #c23030; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:hover, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger.bp3-active {
        background: none;
        box-shadow: none;
        color: #c23030; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:hover {
        background: rgba(219, 55, 55, 0.15);
        color: #c23030; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger.bp3-active {
        background: rgba(219, 55, 55, 0.3);
        color: #c23030; }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:disabled, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger.bp3-disabled {
        background: none;
        color: rgba(194, 48, 48, 0.5); }
        .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:disabled.bp3-active, .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger.bp3-disabled.bp3-active {
          background: rgba(219, 55, 55, 0.3); }
      .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger .bp3-button-spinner .bp3-spinner-head {
        stroke: #c23030; }
      .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger {
        color: #ff7373; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:hover {
          background: rgba(219, 55, 55, 0.2);
          color: #ff7373; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger.bp3-active {
          background: rgba(219, 55, 55, 0.3);
          color: #ff7373; }
        .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:disabled, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger.bp3-disabled {
          background: none;
          color: rgba(255, 115, 115, 0.5); }
          .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger:disabled.bp3-active, .bp3-dark .bp3-button-group.bp3-minimal .bp3-button.bp3-intent-danger.bp3-disabled.bp3-active {
            background: rgba(219, 55, 55, 0.3); }
  .bp3-button-group .bp3-popover-wrapper,
  .bp3-button-group .bp3-popover-target {
    display: flex;
    flex: 1 1 auto; }
  .bp3-button-group.bp3-fill {
    display: flex;
    width: 100%; }
  .bp3-button-group .bp3-button.bp3-fill,
  .bp3-button-group.bp3-fill .bp3-button:not(.bp3-fixed) {
    flex: 1 1 auto; }
  .bp3-button-group.bp3-vertical {
    align-items: stretch;
    flex-direction: column;
    vertical-align: top; }
    .bp3-button-group.bp3-vertical.bp3-fill {
      height: 100%;
      width: unset; }
    .bp3-button-group.bp3-vertical .bp3-button {
      margin-right: 0 !important;
      width: 100%; }
    .bp3-button-group.bp3-vertical:not(.bp3-minimal) > .bp3-popover-wrapper:first-child .bp3-button,
    .bp3-button-group.bp3-vertical:not(.bp3-minimal) > .bp3-button:first-child {
      border-radius: 3px 3px 0 0; }
    .bp3-button-group.bp3-vertical:not(.bp3-minimal) > .bp3-popover-wrapper:last-child .bp3-button,
    .bp3-button-group.bp3-vertical:not(.bp3-minimal) > .bp3-button:last-child {
      border-radius: 0 0 3px 3px; }
    .bp3-button-group.bp3-vertical:not(.bp3-minimal) > .bp3-popover-wrapper:not(:last-child) .bp3-button,
    .bp3-button-group.bp3-vertical:not(.bp3-minimal) > .bp3-button:not(:last-child) {
      margin-bottom: -1px; }
  .bp3-button-group.bp3-align-left .bp3-button {
    text-align: left; }
  .bp3-dark .bp3-button-group:not(.bp3-minimal) > .bp3-popover-wrapper:not(:last-child) .bp3-button,
  .bp3-dark .bp3-button-group:not(.bp3-minimal) > .bp3-button:not(:last-child) {
    margin-right: 1px; }
  .bp3-dark .bp3-button-group.bp3-vertical > .bp3-popover-wrapper:not(:last-child) .bp3-button,
  .bp3-dark .bp3-button-group.bp3-vertical > .bp3-button:not(:last-child) {
    margin-bottom: 1px; }

/*
Callout

Markup:
<div class="bp3-callout {{.modifier}}">
  <h4 class="bp3-heading">Callout Heading</h4>
  It's dangerous to go alone! Take this.
</div>

.bp3-intent-primary - Primary intent
.bp3-intent-success - Success intent
.bp3-intent-warning - Warning intent
.bp3-intent-danger  - Danger intent
.bp3-icon-info-sign - With an icon

Styleguide callout
*/
.bp3-callout {
  font-size: 14px;
  line-height: 1.5;
  background-color: rgba(233, 78, 27, 0.15);
  border-radius: 3px;
  padding: 10px 12px 9px;
  position: relative;
  width: 100%; }
  .bp3-callout[class*="bp3-icon-"] {
    padding-left: 40px; }
    .bp3-callout[class*="bp3-icon-"]::before {
      font-family: "Icons20", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      color: #ffffff;
      left: 10px;
      position: absolute;
      top: 10px; }
  .bp3-callout.bp3-callout-icon {
    padding-left: 40px; }
    .bp3-callout.bp3-callout-icon > .bp3-icon:first-child {
      color: #ffffff;
      left: 10px;
      position: absolute;
      top: 10px; }
  .bp3-callout .bp3-heading {
    line-height: 20px;
    margin-bottom: 5px;
    margin-top: 0; }
    .bp3-callout .bp3-heading:last-child {
      margin-bottom: 0; }
  .bp3-dark .bp3-callout {
    background-color: rgba(233, 78, 27, 0.2); }
    .bp3-dark .bp3-callout[class*="bp3-icon-"]::before {
      color: #e94e1b; }
  .bp3-callout.bp3-intent-primary {
    background-color: rgba(233, 78, 27, 0.15); }
    .bp3-callout.bp3-intent-primary[class*="bp3-icon-"]::before,
    .bp3-callout.bp3-intent-primary > .bp3-icon:first-child,
    .bp3-callout.bp3-intent-primary .bp3-heading {
      color: #e94e1b; }
    .bp3-dark .bp3-callout.bp3-intent-primary {
      background-color: rgba(233, 78, 27, 0.25); }
      .bp3-dark .bp3-callout.bp3-intent-primary[class*="bp3-icon-"]::before,
      .bp3-dark .bp3-callout.bp3-intent-primary > .bp3-icon:first-child,
      .bp3-dark .bp3-callout.bp3-intent-primary .bp3-heading {
        color: #ffffff; }
  .bp3-callout.bp3-intent-success {
    background-color: rgba(15, 153, 96, 0.15); }
    .bp3-callout.bp3-intent-success[class*="bp3-icon-"]::before,
    .bp3-callout.bp3-intent-success > .bp3-icon:first-child,
    .bp3-callout.bp3-intent-success .bp3-heading {
      color: #0d8050; }
    .bp3-dark .bp3-callout.bp3-intent-success {
      background-color: rgba(15, 153, 96, 0.25); }
      .bp3-dark .bp3-callout.bp3-intent-success[class*="bp3-icon-"]::before,
      .bp3-dark .bp3-callout.bp3-intent-success > .bp3-icon:first-child,
      .bp3-dark .bp3-callout.bp3-intent-success .bp3-heading {
        color: #3dcc91; }
  .bp3-callout.bp3-intent-warning {
    background-color: rgba(217, 130, 43, 0.15); }
    .bp3-callout.bp3-intent-warning[class*="bp3-icon-"]::before,
    .bp3-callout.bp3-intent-warning > .bp3-icon:first-child,
    .bp3-callout.bp3-intent-warning .bp3-heading {
      color: #bf7326; }
    .bp3-dark .bp3-callout.bp3-intent-warning {
      background-color: rgba(217, 130, 43, 0.25); }
      .bp3-dark .bp3-callout.bp3-intent-warning[class*="bp3-icon-"]::before,
      .bp3-dark .bp3-callout.bp3-intent-warning > .bp3-icon:first-child,
      .bp3-dark .bp3-callout.bp3-intent-warning .bp3-heading {
        color: #ffb366; }
  .bp3-callout.bp3-intent-danger {
    background-color: rgba(219, 55, 55, 0.15); }
    .bp3-callout.bp3-intent-danger[class*="bp3-icon-"]::before,
    .bp3-callout.bp3-intent-danger > .bp3-icon:first-child,
    .bp3-callout.bp3-intent-danger .bp3-heading {
      color: #c23030; }
    .bp3-dark .bp3-callout.bp3-intent-danger {
      background-color: rgba(219, 55, 55, 0.25); }
      .bp3-dark .bp3-callout.bp3-intent-danger[class*="bp3-icon-"]::before,
      .bp3-dark .bp3-callout.bp3-intent-danger > .bp3-icon:first-child,
      .bp3-dark .bp3-callout.bp3-intent-danger .bp3-heading {
        color: #ff7373; }
  .bp3-running-text .bp3-callout {
    margin: 20px 0; }

/*
Cards

Markup:
<div class="bp3-card {{.modifier}}">
  We build products that make people better at their most important work.
</div>

.bp3-elevation-0 - Ground floor. This level provides just a gentle border shadow.
.bp3-elevation-1 - First. Subtle drop shadow intended for static containers.
.bp3-elevation-2 - Second. An even stronger shadow, moving on up.
.bp3-elevation-3 - Third. For containers overlaying content temporarily.
.bp3-elevation-4 - Fourth. The strongest shadow, usually for overlay containers on top of backdrops.
.bp3-interactive - On hover, increase elevation and use pointer cursor.

Styleguide card
*/
.bp3-card {
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0);
  padding: 20px;
  transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9), box-shadow 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-card.bp3-dark,
  .bp3-dark .bp3-card {
    background-color: #dadada;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0); }

.bp3-elevation-0 {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0); }
  .bp3-elevation-0.bp3-dark,
  .bp3-dark .bp3-elevation-0 {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4), 0 0 0 rgba(16, 22, 26, 0), 0 0 0 rgba(16, 22, 26, 0); }

.bp3-elevation-1 {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2); }
  .bp3-elevation-1.bp3-dark,
  .bp3-dark .bp3-elevation-1 {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4); }

.bp3-elevation-2 {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 1px 1px rgba(16, 22, 26, 0.2), 0 2px 6px rgba(16, 22, 26, 0.2); }
  .bp3-elevation-2.bp3-dark,
  .bp3-dark .bp3-elevation-2 {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.4), 0 2px 6px rgba(16, 22, 26, 0.4); }

.bp3-elevation-3 {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2); }
  .bp3-elevation-3.bp3-dark,
  .bp3-dark .bp3-elevation-3 {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4); }

.bp3-elevation-4 {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 4px 8px rgba(16, 22, 26, 0.2), 0 18px 46px 6px rgba(16, 22, 26, 0.2); }
  .bp3-elevation-4.bp3-dark,
  .bp3-dark .bp3-elevation-4 {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 4px 8px rgba(16, 22, 26, 0.4), 0 18px 46px 6px rgba(16, 22, 26, 0.4); }

.bp3-card.bp3-interactive:hover {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  cursor: pointer; }
  .bp3-card.bp3-interactive:hover.bp3-dark,
  .bp3-dark .bp3-card.bp3-interactive:hover {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4); }

.bp3-card.bp3-interactive:active {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
  opacity: 0.9;
  transition-duration: 0; }
  .bp3-card.bp3-interactive:active.bp3-dark,
  .bp3-dark .bp3-card.bp3-interactive:active {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4); }

.bp3-collapse {
  height: 0;
  overflow-y: hidden;
  transition: height 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-collapse .bp3-collapse-body {
    transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }
    .bp3-collapse .bp3-collapse-body[aria-hidden="true"] {
      display: none; }

.bp3-context-menu .bp3-popover-target {
  display: block; }

.bp3-context-menu-popover-target {
  position: fixed; }

.bp3-divider {
  border-bottom: 1px solid rgba(16, 22, 26, 0.15);
  border-right: 1px solid rgba(16, 22, 26, 0.15);
  margin: 5px; }
  .bp3-dark .bp3-divider {
    border-color: rgba(16, 22, 26, 0.4); }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
/*
Dialog

Markup:
<!-- this container element fills its parent and centers the .bp3-dialog within it -->
<div class="bp3-dialog-container">
  <div class="bp3-dialog">
    <div class="bp3-dialog-header">
      <span class="bp3-icon-large bp3-icon-inbox"></span>
      <h4 class="bp3-heading">Dialog header</h4>
      <button aria-label="Close" class="bp3-dialog-close-button bp3-button bp3-minimal bp3-icon-cross"></button>
    </div>
    <div class="bp3-dialog-body">
      This dialog hasn't been wired up with any open or close interactions.
      It's just an example of markup and styles.
    </div>
    <div class="bp3-dialog-footer">
      <div class="bp3-dialog-footer-actions">
        <button type="button" class="bp3-button">Secondary button</button>
        <button type="submit" class="bp3-button bp3-intent-primary">Primary button</button>
      </div>
    </div>
  </div>
</div>

Styleguide dialog
*/
.bp3-dialog-container {
  opacity: 1;
  transform: scale(1);
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100%;
  pointer-events: none;
  user-select: none;
  width: 100%; }
  .bp3-dialog-container.bp3-overlay-enter > .bp3-dialog, .bp3-dialog-container.bp3-overlay-appear > .bp3-dialog {
    opacity: 0;
    transform: scale(0.5); }
  .bp3-dialog-container.bp3-overlay-enter-active > .bp3-dialog, .bp3-dialog-container.bp3-overlay-appear-active > .bp3-dialog {
    opacity: 1;
    transform: scale(1);
    transition-delay: 0;
    transition-duration: 300ms;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11); }
  .bp3-dialog-container.bp3-overlay-exit > .bp3-dialog {
    opacity: 1;
    transform: scale(1); }
  .bp3-dialog-container.bp3-overlay-exit-active > .bp3-dialog {
    opacity: 0;
    transform: scale(0.5);
    transition-delay: 0;
    transition-duration: 300ms;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11); }

.bp3-dialog {
  background: #e94e1b;
  border-radius: 6px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 4px 8px rgba(16, 22, 26, 0.2), 0 18px 46px 6px rgba(16, 22, 26, 0.2);
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  padding-bottom: 20px;
  pointer-events: all;
  user-select: text;
  width: 500px; }
  .bp3-dialog:focus {
    outline: 0; }
  .bp3-dialog.bp3-dark,
  .bp3-dark .bp3-dialog {
    background: #e94e1b;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 4px 8px rgba(16, 22, 26, 0.4), 0 18px 46px 6px rgba(16, 22, 26, 0.4);
    color: #3c3c3b; }

.bp3-dialog-header {
  align-items: center;
  background: #ffffff;
  border-radius: 6px 6px 0 0;
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15);
  display: flex;
  flex: 0 0 auto;
  min-height: 40px;
  padding-left: 20px;
  padding-right: 5px; }
  .bp3-dialog-header .bp3-icon-large,
  .bp3-dialog-header .bp3-icon {
    color: #ffffff;
    flex: 0 0 auto;
    margin-right: 10px; }
  .bp3-dialog-header .bp3-heading {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    flex: 1 1 auto;
    line-height: inherit;
    margin: 0; }
    .bp3-dialog-header .bp3-heading:last-child {
      margin-right: 20px; }
  .bp3-dark .bp3-dialog-header {
    background: #dadada;
    box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-dialog-header .bp3-icon-large,
    .bp3-dark .bp3-dialog-header .bp3-icon {
      color: #e94e1b; }

.bp3-dialog-body {
  flex: 1 1 auto;
  line-height: 18px;
  margin: 20px; }

.bp3-dialog-footer {
  flex: 0 0 auto;
  margin: 0 20px; }

.bp3-dialog-footer-actions {
  display: flex;
  justify-content: flex-end; }
  .bp3-dialog-footer-actions .bp3-button {
    margin-left: 10px; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.bp3-drawer {
  background: #ffffff;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 4px 8px rgba(16, 22, 26, 0.2), 0 18px 46px 6px rgba(16, 22, 26, 0.2);
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0; }
  .bp3-drawer:focus {
    outline: 0; }
  .bp3-drawer.bp3-position-top {
    height: 50%;
    left: 0;
    right: 0;
    top: 0; }
    .bp3-drawer.bp3-position-top.bp3-overlay-enter, .bp3-drawer.bp3-position-top.bp3-overlay-appear {
      transform: translateY(-100%); }
    .bp3-drawer.bp3-position-top.bp3-overlay-enter-active, .bp3-drawer.bp3-position-top.bp3-overlay-appear-active {
      transform: translateY(0);
      transition-delay: 0;
      transition-duration: 200ms;
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
    .bp3-drawer.bp3-position-top.bp3-overlay-exit {
      transform: translateY(0); }
    .bp3-drawer.bp3-position-top.bp3-overlay-exit-active {
      transform: translateY(-100%);
      transition-delay: 0;
      transition-duration: 100ms;
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-drawer.bp3-position-bottom {
    bottom: 0;
    height: 50%;
    left: 0;
    right: 0; }
    .bp3-drawer.bp3-position-bottom.bp3-overlay-enter, .bp3-drawer.bp3-position-bottom.bp3-overlay-appear {
      transform: translateY(100%); }
    .bp3-drawer.bp3-position-bottom.bp3-overlay-enter-active, .bp3-drawer.bp3-position-bottom.bp3-overlay-appear-active {
      transform: translateY(0);
      transition-delay: 0;
      transition-duration: 200ms;
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
    .bp3-drawer.bp3-position-bottom.bp3-overlay-exit {
      transform: translateY(0); }
    .bp3-drawer.bp3-position-bottom.bp3-overlay-exit-active {
      transform: translateY(100%);
      transition-delay: 0;
      transition-duration: 100ms;
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-drawer.bp3-position-left {
    bottom: 0;
    left: 0;
    top: 0;
    width: 50%; }
    .bp3-drawer.bp3-position-left.bp3-overlay-enter, .bp3-drawer.bp3-position-left.bp3-overlay-appear {
      transform: translateX(-100%); }
    .bp3-drawer.bp3-position-left.bp3-overlay-enter-active, .bp3-drawer.bp3-position-left.bp3-overlay-appear-active {
      transform: translateX(0);
      transition-delay: 0;
      transition-duration: 200ms;
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
    .bp3-drawer.bp3-position-left.bp3-overlay-exit {
      transform: translateX(0); }
    .bp3-drawer.bp3-position-left.bp3-overlay-exit-active {
      transform: translateX(-100%);
      transition-delay: 0;
      transition-duration: 100ms;
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-drawer.bp3-position-right {
    bottom: 0;
    right: 0;
    top: 0;
    width: 50%; }
    .bp3-drawer.bp3-position-right.bp3-overlay-enter, .bp3-drawer.bp3-position-right.bp3-overlay-appear {
      transform: translateX(100%); }
    .bp3-drawer.bp3-position-right.bp3-overlay-enter-active, .bp3-drawer.bp3-position-right.bp3-overlay-appear-active {
      transform: translateX(0);
      transition-delay: 0;
      transition-duration: 200ms;
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
    .bp3-drawer.bp3-position-right.bp3-overlay-exit {
      transform: translateX(0); }
    .bp3-drawer.bp3-position-right.bp3-overlay-exit-active {
      transform: translateX(100%);
      transition-delay: 0;
      transition-duration: 100ms;
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
  .bp3-position-right):not(.bp3-vertical) {
    bottom: 0;
    right: 0;
    top: 0;
    width: 50%; }
    .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right):not(.bp3-vertical).bp3-overlay-enter, .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right):not(.bp3-vertical).bp3-overlay-appear {
      transform: translateX(100%); }
    .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right):not(.bp3-vertical).bp3-overlay-enter-active, .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right):not(.bp3-vertical).bp3-overlay-appear-active {
      transform: translateX(0);
      transition-delay: 0;
      transition-duration: 200ms;
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
    .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right):not(.bp3-vertical).bp3-overlay-exit {
      transform: translateX(0); }
    .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right):not(.bp3-vertical).bp3-overlay-exit-active {
      transform: translateX(100%);
      transition-delay: 0;
      transition-duration: 100ms;
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
  .bp3-position-right).bp3-vertical {
    bottom: 0;
    height: 50%;
    left: 0;
    right: 0; }
    .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right).bp3-vertical.bp3-overlay-enter, .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right).bp3-vertical.bp3-overlay-appear {
      transform: translateY(100%); }
    .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right).bp3-vertical.bp3-overlay-enter-active, .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right).bp3-vertical.bp3-overlay-appear-active {
      transform: translateY(0);
      transition-delay: 0;
      transition-duration: 200ms;
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
    .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right).bp3-vertical.bp3-overlay-exit {
      transform: translateY(0); }
    .bp3-drawer:not(.bp3-position-top):not(.bp3-position-bottom):not(.bp3-position-left):not(
    .bp3-position-right).bp3-vertical.bp3-overlay-exit-active {
      transform: translateY(100%);
      transition-delay: 0;
      transition-duration: 100ms;
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-drawer.bp3-dark,
  .bp3-dark .bp3-drawer {
    background: #dadada;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 4px 8px rgba(16, 22, 26, 0.4), 0 18px 46px 6px rgba(16, 22, 26, 0.4);
    color: #3c3c3b; }

.bp3-drawer-header {
  align-items: center;
  border-radius: 0;
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15);
  display: flex;
  flex: 0 0 auto;
  min-height: 40px;
  padding: 5px;
  padding-left: 20px;
  position: relative; }
  .bp3-drawer-header .bp3-icon-large,
  .bp3-drawer-header .bp3-icon {
    color: #ffffff;
    flex: 0 0 auto;
    margin-right: 10px; }
  .bp3-drawer-header .bp3-heading {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    flex: 1 1 auto;
    line-height: inherit;
    margin: 0; }
    .bp3-drawer-header .bp3-heading:last-child {
      margin-right: 20px; }
  .bp3-dark .bp3-drawer-header {
    box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-drawer-header .bp3-icon-large,
    .bp3-dark .bp3-drawer-header .bp3-icon {
      color: #e94e1b; }

.bp3-drawer-body {
  flex: 1 1 auto;
  line-height: 18px;
  overflow: auto; }

.bp3-drawer-footer {
  box-shadow: inset 0 1px 0 rgba(16, 22, 26, 0.15);
  flex: 0 0 auto;
  padding: 10px 20px;
  position: relative; }
  .bp3-dark .bp3-drawer-footer {
    box-shadow: inset 0 1px 0 rgba(16, 22, 26, 0.4); }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.bp3-editable-text {
  cursor: text;
  display: inline-block;
  max-width: 100%;
  position: relative;
  vertical-align: top;
  white-space: nowrap; }
  .bp3-editable-text::before {
    bottom: -3px;
    left: -3px;
    position: absolute;
    right: -3px;
    top: -3px;
    border-radius: 3px;
    content: "";
    transition: background-color 100ms cubic-bezier(0.4, 1, 0.75, 0.9), box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-editable-text:hover::before {
    box-shadow: 0 0 0 0 rgba(233, 78, 27, 0), 0 0 0 0 rgba(233, 78, 27, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15); }
  .bp3-editable-text.bp3-editable-text-editing::before {
    background-color: #ffffff;
    box-shadow: 0 0 0 1px #e94e1b, 0 0 0 3px rgba(233, 78, 27, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .bp3-editable-text.bp3-disabled::before {
    box-shadow: none; }
  .bp3-editable-text.bp3-intent-primary .bp3-editable-text-input,
  .bp3-editable-text.bp3-intent-primary .bp3-editable-text-content {
    color: #e94e1b; }
  .bp3-editable-text.bp3-intent-primary:hover::before {
    box-shadow: 0 0 0 0 rgba(233, 78, 27, 0), 0 0 0 0 rgba(233, 78, 27, 0), inset 0 0 0 1px rgba(233, 78, 27, 0.4); }
  .bp3-editable-text.bp3-intent-primary.bp3-editable-text-editing::before {
    box-shadow: 0 0 0 1px #e94e1b, 0 0 0 3px rgba(233, 78, 27, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .bp3-editable-text.bp3-intent-success .bp3-editable-text-input,
  .bp3-editable-text.bp3-intent-success .bp3-editable-text-content {
    color: #0f9960; }
  .bp3-editable-text.bp3-intent-success:hover::before {
    box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px rgba(15, 153, 96, 0.4); }
  .bp3-editable-text.bp3-intent-success.bp3-editable-text-editing::before {
    box-shadow: 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .bp3-editable-text.bp3-intent-warning .bp3-editable-text-input,
  .bp3-editable-text.bp3-intent-warning .bp3-editable-text-content {
    color: #d9822b; }
  .bp3-editable-text.bp3-intent-warning:hover::before {
    box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px rgba(217, 130, 43, 0.4); }
  .bp3-editable-text.bp3-intent-warning.bp3-editable-text-editing::before {
    box-shadow: 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .bp3-editable-text.bp3-intent-danger .bp3-editable-text-input,
  .bp3-editable-text.bp3-intent-danger .bp3-editable-text-content {
    color: #db3737; }
  .bp3-editable-text.bp3-intent-danger:hover::before {
    box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px rgba(219, 55, 55, 0.4); }
  .bp3-editable-text.bp3-intent-danger.bp3-editable-text-editing::before {
    box-shadow: 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .bp3-dark .bp3-editable-text:hover::before {
    box-shadow: 0 0 0 0 rgba(233, 78, 27, 0), 0 0 0 0 rgba(233, 78, 27, 0), inset 0 0 0 1px rgba(255, 255, 255, 0.15); }
  .bp3-dark .bp3-editable-text.bp3-editable-text-editing::before {
    background-color: rgba(16, 22, 26, 0.3);
    box-shadow: 0 0 0 1px #e94e1b, 0 0 0 3px rgba(233, 78, 27, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-editable-text.bp3-disabled::before {
    box-shadow: none; }
  .bp3-dark .bp3-editable-text.bp3-intent-primary .bp3-editable-text-content {
    color: #ffffff; }
  .bp3-dark .bp3-editable-text.bp3-intent-primary:hover::before {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0), 0 0 0 0 rgba(255, 255, 255, 0), inset 0 0 0 1px rgba(255, 255, 255, 0.4); }
  .bp3-dark .bp3-editable-text.bp3-intent-primary.bp3-editable-text-editing::before {
    box-shadow: 0 0 0 1px white, 0 0 0 3px rgba(255, 255, 255, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-editable-text.bp3-intent-success .bp3-editable-text-content {
    color: #3dcc91; }
  .bp3-dark .bp3-editable-text.bp3-intent-success:hover::before {
    box-shadow: 0 0 0 0 rgba(61, 204, 145, 0), 0 0 0 0 rgba(61, 204, 145, 0), inset 0 0 0 1px rgba(61, 204, 145, 0.4); }
  .bp3-dark .bp3-editable-text.bp3-intent-success.bp3-editable-text-editing::before {
    box-shadow: 0 0 0 1px #3dcc91, 0 0 0 3px rgba(61, 204, 145, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-editable-text.bp3-intent-warning .bp3-editable-text-content {
    color: #ffb366; }
  .bp3-dark .bp3-editable-text.bp3-intent-warning:hover::before {
    box-shadow: 0 0 0 0 rgba(255, 179, 102, 0), 0 0 0 0 rgba(255, 179, 102, 0), inset 0 0 0 1px rgba(255, 179, 102, 0.4); }
  .bp3-dark .bp3-editable-text.bp3-intent-warning.bp3-editable-text-editing::before {
    box-shadow: 0 0 0 1px #ffb366, 0 0 0 3px rgba(255, 179, 102, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-editable-text.bp3-intent-danger .bp3-editable-text-content {
    color: #ff7373; }
  .bp3-dark .bp3-editable-text.bp3-intent-danger:hover::before {
    box-shadow: 0 0 0 0 rgba(255, 115, 115, 0), 0 0 0 0 rgba(255, 115, 115, 0), inset 0 0 0 1px rgba(255, 115, 115, 0.4); }
  .bp3-dark .bp3-editable-text.bp3-intent-danger.bp3-editable-text-editing::before {
    box-shadow: 0 0 0 1px #ff7373, 0 0 0 3px rgba(255, 115, 115, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }

.bp3-editable-text-input,
.bp3-editable-text-content {
  color: inherit;
  display: inherit;
  font: inherit;
  letter-spacing: inherit;
  max-width: inherit;
  min-width: inherit;
  position: relative;
  resize: none;
  text-transform: inherit;
  vertical-align: top; }

.bp3-editable-text-input {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
  white-space: pre-wrap;
  width: 100%; }
  .bp3-editable-text-input::placeholder {
    color: rgba(255, 255, 255, 0.6);
    opacity: 1; }
  .bp3-editable-text-input:focus {
    outline: none; }
  .bp3-editable-text-input::-ms-clear {
    display: none; }

.bp3-editable-text-content {
  overflow: hidden;
  padding-right: 2px;
  text-overflow: ellipsis;
  white-space: pre; }
  .bp3-editable-text-editing > .bp3-editable-text-content {
    left: 0;
    position: absolute;
    visibility: hidden; }
  .bp3-editable-text-placeholder > .bp3-editable-text-content {
    color: rgba(255, 255, 255, 0.6); }
    .bp3-dark .bp3-editable-text-placeholder > .bp3-editable-text-content {
      color: rgba(233, 78, 27, 0.6); }

.bp3-editable-text.bp3-multiline {
  display: block; }
  .bp3-editable-text.bp3-multiline .bp3-editable-text-content {
    overflow: auto;
    white-space: pre-wrap;
    word-wrap: break-word; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.bp3-divider {
  border-bottom: 1px solid rgba(16, 22, 26, 0.15);
  border-right: 1px solid rgba(16, 22, 26, 0.15);
  margin: 5px; }
  .bp3-dark .bp3-divider {
    border-color: rgba(16, 22, 26, 0.4); }

/*
Control groups

Markup:
<div class="bp3-control-group">
  <button class="bp3-button bp3-icon-filter">Filter</button>
  <input type="text" class="bp3-input" placeholder="Find filters..." />
</div>
<div class="bp3-control-group">
  <div class="bp3-select">
    <select>
      <option selected>Filter...</option>
      <option value="1">Issues</option>
      <option value="2">Requests</option>
      <option value="3">Projects</option>
    </select>
  </div>
  <div class="bp3-input-group">
    <span class="bp3-icon bp3-icon-search"></span>
    <input type="text" class="bp3-input" value="from:ggray to:allorca" />
  </div>
</div>
<div class="bp3-control-group">
  <div class="bp3-input-group">
    <span class="bp3-icon bp3-icon-people"></span>
    <input type="text" class="bp3-input" placeholder="Find collaborators..." style="padding-right:94px" />
    <div class="bp3-input-action">
      <button class="bp3-button bp3-minimal bp3-intent-primary">
        can view<span class="bp3-icon-standard bp3-icon-caret-down bp3-align-right"></span>
      </button>
    </div>
  </div>
  <button class="bp3-button bp3-intent-primary">Add</button>
</div>
<div class="bp3-control-group">
  <div class="bp3-select">
    <select>
      <option selected value="dollar">$</option>
      <option value="euro">€</option>
    </select>
  </div>
  <div class="bp3-control-group bp3-numeric-input">
    <div class="bp3-input-group">
      <input type="text" autocomplete="off" class="bp3-input" style="padding-right: 10px;" value="1">
    </div>
    <div class="bp3-button-group bp3-vertical bp3-fixed">
      <button type="button" class="bp3-button">
        <span icon="chevron-up" class="bp3-icon bp3-icon-chevron-up">
          <svg data-icon="chevron-up" width="16" height="16" viewBox="0 0 16 16"><desc>chevron-up</desc><path d="M12.71 9.29l-4-4C8.53 5.11 8.28 5 8 5s-.53.11-.71.29l-4 4a1.003 1.003 0 001.42 1.42L8 7.41l3.29 3.29c.18.19.43.3.71.3a1.003 1.003 0 00.71-1.71z" fill-rule="evenodd"></path></svg>
        </span>
      </button>
      <button type="button" class="bp3-button">
        <span icon="chevron-down" class="bp3-icon bp3-icon-chevron-down">
          <svg data-icon="chevron-down" width="16" height="16" viewBox="0 0 16 16"><desc>chevron-down</desc><path d="M12 5c-.28 0-.53.11-.71.29L8 8.59l-3.29-3.3a1.003 1.003 0 00-1.42 1.42l4 4c.18.18.43.29.71.29s.53-.11.71-.29l4-4A1.003 1.003 0 0012 5z" fill-rule="evenodd"></path></svg>
        </span>
      </button>
    </div>
  </div>
</div>

Styleguide control-group
*/
.bp3-control-group {
  transform: translateZ(0);
  display: flex;
  flex-direction: row;
  align-items: stretch;
  /*
  Responsive control groups

  Markup:
  <div class="bp3-control-group">
    <div class="bp3-input-group bp3-fill">
      <span class="bp3-icon bp3-icon-people"></span>
      <input type="text" class="bp3-input" placeholder="Find collaborators..." />
    </div>
    <button class="bp3-button bp3-intent-primary">Add</button>
  </div>
  <div class="bp3-control-group bp3-fill">
    <button class="bp3-button bp3-icon-minus bp3-fixed"></button>
    <input type="text" class="bp3-input" placeholder="Enter a value..." />
    <button class="bp3-button bp3-icon-plus bp3-fixed"></button>
  </div>

  Styleguide control-group-fill
  */
  /*
  Vertical control groups

  Markup:
  <div class="bp3-control-group bp3-vertical" style="width: 300px;">
    <div class="bp3-input-group bp3-large">
      <span class="bp3-icon bp3-icon-person"></span>
      <input type="text" class="bp3-input" placeholder="Username" />
    </div>
    <div class="bp3-input-group bp3-large">
      <span class="bp3-icon bp3-icon-lock"></span>
      <input type="password" class="bp3-input" placeholder="Password" />
    </div>
    <button class="bp3-button bp3-large bp3-intent-primary">Login</button>
  </div>

  Styleguide control-group-vertical
  */ }
  .bp3-control-group > * {
    flex-grow: 0;
    flex-shrink: 0; }
  .bp3-control-group > .bp3-fill {
    flex-grow: 1;
    flex-shrink: 1; }
  .bp3-control-group .bp3-button,
  .bp3-control-group .bp3-html-select,
  .bp3-control-group .bp3-input,
  .bp3-control-group .bp3-select {
    position: relative; }
  .bp3-control-group .bp3-input {
    border-radius: inherit;
    z-index: 2; }
    .bp3-control-group .bp3-input:focus {
      border-radius: 3px;
      z-index: 14; }
    .bp3-control-group .bp3-input[class*="bp3-intent"] {
      z-index: 13; }
      .bp3-control-group .bp3-input[class*="bp3-intent"]:focus {
        z-index: 15; }
    .bp3-control-group .bp3-input[readonly], .bp3-control-group .bp3-input:disabled, .bp3-control-group .bp3-input.bp3-disabled {
      z-index: 1; }
  .bp3-control-group .bp3-input-group[class*="bp3-intent"] .bp3-input {
    z-index: 13; }
    .bp3-control-group .bp3-input-group[class*="bp3-intent"] .bp3-input:focus {
      z-index: 15; }
  .bp3-control-group .bp3-button,
  .bp3-control-group .bp3-html-select select,
  .bp3-control-group .bp3-select select {
    transform: translateZ(0);
    border-radius: inherit;
    z-index: 4; }
    .bp3-control-group .bp3-button:focus,
    .bp3-control-group .bp3-html-select select:focus,
    .bp3-control-group .bp3-select select:focus {
      z-index: 5; }
    .bp3-control-group .bp3-button:hover,
    .bp3-control-group .bp3-html-select select:hover,
    .bp3-control-group .bp3-select select:hover {
      z-index: 6; }
    .bp3-control-group .bp3-button:active,
    .bp3-control-group .bp3-html-select select:active,
    .bp3-control-group .bp3-select select:active {
      z-index: 7; }
    .bp3-control-group .bp3-button[readonly], .bp3-control-group .bp3-button:disabled, .bp3-control-group .bp3-button.bp3-disabled,
    .bp3-control-group .bp3-html-select select[readonly],
    .bp3-control-group .bp3-html-select select:disabled,
    .bp3-control-group .bp3-html-select select.bp3-disabled,
    .bp3-control-group .bp3-select select[readonly],
    .bp3-control-group .bp3-select select:disabled,
    .bp3-control-group .bp3-select select.bp3-disabled {
      z-index: 3; }
    .bp3-control-group .bp3-button[class*="bp3-intent"],
    .bp3-control-group .bp3-html-select select[class*="bp3-intent"],
    .bp3-control-group .bp3-select select[class*="bp3-intent"] {
      z-index: 9; }
      .bp3-control-group .bp3-button[class*="bp3-intent"]:focus,
      .bp3-control-group .bp3-html-select select[class*="bp3-intent"]:focus,
      .bp3-control-group .bp3-select select[class*="bp3-intent"]:focus {
        z-index: 10; }
      .bp3-control-group .bp3-button[class*="bp3-intent"]:hover,
      .bp3-control-group .bp3-html-select select[class*="bp3-intent"]:hover,
      .bp3-control-group .bp3-select select[class*="bp3-intent"]:hover {
        z-index: 11; }
      .bp3-control-group .bp3-button[class*="bp3-intent"]:active,
      .bp3-control-group .bp3-html-select select[class*="bp3-intent"]:active,
      .bp3-control-group .bp3-select select[class*="bp3-intent"]:active {
        z-index: 12; }
      .bp3-control-group .bp3-button[class*="bp3-intent"][readonly], .bp3-control-group .bp3-button[class*="bp3-intent"]:disabled, .bp3-control-group .bp3-button[class*="bp3-intent"].bp3-disabled,
      .bp3-control-group .bp3-html-select select[class*="bp3-intent"][readonly],
      .bp3-control-group .bp3-html-select select[class*="bp3-intent"]:disabled,
      .bp3-control-group .bp3-html-select select[class*="bp3-intent"].bp3-disabled,
      .bp3-control-group .bp3-select select[class*="bp3-intent"][readonly],
      .bp3-control-group .bp3-select select[class*="bp3-intent"]:disabled,
      .bp3-control-group .bp3-select select[class*="bp3-intent"].bp3-disabled {
        z-index: 8; }
  .bp3-control-group .bp3-input-group > .bp3-icon,
  .bp3-control-group .bp3-input-group > .bp3-button,
  .bp3-control-group .bp3-input-group > .bp3-input-action {
    z-index: 16; }
  .bp3-control-group .bp3-select::after,
  .bp3-control-group .bp3-html-select::after,
  .bp3-control-group .bp3-select > .bp3-icon,
  .bp3-control-group .bp3-html-select > .bp3-icon {
    z-index: 17; }
  .bp3-control-group .bp3-select:focus-within {
    z-index: 5; }
  .bp3-control-group:not(.bp3-vertical) > *:not(.bp3-divider) {
    margin-right: -1px; }
  .bp3-control-group:not(.bp3-vertical) > .bp3-divider:not(:first-child) {
    margin-left: 6px; }
  .bp3-dark .bp3-control-group:not(.bp3-vertical) > *:not(.bp3-divider) {
    margin-right: 0; }
  .bp3-dark .bp3-control-group:not(.bp3-vertical) > .bp3-button + .bp3-button {
    margin-left: 1px; }
  .bp3-control-group .bp3-popover-wrapper,
  .bp3-control-group .bp3-popover-target {
    border-radius: inherit; }
  .bp3-control-group > :first-child {
    border-radius: 3px 0 0 3px; }
  .bp3-control-group > :last-child {
    border-radius: 0 3px 3px 0;
    margin-right: 0; }
  .bp3-control-group > :only-child {
    border-radius: 3px;
    margin-right: 0; }
  .bp3-control-group .bp3-input-group .bp3-button {
    border-radius: 3px; }
  .bp3-control-group .bp3-numeric-input:not(:first-child) .bp3-input-group {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .bp3-control-group > .bp3-fill {
    flex: 1 1 auto; }
  .bp3-control-group.bp3-fill > *:not(.bp3-fixed) {
    flex: 1 1 auto; }
  .bp3-control-group.bp3-vertical {
    flex-direction: column; }
    .bp3-control-group.bp3-vertical > * {
      margin-top: -1px; }
    .bp3-control-group.bp3-vertical > :first-child {
      border-radius: 3px 3px 0 0;
      margin-top: 0; }
    .bp3-control-group.bp3-vertical > :last-child {
      border-radius: 0 0 3px 3px; }

/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.bp3-control {
  cursor: pointer;
  display: block;
  margin-bottom: 10px;
  position: relative;
  text-transform: none;
  /*
  Checkbox

  Markup:
  <label class="bp3-control bp3-checkbox {{.modifier}}">
    <input type="checkbox" {{:modifier}} />
    <span class="bp3-control-indicator"></span>
    Checkbox
  </label>

  :checked  - Checked
  :disabled - Disabled. Also add <code>.bp3-disabled</code> to <code>.bp3-control</code> to change text color (not shown below).
  :indeterminate - Indeterminate. Note that this style can only be achieved via JavaScript
                   <code>input.indeterminate = true</code>.
  .bp3-align-right - Right-aligned indicator
  .bp3-large - Large

  Styleguide checkbox
  */
  /*
  Radio

  Markup:
  <label class="bp3-control bp3-radio {{.modifier}}">
    <input type="radio" name="docs-radio-regular" {{:modifier}} />
    <span class="bp3-control-indicator"></span>
    Radio
  </label>

  :checked  - Selected
  :disabled - Disabled. Also add <code>.bp3-disabled</code> to <code>.bp3-control</code> to change text color (not shown below).
  .bp3-align-right - Right-aligned indicator
  .bp3-large - Large

  Styleguide radio
  */
  /*
  Switch

  Markup:
  <label class="bp3-control bp3-switch {{.modifier}}">
    <input type="checkbox" {{:modifier}} />
    <span class="bp3-control-indicator"></span>
    Switch
  </label>

  :checked  - Selected
  :disabled - Disabled. Also add <code>.bp3-disabled</code> to <code>.bp3-control</code> to change text color (not shown below).
  .bp3-align-right - Right-aligned indicator
  .bp3-large - Large

  Styleguide switch
  */
  /*
  Inline labels

  Markup:
  <div>
    <label class="bp3-label">A group of related options</label>
    <label class="bp3-control bp3-checkbox bp3-inline">
      <input type="checkbox" />
      <span class="bp3-control-indicator"></span>
      First
    </label>
    <label class="bp3-control bp3-checkbox bp3-inline">
      <input type="checkbox" />
      <span class="bp3-control-indicator"></span>
      Second
    </label>
    <label class="bp3-control bp3-checkbox bp3-inline">
      <input type="checkbox" />
      <span class="bp3-control-indicator"></span>
      Third
    </label>
  </div>

  Styleguide checkbox-inline
  */ }
  .bp3-control input:checked ~ .bp3-control-indicator {
    background-color: #e94e1b;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
    color: #ffffff; }
  .bp3-control:hover input:checked ~ .bp3-control-indicator {
    background-color: #e94e1b;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2); }
  .bp3-control input:not(:disabled):active:checked ~ .bp3-control-indicator {
    background: #ffffff;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
  .bp3-control input:disabled:checked ~ .bp3-control-indicator {
    background: rgba(233, 78, 27, 0.5);
    box-shadow: none; }
  .bp3-dark .bp3-control input:checked ~ .bp3-control-indicator {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-control:hover input:checked ~ .bp3-control-indicator {
    background-color: #e94e1b;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-control input:not(:disabled):active:checked ~ .bp3-control-indicator {
    background-color: #ffffff;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
  .bp3-dark .bp3-control input:disabled:checked ~ .bp3-control-indicator {
    background: rgba(255, 255, 255, 0.5);
    box-shadow: none; }
  .bp3-control:not(.bp3-align-right) {
    padding-left: 26px; }
    .bp3-control:not(.bp3-align-right) .bp3-control-indicator {
      margin-left: -26px; }
  .bp3-control.bp3-align-right {
    padding-right: 26px; }
    .bp3-control.bp3-align-right .bp3-control-indicator {
      margin-right: -26px; }
  .bp3-control.bp3-disabled {
    color: rgba(255, 255, 255, 0.6);
    cursor: not-allowed; }
  .bp3-control.bp3-inline {
    display: inline-block;
    margin-right: 20px; }
  .bp3-control input {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: -1; }
  .bp3-control .bp3-control-indicator {
    background-clip: padding-box;
    background-color: #3c3c3b;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
    border: none;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    height: 1em;
    margin-right: 10px;
    margin-top: -3px;
    position: relative;
    user-select: none;
    vertical-align: middle;
    width: 1em; }
    .bp3-control .bp3-control-indicator::before {
      content: "";
      display: block;
      height: 1em;
      width: 1em; }
  .bp3-control:hover .bp3-control-indicator {
    background-color: #e94e1b; }
  .bp3-control input:not(:disabled):active ~ .bp3-control-indicator {
    background: #e94e1b;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
  .bp3-control input:disabled ~ .bp3-control-indicator {
    background: rgba(255, 255, 255, 0.5);
    box-shadow: none;
    cursor: not-allowed; }
  .bp3-control input:focus ~ .bp3-control-indicator {
    outline: rgba(233, 78, 27, 0.6) auto 2px;
    outline-offset: 2px;
    -moz-outline-radius: 6px; }
  .bp3-control.bp3-align-right .bp3-control-indicator {
    float: right;
    margin-left: 10px;
    margin-top: 1px; }
  .bp3-control.bp3-large {
    font-size: 16px; }
    .bp3-control.bp3-large:not(.bp3-align-right) {
      padding-left: 30px; }
      .bp3-control.bp3-large:not(.bp3-align-right) .bp3-control-indicator {
        margin-left: -30px; }
    .bp3-control.bp3-large.bp3-align-right {
      padding-right: 30px; }
      .bp3-control.bp3-large.bp3-align-right .bp3-control-indicator {
        margin-right: -30px; }
    .bp3-control.bp3-large .bp3-control-indicator {
      font-size: 20px; }
    .bp3-control.bp3-large.bp3-align-right .bp3-control-indicator {
      margin-top: 0; }
  .bp3-control.bp3-checkbox input:indeterminate ~ .bp3-control-indicator {
    background-color: #e94e1b;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
    color: #ffffff; }
  .bp3-control.bp3-checkbox:hover input:indeterminate ~ .bp3-control-indicator {
    background-color: #e94e1b;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2); }
  .bp3-control.bp3-checkbox input:not(:disabled):active:indeterminate ~ .bp3-control-indicator {
    background: #ffffff;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
  .bp3-control.bp3-checkbox input:disabled:indeterminate ~ .bp3-control-indicator {
    background: rgba(233, 78, 27, 0.5);
    box-shadow: none; }
  .bp3-dark .bp3-control.bp3-checkbox input:indeterminate ~ .bp3-control-indicator {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-control.bp3-checkbox:hover input:indeterminate ~ .bp3-control-indicator {
    background-color: #e94e1b;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-control.bp3-checkbox input:not(:disabled):active:indeterminate ~ .bp3-control-indicator {
    background-color: #ffffff;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
  .bp3-dark .bp3-control.bp3-checkbox input:disabled:indeterminate ~ .bp3-control-indicator {
    background: rgba(255, 255, 255, 0.5);
    box-shadow: none; }
  .bp3-control.bp3-checkbox .bp3-control-indicator {
    border-radius: 3px; }
  .bp3-control.bp3-checkbox input:checked ~ .bp3-control-indicator::before {
    background-image: svg-icon("16px/small-tick.svg", (path: (fill: #ffffff))); }
  .bp3-control.bp3-checkbox input:indeterminate ~ .bp3-control-indicator::before {
    background-image: svg-icon("16px/small-minus.svg", (path: (fill: #ffffff))); }
  .bp3-control.bp3-radio .bp3-control-indicator {
    border-radius: 50%; }
  .bp3-control.bp3-radio input:checked ~ .bp3-control-indicator::before {
    background-image: radial-gradient(#ffffff, #ffffff 28%, transparent 32%); }
  .bp3-control.bp3-radio input:checked:disabled ~ .bp3-control-indicator::before {
    opacity: 0.5; }
  .bp3-control.bp3-radio input:focus ~ .bp3-control-indicator {
    -moz-outline-radius: 16px; }
  .bp3-control.bp3-switch input ~ .bp3-control-indicator {
    background: rgba(233, 78, 27, 0.5); }
  .bp3-control.bp3-switch:hover input ~ .bp3-control-indicator {
    background: rgba(233, 78, 27, 0.5); }
  .bp3-control.bp3-switch input:not(:disabled):active ~ .bp3-control-indicator {
    background: rgba(255, 255, 255, 0.5); }
  .bp3-control.bp3-switch input:disabled ~ .bp3-control-indicator {
    background: rgba(255, 255, 255, 0.5); }
    .bp3-control.bp3-switch input:disabled ~ .bp3-control-indicator::before {
      background: rgba(255, 255, 255, 0.8); }
  .bp3-control.bp3-switch input:checked ~ .bp3-control-indicator {
    background: #e94e1b; }
  .bp3-control.bp3-switch:hover input:checked ~ .bp3-control-indicator {
    background: #e94e1b; }
  .bp3-control.bp3-switch input:checked:not(:disabled):active ~ .bp3-control-indicator {
    background: #ffffff; }
  .bp3-control.bp3-switch input:checked:disabled ~ .bp3-control-indicator {
    background: rgba(233, 78, 27, 0.5); }
    .bp3-control.bp3-switch input:checked:disabled ~ .bp3-control-indicator::before {
      background: rgba(255, 255, 255, 0.8); }
  .bp3-control.bp3-switch:not(.bp3-align-right) {
    padding-left: 38px; }
    .bp3-control.bp3-switch:not(.bp3-align-right) .bp3-control-indicator {
      margin-left: -38px; }
  .bp3-control.bp3-switch.bp3-align-right {
    padding-right: 38px; }
    .bp3-control.bp3-switch.bp3-align-right .bp3-control-indicator {
      margin-right: -38px; }
  .bp3-control.bp3-switch .bp3-control-indicator {
    border: none;
    border-radius: 1.75em;
    box-shadow: none !important;
    min-width: 1.75em;
    transition: background-color 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
    width: auto; }
    .bp3-control.bp3-switch .bp3-control-indicator::before {
      background: #ffffff;
      border-radius: 50%;
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.2);
      height: calc(1em - 4px);
      left: 0;
      margin: 2px;
      position: absolute;
      transition: left 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
      width: calc(1em - 4px); }
  .bp3-control.bp3-switch input:checked ~ .bp3-control-indicator::before {
    left: calc(100% - 1em); }
  .bp3-control.bp3-switch.bp3-large:not(.bp3-align-right) {
    padding-left: 45px; }
    .bp3-control.bp3-switch.bp3-large:not(.bp3-align-right) .bp3-control-indicator {
      margin-left: -45px; }
  .bp3-control.bp3-switch.bp3-large.bp3-align-right {
    padding-right: 45px; }
    .bp3-control.bp3-switch.bp3-large.bp3-align-right .bp3-control-indicator {
      margin-right: -45px; }
  .bp3-dark .bp3-control.bp3-switch input ~ .bp3-control-indicator {
    background: rgba(16, 22, 26, 0.5); }
  .bp3-dark .bp3-control.bp3-switch:hover input ~ .bp3-control-indicator {
    background: rgba(16, 22, 26, 0.7); }
  .bp3-dark .bp3-control.bp3-switch input:not(:disabled):active ~ .bp3-control-indicator {
    background: rgba(16, 22, 26, 0.9); }
  .bp3-dark .bp3-control.bp3-switch input:disabled ~ .bp3-control-indicator {
    background: rgba(255, 255, 255, 0.5); }
    .bp3-dark .bp3-control.bp3-switch input:disabled ~ .bp3-control-indicator::before {
      background: rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-control.bp3-switch input:checked ~ .bp3-control-indicator {
    background: #e94e1b; }
  .bp3-dark .bp3-control.bp3-switch:hover input:checked ~ .bp3-control-indicator {
    background: #e94e1b; }
  .bp3-dark .bp3-control.bp3-switch input:checked:not(:disabled):active ~ .bp3-control-indicator {
    background: #ffffff; }
  .bp3-dark .bp3-control.bp3-switch input:checked:disabled ~ .bp3-control-indicator {
    background: rgba(255, 255, 255, 0.5); }
    .bp3-dark .bp3-control.bp3-switch input:checked:disabled ~ .bp3-control-indicator::before {
      background: rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-control.bp3-switch .bp3-control-indicator::before {
    background: #ffffff;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-control.bp3-switch input:checked ~ .bp3-control-indicator::before {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4); }
  .bp3-control.bp3-switch .bp3-switch-inner-text {
    font-size: 0.7em;
    text-align: center; }
  .bp3-control.bp3-switch .bp3-control-indicator-child:first-child {
    line-height: 0;
    margin-left: 0.5em;
    margin-right: 1.2em;
    visibility: hidden; }
  .bp3-control.bp3-switch .bp3-control-indicator-child:last-child {
    line-height: 1em;
    margin-left: 1.2em;
    margin-right: 0.5em;
    visibility: visible; }
  .bp3-control.bp3-switch input:checked ~ .bp3-control-indicator .bp3-control-indicator-child:first-child {
    line-height: 1em;
    visibility: visible; }
  .bp3-control.bp3-switch input:checked ~ .bp3-control-indicator .bp3-control-indicator-child:last-child {
    line-height: 0;
    visibility: hidden; }
  .bp3-dark .bp3-control {
    color: #3c3c3b; }
    .bp3-dark .bp3-control.bp3-disabled {
      color: rgba(233, 78, 27, 0.6); }
    .bp3-dark .bp3-control .bp3-control-indicator {
      background-color: #ffffff;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-control:hover .bp3-control-indicator {
      background-color: #dadada; }
    .bp3-dark .bp3-control input:not(:disabled):active ~ .bp3-control-indicator {
      background: #ffffff;
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
    .bp3-dark .bp3-control input:disabled ~ .bp3-control-indicator {
      background: rgba(255, 255, 255, 0.5);
      box-shadow: none;
      cursor: not-allowed; }
    .bp3-dark .bp3-control.bp3-checkbox input:disabled:checked ~ .bp3-control-indicator, .bp3-dark .bp3-control.bp3-checkbox input:disabled:indeterminate ~ .bp3-control-indicator {
      color: rgba(233, 78, 27, 0.6); }

/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/*
File input

Markup:
<label class="bp3-file-input {{.modifier}}">
  <input type="file" {{:modifier}}/>
  <span class="bp3-file-upload-input">Choose file...</span>
</label>

:disabled - Disabled
.bp3-large - Larger size
.bp3-fill - Take up full width of parent element
.bp3-file-input-has-selection - User has made a selection

Styleguide file-input
*/
.bp3-file-input {
  cursor: pointer;
  display: inline-block;
  height: 30px;
  position: relative; }
  .bp3-file-input input {
    margin: 0;
    min-width: 200px;
    opacity: 0; }
    .bp3-file-input input:disabled + .bp3-file-upload-input,
    .bp3-file-input input.bp3-disabled + .bp3-file-upload-input {
      background: rgba(255, 255, 255, 0.5);
      box-shadow: none;
      color: rgba(255, 255, 255, 0.6);
      cursor: not-allowed;
      resize: none; }
      .bp3-file-input input:disabled + .bp3-file-upload-input::after,
      .bp3-file-input input.bp3-disabled + .bp3-file-upload-input::after {
        background-color: rgba(255, 255, 255, 0.5);
        background-image: none;
        box-shadow: none;
        color: rgba(255, 255, 255, 0.6);
        cursor: not-allowed;
        outline: none; }
        .bp3-file-input input:disabled + .bp3-file-upload-input::after.bp3-active, .bp3-file-input input:disabled + .bp3-file-upload-input::after.bp3-active:hover,
        .bp3-file-input input.bp3-disabled + .bp3-file-upload-input::after.bp3-active,
        .bp3-file-input input.bp3-disabled + .bp3-file-upload-input::after.bp3-active:hover {
          background: rgba(255, 255, 255, 0.7); }
      .bp3-dark .bp3-file-input input:disabled + .bp3-file-upload-input, .bp3-dark
      .bp3-file-input input.bp3-disabled + .bp3-file-upload-input {
        background: rgba(255, 255, 255, 0.5);
        box-shadow: none;
        color: rgba(233, 78, 27, 0.6); }
        .bp3-dark .bp3-file-input input:disabled + .bp3-file-upload-input::after, .bp3-dark
        .bp3-file-input input.bp3-disabled + .bp3-file-upload-input::after {
          background-color: rgba(255, 255, 255, 0.5);
          background-image: none;
          box-shadow: none;
          color: rgba(233, 78, 27, 0.6); }
          .bp3-dark .bp3-file-input input:disabled + .bp3-file-upload-input::after.bp3-active, .bp3-dark
          .bp3-file-input input.bp3-disabled + .bp3-file-upload-input::after.bp3-active {
            background: rgba(255, 255, 255, 0.7); }
  .bp3-file-input.bp3-file-input-has-selection .bp3-file-upload-input {
    color: #182026; }
  .bp3-dark .bp3-file-input.bp3-file-input-has-selection .bp3-file-upload-input {
    color: #3c3c3b; }
  .bp3-file-input.bp3-fill {
    width: 100%; }
  .bp3-file-input.bp3-large,
  .bp3-large .bp3-file-input {
    height: 40px; }
  .bp3-file-input .bp3-file-upload-input-custom-text::after {
    content: attr(bp3-button-text); }

.bp3-file-upload-input {
  appearance: none;
  background: #ffffff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 0 rgba(233, 78, 27, 0), 0 0 0 0 rgba(233, 78, 27, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  color: #182026;
  font-size: 14px;
  font-weight: 400;
  height: 30px;
  line-height: 30px;
  outline: none;
  padding: 0 10px;
  transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  color: rgba(255, 255, 255, 0.6);
  left: 0;
  padding-right: 80px;
  position: absolute;
  right: 0;
  top: 0;
  user-select: none; }
  .bp3-file-upload-input::placeholder {
    color: rgba(255, 255, 255, 0.6);
    opacity: 1; }
  .bp3-file-upload-input:focus, .bp3-file-upload-input.bp3-active {
    box-shadow: 0 0 0 1px #e94e1b, 0 0 0 3px rgba(233, 78, 27, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .bp3-file-upload-input[type="search"], .bp3-file-upload-input.bp3-round {
    border-radius: 30px;
    box-sizing: border-box;
    padding-left: 10px; }
  .bp3-file-upload-input[readonly] {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.15); }
  .bp3-file-upload-input:disabled, .bp3-file-upload-input.bp3-disabled {
    background: rgba(255, 255, 255, 0.5);
    box-shadow: none;
    color: rgba(255, 255, 255, 0.6);
    cursor: not-allowed;
    resize: none; }
  .bp3-file-upload-input::after {
    background-color: #3c3c3b;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
    color: #182026;
    min-height: 24px;
    min-width: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    border-radius: 3px;
    content: "Browse";
    line-height: 24px;
    margin: 3px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 70px; }
    .bp3-file-upload-input::after:hover {
      background-clip: padding-box;
      background-color: #e94e1b;
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1); }
    .bp3-file-upload-input::after:active, .bp3-file-upload-input::after.bp3-active {
      background-color: #e94e1b;
      background-image: none;
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
    .bp3-file-upload-input::after:disabled, .bp3-file-upload-input::after.bp3-disabled {
      background-color: rgba(255, 255, 255, 0.5);
      background-image: none;
      box-shadow: none;
      color: rgba(255, 255, 255, 0.6);
      cursor: not-allowed;
      outline: none; }
      .bp3-file-upload-input::after:disabled.bp3-active, .bp3-file-upload-input::after:disabled.bp3-active:hover, .bp3-file-upload-input::after.bp3-disabled.bp3-active, .bp3-file-upload-input::after.bp3-disabled.bp3-active:hover {
        background: rgba(255, 255, 255, 0.7); }
  .bp3-file-upload-input:hover::after {
    background-clip: padding-box;
    background-color: #e94e1b;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1); }
  .bp3-file-upload-input:active::after {
    background-color: #e94e1b;
    background-image: none;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
  .bp3-large .bp3-file-upload-input {
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    padding-right: 95px; }
    .bp3-large .bp3-file-upload-input[type="search"], .bp3-large .bp3-file-upload-input.bp3-round {
      padding: 0 15px; }
    .bp3-large .bp3-file-upload-input::after {
      min-height: 30px;
      min-width: 30px;
      line-height: 30px;
      margin: 5px;
      width: 85px; }
  .bp3-dark .bp3-file-upload-input {
    background: rgba(16, 22, 26, 0.3);
    box-shadow: 0 0 0 0 rgba(233, 78, 27, 0), 0 0 0 0 rgba(233, 78, 27, 0), 0 0 0 0 rgba(233, 78, 27, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
    color: #3c3c3b;
    color: rgba(233, 78, 27, 0.6); }
    .bp3-dark .bp3-file-upload-input::placeholder {
      color: rgba(233, 78, 27, 0.6); }
    .bp3-dark .bp3-file-upload-input:focus {
      box-shadow: 0 0 0 1px #e94e1b, 0 0 0 1px #e94e1b, 0 0 0 3px rgba(233, 78, 27, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-file-upload-input[readonly] {
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-file-upload-input:disabled, .bp3-dark .bp3-file-upload-input.bp3-disabled {
      background: rgba(255, 255, 255, 0.5);
      box-shadow: none;
      color: rgba(233, 78, 27, 0.6); }
    .bp3-dark .bp3-file-upload-input::after {
      background-color: #ffffff;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
      color: #3c3c3b; }
      .bp3-dark .bp3-file-upload-input::after:hover, .bp3-dark .bp3-file-upload-input::after:active, .bp3-dark .bp3-file-upload-input::after.bp3-active {
        color: #3c3c3b; }
      .bp3-dark .bp3-file-upload-input::after:hover {
        background-color: #dadada;
        box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
      .bp3-dark .bp3-file-upload-input::after:active, .bp3-dark .bp3-file-upload-input::after.bp3-active {
        background-color: #ffffff;
        background-image: none;
        box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
      .bp3-dark .bp3-file-upload-input::after:disabled, .bp3-dark .bp3-file-upload-input::after.bp3-disabled {
        background-color: rgba(255, 255, 255, 0.5);
        background-image: none;
        box-shadow: none;
        color: rgba(233, 78, 27, 0.6); }
        .bp3-dark .bp3-file-upload-input::after:disabled.bp3-active, .bp3-dark .bp3-file-upload-input::after.bp3-disabled.bp3-active {
          background: rgba(255, 255, 255, 0.7); }
      .bp3-dark .bp3-file-upload-input::after .bp3-button-spinner .bp3-spinner-head {
        background: rgba(16, 22, 26, 0.5);
        stroke: #e94e1b; }
    .bp3-dark .bp3-file-upload-input:hover::after {
      background-color: #dadada;
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-file-upload-input:active::after {
      background-color: #ffffff;
      background-image: none;
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2); }

/* stylelint-disable-next-line no-duplicate-selectors */
.bp3-file-upload-input::after {
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1); }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/*
Form groups

Markup:
<div class="bp3-form-group {{.modifier}}">
  <label class="bp3-label" for="form-group-input">
    Label
    <span class="bp3-text-muted">(required)</span>
  </label>
  <div class="bp3-form-content">
    <div class="bp3-input-group {{.modifier}}">
      <span class="bp3-icon bp3-icon-calendar"></span>
      <input id="form-group-input" type="text" {{:modifier}} class="bp3-input"
             style="width: 200px;" placeholder="Placeholder text" dir="auto" />
    </div>
    <div class="bp3-form-helper-text">Please enter a value</div>
  </div>
</div>

:disabled - Disable the input.
.bp3-disabled - Disabled styles. Input must be disabled separately via attribute.
.bp3-inline - Label and content appear side by side.
.bp3-intent-primary - Apply intent to form group and input separately.

Styleguide form-group
*/
.bp3-form-group {
  display: flex;
  flex-direction: column;
  margin: 0 0 15px; }
  .bp3-form-group label.bp3-label {
    margin-bottom: 5px; }
  .bp3-form-group .bp3-control {
    margin-top: 7px; }
  .bp3-form-group .bp3-form-helper-text {
    color: #ffffff;
    font-size: 12px;
    margin-top: 5px; }
  .bp3-form-group.bp3-intent-primary .bp3-form-helper-text {
    color: #e94e1b; }
  .bp3-form-group.bp3-intent-success .bp3-form-helper-text {
    color: #0d8050; }
  .bp3-form-group.bp3-intent-warning .bp3-form-helper-text {
    color: #bf7326; }
  .bp3-form-group.bp3-intent-danger .bp3-form-helper-text {
    color: #c23030; }
  .bp3-form-group.bp3-inline {
    align-items: flex-start;
    flex-direction: row; }
    .bp3-form-group.bp3-inline.bp3-large label.bp3-label {
      line-height: 40px;
      margin: 0 10px 0 0; }
    .bp3-form-group.bp3-inline label.bp3-label {
      line-height: 30px;
      margin: 0 10px 0 0; }
  .bp3-form-group.bp3-disabled .bp3-label,
  .bp3-form-group.bp3-disabled .bp3-text-muted,
  .bp3-form-group.bp3-disabled .bp3-form-helper-text {
    color: rgba(255, 255, 255, 0.6) !important; }
  .bp3-dark .bp3-form-group.bp3-intent-primary .bp3-form-helper-text {
    color: #ffffff; }
  .bp3-dark .bp3-form-group.bp3-intent-success .bp3-form-helper-text {
    color: #3dcc91; }
  .bp3-dark .bp3-form-group.bp3-intent-warning .bp3-form-helper-text {
    color: #ffb366; }
  .bp3-dark .bp3-form-group.bp3-intent-danger .bp3-form-helper-text {
    color: #ff7373; }
  .bp3-dark .bp3-form-group .bp3-form-helper-text {
    color: #e94e1b; }
  .bp3-dark .bp3-form-group.bp3-disabled .bp3-label,
  .bp3-dark .bp3-form-group.bp3-disabled .bp3-text-muted,
  .bp3-dark .bp3-form-group.bp3-disabled .bp3-form-helper-text {
    color: rgba(233, 78, 27, 0.6) !important; }

/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
/*
Input groups

Markup:
<div class="bp3-input-group {{.modifier}}">
  <span class="bp3-icon bp3-icon-filter"></span>
  <input type="text" class="bp3-input" {{:modifier}} placeholder="Filter histogram..." />
</div>
<div class="bp3-input-group {{.modifier}}">
  <input type="password" class="bp3-input" {{:modifier}} placeholder="Enter your password..." />
  <button class="bp3-button bp3-minimal bp3-intent-warning bp3-icon-lock" {{:modifier}}></button>
</div>
<div class="bp3-input-group {{.modifier}}">
  <span class="bp3-icon bp3-icon-search"></span>
  <input type="text" class="bp3-input" {{:modifier}} placeholder="Search" />
  <button class="bp3-button bp3-minimal bp3-intent-primary bp3-icon-arrow-right" {{:modifier}}></button>
</div>

:disabled - Disabled input. Must be added separately to the <code>&#60;input&#62;</code> and <code>&#60;button&#62;</code>. Also add <code>.bp3-disabled</code> to <code>.bp3-input-group</code> for icon coloring (not shown below).
.bp3-round - Rounded caps. Button will also be rounded.
.bp3-large - Large group. Children will adjust size accordingly.
.bp3-small - Small group. Children will adjust size accordingly.
.bp3-intent-primary - Primary intent. (All 4 intents are supported.)
.bp3-fill - Take up full width of parent element.

Styleguide input-group
*/
.bp3-input-group {
  display: block;
  position: relative; }
  .bp3-input-group .bp3-input {
    position: relative;
    width: 100%; }
    .bp3-input-group .bp3-input:not(:first-child) {
      padding-left: 30px; }
    .bp3-input-group .bp3-input:not(:last-child) {
      padding-right: 30px; }
  .bp3-input-group .bp3-input-action,
  .bp3-input-group > .bp3-input-left-container,
  .bp3-input-group > .bp3-button,
  .bp3-input-group > .bp3-icon {
    position: absolute;
    top: 0; }
    .bp3-input-group .bp3-input-action:first-child,
    .bp3-input-group > .bp3-input-left-container:first-child,
    .bp3-input-group > .bp3-button:first-child,
    .bp3-input-group > .bp3-icon:first-child {
      left: 0; }
    .bp3-input-group .bp3-input-action:last-child,
    .bp3-input-group > .bp3-input-left-container:last-child,
    .bp3-input-group > .bp3-button:last-child,
    .bp3-input-group > .bp3-icon:last-child {
      right: 0; }
  .bp3-input-group .bp3-button {
    min-height: 24px;
    min-width: 24px;
    margin: 3px;
    padding: 0 7px; }
    .bp3-input-group .bp3-button:empty {
      padding: 0; }
  .bp3-input-group > .bp3-input-left-container,
  .bp3-input-group > .bp3-icon {
    z-index: 1; }
  .bp3-input-group > .bp3-input-left-container > .bp3-icon,
  .bp3-input-group > .bp3-icon {
    color: #ffffff; }
    .bp3-input-group > .bp3-input-left-container > .bp3-icon:empty,
    .bp3-input-group > .bp3-icon:empty {
      font-family: "Icons16", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased; }
  .bp3-input-group > .bp3-input-left-container > .bp3-icon,
  .bp3-input-group > .bp3-icon,
  .bp3-input-group .bp3-input-action > .bp3-spinner {
    margin: 7px; }
  .bp3-input-group .bp3-tag {
    margin: 5px; }
  .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:not(:hover):not(:focus),
  .bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:not(:hover):not(:focus) {
    color: #ffffff; }
    .bp3-dark .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:not(:hover):not(:focus), .bp3-dark
    .bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:not(:hover):not(:focus) {
      color: #e94e1b; }
    .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:not(:hover):not(:focus) .bp3-icon, .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:not(:hover):not(:focus) .bp3-icon-standard, .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:not(:hover):not(:focus) .bp3-icon-large,
    .bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:not(:hover):not(:focus) .bp3-icon,
    .bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:not(:hover):not(:focus) .bp3-icon-standard,
    .bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:not(:hover):not(:focus) .bp3-icon-large {
      color: #ffffff; }
  .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:disabled,
  .bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:disabled {
    color: rgba(255, 255, 255, 0.6) !important; }
    .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:disabled .bp3-icon, .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:disabled .bp3-icon-standard, .bp3-input-group .bp3-input:not(:focus) + .bp3-button.bp3-minimal:disabled .bp3-icon-large,
    .bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:disabled .bp3-icon,
    .bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:disabled .bp3-icon-standard,
    .bp3-input-group .bp3-input:not(:focus) + .bp3-input-action .bp3-button.bp3-minimal:disabled .bp3-icon-large {
      color: rgba(255, 255, 255, 0.6) !important; }
  .bp3-input-group.bp3-disabled {
    cursor: not-allowed; }
    .bp3-input-group.bp3-disabled .bp3-icon {
      color: rgba(255, 255, 255, 0.6); }
  .bp3-input-group.bp3-large .bp3-button {
    min-height: 30px;
    min-width: 30px;
    margin: 5px; }
  .bp3-input-group.bp3-large > .bp3-input-left-container > .bp3-icon,
  .bp3-input-group.bp3-large > .bp3-icon,
  .bp3-input-group.bp3-large .bp3-input-action > .bp3-spinner {
    margin: 12px; }
  .bp3-input-group.bp3-large .bp3-input {
    font-size: 16px;
    height: 40px;
    line-height: 40px; }
    .bp3-input-group.bp3-large .bp3-input[type="search"], .bp3-input-group.bp3-large .bp3-input.bp3-round {
      padding: 0 15px; }
    .bp3-input-group.bp3-large .bp3-input:not(:first-child) {
      padding-left: 40px; }
    .bp3-input-group.bp3-large .bp3-input:not(:last-child) {
      padding-right: 40px; }
  .bp3-input-group.bp3-small .bp3-button {
    min-height: 20px;
    min-width: 20px;
    margin: 2px; }
  .bp3-input-group.bp3-small .bp3-tag {
    min-height: 20px;
    min-width: 20px;
    margin: 2px; }
  .bp3-input-group.bp3-small > .bp3-input-left-container > .bp3-icon,
  .bp3-input-group.bp3-small > .bp3-icon,
  .bp3-input-group.bp3-small .bp3-input-action > .bp3-spinner {
    margin: 4px; }
  .bp3-input-group.bp3-small .bp3-input {
    font-size: 12px;
    height: 24px;
    line-height: 24px;
    padding-left: 8px;
    padding-right: 8px; }
    .bp3-input-group.bp3-small .bp3-input[type="search"], .bp3-input-group.bp3-small .bp3-input.bp3-round {
      padding: 0 12px; }
    .bp3-input-group.bp3-small .bp3-input:not(:first-child) {
      padding-left: 24px; }
    .bp3-input-group.bp3-small .bp3-input:not(:last-child) {
      padding-right: 24px; }
  .bp3-input-group.bp3-fill {
    flex: 1 1 auto;
    width: 100%; }
  .bp3-input-group.bp3-round .bp3-button,
  .bp3-input-group.bp3-round .bp3-input,
  .bp3-input-group.bp3-round .bp3-tag {
    border-radius: 30px; }
  .bp3-dark .bp3-input-group .bp3-icon {
    color: #e94e1b; }
  .bp3-dark .bp3-input-group.bp3-disabled .bp3-icon {
    color: rgba(233, 78, 27, 0.6); }
  .bp3-input-group.bp3-intent-primary .bp3-input {
    box-shadow: 0 0 0 0 rgba(233, 78, 27, 0), 0 0 0 0 rgba(233, 78, 27, 0), inset 0 0 0 1px #e94e1b, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input-group.bp3-intent-primary .bp3-input:focus {
      box-shadow: 0 0 0 1px #e94e1b, 0 0 0 3px rgba(233, 78, 27, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input-group.bp3-intent-primary .bp3-input[readonly] {
      box-shadow: inset 0 0 0 1px #e94e1b; }
    .bp3-input-group.bp3-intent-primary .bp3-input:disabled, .bp3-input-group.bp3-intent-primary .bp3-input.bp3-disabled {
      box-shadow: none; }
  .bp3-input-group.bp3-intent-primary > .bp3-icon {
    color: #e94e1b; }
    .bp3-dark .bp3-input-group.bp3-intent-primary > .bp3-icon {
      color: #ffffff; }
  .bp3-input-group.bp3-intent-success .bp3-input {
    box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px #0f9960, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input-group.bp3-intent-success .bp3-input:focus {
      box-shadow: 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input-group.bp3-intent-success .bp3-input[readonly] {
      box-shadow: inset 0 0 0 1px #0f9960; }
    .bp3-input-group.bp3-intent-success .bp3-input:disabled, .bp3-input-group.bp3-intent-success .bp3-input.bp3-disabled {
      box-shadow: none; }
  .bp3-input-group.bp3-intent-success > .bp3-icon {
    color: #0d8050; }
    .bp3-dark .bp3-input-group.bp3-intent-success > .bp3-icon {
      color: #3dcc91; }
  .bp3-input-group.bp3-intent-warning .bp3-input {
    box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px #d9822b, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input-group.bp3-intent-warning .bp3-input:focus {
      box-shadow: 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input-group.bp3-intent-warning .bp3-input[readonly] {
      box-shadow: inset 0 0 0 1px #d9822b; }
    .bp3-input-group.bp3-intent-warning .bp3-input:disabled, .bp3-input-group.bp3-intent-warning .bp3-input.bp3-disabled {
      box-shadow: none; }
  .bp3-input-group.bp3-intent-warning > .bp3-icon {
    color: #bf7326; }
    .bp3-dark .bp3-input-group.bp3-intent-warning > .bp3-icon {
      color: #ffb366; }
  .bp3-input-group.bp3-intent-danger .bp3-input {
    box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input-group.bp3-intent-danger .bp3-input:focus {
      box-shadow: 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input-group.bp3-intent-danger .bp3-input[readonly] {
      box-shadow: inset 0 0 0 1px #db3737; }
    .bp3-input-group.bp3-intent-danger .bp3-input:disabled, .bp3-input-group.bp3-intent-danger .bp3-input.bp3-disabled {
      box-shadow: none; }
  .bp3-input-group.bp3-intent-danger > .bp3-icon {
    color: #c23030; }
    .bp3-dark .bp3-input-group.bp3-intent-danger > .bp3-icon {
      color: #ff7373; }

/*
Text inputs

Markup:
<input class="bp3-input {{.modifier}}" {{:modifier}} type="text" placeholder="Text input" dir="auto" />

:disabled - Disabled
:readonly - Readonly
.bp3-round - Rounded ends
.bp3-large - Larger size
.bp3-small - Small size
.bp3-intent-primary - Primary intent
.bp3-intent-success - Success intent
.bp3-intent-warning - Warning intent
.bp3-intent-danger - Danger intent
.bp3-fill - Take up full width of parent element

Styleguide input
*/
.bp3-input {
  appearance: none;
  background: #ffffff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 0 rgba(233, 78, 27, 0), 0 0 0 0 rgba(233, 78, 27, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
  color: #182026;
  font-size: 14px;
  font-weight: 400;
  height: 30px;
  line-height: 30px;
  outline: none;
  padding: 0 10px;
  transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  vertical-align: middle; }
  .bp3-input::placeholder {
    color: rgba(255, 255, 255, 0.6);
    opacity: 1; }
  .bp3-input:focus, .bp3-input.bp3-active {
    box-shadow: 0 0 0 1px #e94e1b, 0 0 0 3px rgba(233, 78, 27, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .bp3-input[type="search"], .bp3-input.bp3-round {
    border-radius: 30px;
    box-sizing: border-box;
    padding-left: 10px; }
  .bp3-input[readonly] {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.15); }
  .bp3-input:disabled, .bp3-input.bp3-disabled {
    background: rgba(255, 255, 255, 0.5);
    box-shadow: none;
    color: rgba(255, 255, 255, 0.6);
    cursor: not-allowed;
    resize: none; }
  .bp3-input.bp3-large {
    font-size: 16px;
    height: 40px;
    line-height: 40px; }
    .bp3-input.bp3-large[type="search"], .bp3-input.bp3-large.bp3-round {
      padding: 0 15px; }
  .bp3-input.bp3-small {
    font-size: 12px;
    height: 24px;
    line-height: 24px;
    padding-left: 8px;
    padding-right: 8px; }
    .bp3-input.bp3-small[type="search"], .bp3-input.bp3-small.bp3-round {
      padding: 0 12px; }
  .bp3-input.bp3-fill {
    flex: 1 1 auto;
    width: 100%; }
  .bp3-dark .bp3-input {
    background: rgba(16, 22, 26, 0.3);
    box-shadow: 0 0 0 0 rgba(233, 78, 27, 0), 0 0 0 0 rgba(233, 78, 27, 0), 0 0 0 0 rgba(233, 78, 27, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
    color: #3c3c3b; }
    .bp3-dark .bp3-input::placeholder {
      color: rgba(233, 78, 27, 0.6); }
    .bp3-dark .bp3-input:focus {
      box-shadow: 0 0 0 1px #e94e1b, 0 0 0 1px #e94e1b, 0 0 0 3px rgba(233, 78, 27, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-input[readonly] {
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-input:disabled, .bp3-dark .bp3-input.bp3-disabled {
      background: rgba(255, 255, 255, 0.5);
      box-shadow: none;
      color: rgba(233, 78, 27, 0.6); }
  .bp3-input.bp3-intent-primary {
    box-shadow: 0 0 0 0 rgba(233, 78, 27, 0), 0 0 0 0 rgba(233, 78, 27, 0), inset 0 0 0 1px #e94e1b, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input.bp3-intent-primary:focus {
      box-shadow: 0 0 0 1px #e94e1b, 0 0 0 3px rgba(233, 78, 27, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input.bp3-intent-primary[readonly] {
      box-shadow: inset 0 0 0 1px #e94e1b; }
    .bp3-input.bp3-intent-primary:disabled, .bp3-input.bp3-intent-primary.bp3-disabled {
      box-shadow: none; }
    .bp3-dark .bp3-input.bp3-intent-primary {
      box-shadow: 0 0 0 0 rgba(233, 78, 27, 0), 0 0 0 0 rgba(233, 78, 27, 0), 0 0 0 0 rgba(233, 78, 27, 0), inset 0 0 0 1px #e94e1b, inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .bp3-dark .bp3-input.bp3-intent-primary:focus {
        box-shadow: 0 0 0 1px #e94e1b, 0 0 0 1px #e94e1b, 0 0 0 3px rgba(233, 78, 27, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .bp3-dark .bp3-input.bp3-intent-primary[readonly] {
        box-shadow: inset 0 0 0 1px #e94e1b; }
      .bp3-dark .bp3-input.bp3-intent-primary:disabled, .bp3-dark .bp3-input.bp3-intent-primary.bp3-disabled {
        box-shadow: none; }
  .bp3-input.bp3-intent-success {
    box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px #0f9960, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input.bp3-intent-success:focus {
      box-shadow: 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input.bp3-intent-success[readonly] {
      box-shadow: inset 0 0 0 1px #0f9960; }
    .bp3-input.bp3-intent-success:disabled, .bp3-input.bp3-intent-success.bp3-disabled {
      box-shadow: none; }
    .bp3-dark .bp3-input.bp3-intent-success {
      box-shadow: 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), 0 0 0 0 rgba(15, 153, 96, 0), inset 0 0 0 1px #0f9960, inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .bp3-dark .bp3-input.bp3-intent-success:focus {
        box-shadow: 0 0 0 1px #0f9960, 0 0 0 1px #0f9960, 0 0 0 3px rgba(15, 153, 96, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .bp3-dark .bp3-input.bp3-intent-success[readonly] {
        box-shadow: inset 0 0 0 1px #0f9960; }
      .bp3-dark .bp3-input.bp3-intent-success:disabled, .bp3-dark .bp3-input.bp3-intent-success.bp3-disabled {
        box-shadow: none; }
  .bp3-input.bp3-intent-warning {
    box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px #d9822b, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input.bp3-intent-warning:focus {
      box-shadow: 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input.bp3-intent-warning[readonly] {
      box-shadow: inset 0 0 0 1px #d9822b; }
    .bp3-input.bp3-intent-warning:disabled, .bp3-input.bp3-intent-warning.bp3-disabled {
      box-shadow: none; }
    .bp3-dark .bp3-input.bp3-intent-warning {
      box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0), inset 0 0 0 1px #d9822b, inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .bp3-dark .bp3-input.bp3-intent-warning:focus {
        box-shadow: 0 0 0 1px #d9822b, 0 0 0 1px #d9822b, 0 0 0 3px rgba(217, 130, 43, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .bp3-dark .bp3-input.bp3-intent-warning[readonly] {
        box-shadow: inset 0 0 0 1px #d9822b; }
      .bp3-dark .bp3-input.bp3-intent-warning:disabled, .bp3-dark .bp3-input.bp3-intent-warning.bp3-disabled {
        box-shadow: none; }
  .bp3-input.bp3-intent-danger {
    box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input.bp3-intent-danger:focus {
      box-shadow: 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-input.bp3-intent-danger[readonly] {
      box-shadow: inset 0 0 0 1px #db3737; }
    .bp3-input.bp3-intent-danger:disabled, .bp3-input.bp3-intent-danger.bp3-disabled {
      box-shadow: none; }
    .bp3-dark .bp3-input.bp3-intent-danger {
      box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .bp3-dark .bp3-input.bp3-intent-danger:focus {
        box-shadow: 0 0 0 1px #db3737, 0 0 0 1px #db3737, 0 0 0 3px rgba(219, 55, 55, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
      .bp3-dark .bp3-input.bp3-intent-danger[readonly] {
        box-shadow: inset 0 0 0 1px #db3737; }
      .bp3-dark .bp3-input.bp3-intent-danger:disabled, .bp3-dark .bp3-input.bp3-intent-danger.bp3-disabled {
        box-shadow: none; }
  .bp3-input::-ms-clear {
    display: none; }

/*
Search inputs

Markup:
<div class="bp3-input-group {{.modifier}}">
  <span class="bp3-icon bp3-icon-search"></span>
  <input class="bp3-input" {{:modifier}} type="search" placeholder="Search input" dir="auto" />
</div>

:disabled - Disabled. Also add <code>.bp3-disabled</code> to <code>.bp3-input-group</code> for icon coloring (not shown below).
.bp3-large - Large
.bp3-small - Small

Styleguide input-search
*/
/*
Textareas

Markup:
<textarea class="bp3-input {{.modifier}}" {{:modifier}} dir="auto"></textarea>

:disabled - Disabled
:readonly - Readonly
.bp3-large - Larger font size
.bp3-small - Small font size
.bp3-intent-primary - Primary intent
.bp3-intent-danger  - Danger intent
.bp3-fill  - Take up full width of parent element

Styleguide textarea
*/
textarea.bp3-input {
  max-width: 100%;
  padding: 10px; }
  textarea.bp3-input, textarea.bp3-input.bp3-large, textarea.bp3-input.bp3-small {
    height: auto;
    line-height: inherit; }
  textarea.bp3-input.bp3-small {
    padding: 8px; }
  .bp3-dark textarea.bp3-input {
    background: rgba(16, 22, 26, 0.3);
    box-shadow: 0 0 0 0 rgba(233, 78, 27, 0), 0 0 0 0 rgba(233, 78, 27, 0), 0 0 0 0 rgba(233, 78, 27, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
    color: #3c3c3b; }
    .bp3-dark textarea.bp3-input::placeholder {
      color: rgba(233, 78, 27, 0.6); }
    .bp3-dark textarea.bp3-input:focus {
      box-shadow: 0 0 0 1px #e94e1b, 0 0 0 1px #e94e1b, 0 0 0 3px rgba(233, 78, 27, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark textarea.bp3-input[readonly] {
      box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark textarea.bp3-input:disabled, .bp3-dark textarea.bp3-input.bp3-disabled {
      background: rgba(255, 255, 255, 0.5);
      box-shadow: none;
      color: rgba(233, 78, 27, 0.6); }

/*
Labels

Markup:
<div>
  <label class="bp3-label {{.modifier}}">
    Label A
    <span class="bp3-text-muted">(optional)</span>
    <input {{:modifier}} class="bp3-input" style="width: 200px;" type="text" placeholder="Text input" dir="auto" />
  </label>
  <label class="bp3-label {{.modifier}}">
    Label B
    <div class="bp3-input-group {{.modifier}}">
      <span class="bp3-icon bp3-icon-calendar"></span>
      <input {{:modifier}} class="bp3-input" style="width: 200px;" type="text" placeholder="Input group" dir="auto" />
    </div>
  </label>
  <label class="bp3-label {{.modifier}}">
    Label C
    <div class="bp3-select {{.modifier}}">
      <select {{:modifier}}>
        <option selected>Choose an item...</option>
        <option value="1">One</option>
      </select>
    </div>
  </label>
</div>

:disabled - Disable the input.
.bp3-disabled - Disabled styles. Input must be disabled separately via attribute.
.bp3-inline - Label and content appear side by side.

Styleguide label
*/
label.bp3-label {
  display: block;
  margin-bottom: 15px;
  margin-top: 0; }
  label.bp3-label .bp3-html-select,
  label.bp3-label .bp3-input,
  label.bp3-label .bp3-select,
  label.bp3-label .bp3-slider,
  label.bp3-label .bp3-popover-wrapper {
    display: block;
    margin-top: 5px;
    text-transform: none; }
  label.bp3-label .bp3-button-group {
    margin-top: 5px; }
  label.bp3-label .bp3-select select,
  label.bp3-label .bp3-html-select select {
    font-weight: 400;
    vertical-align: top;
    width: 100%; }
  label.bp3-label.bp3-disabled,
  label.bp3-label.bp3-disabled .bp3-text-muted {
    color: rgba(255, 255, 255, 0.6); }
  label.bp3-label.bp3-inline {
    line-height: 30px; }
    label.bp3-label.bp3-inline .bp3-html-select,
    label.bp3-label.bp3-inline .bp3-input,
    label.bp3-label.bp3-inline .bp3-input-group,
    label.bp3-label.bp3-inline .bp3-select,
    label.bp3-label.bp3-inline .bp3-popover-wrapper {
      display: inline-block;
      margin: 0 0 0 5px;
      vertical-align: top; }
    label.bp3-label.bp3-inline .bp3-button-group {
      margin: 0 0 0 5px; }
    label.bp3-label.bp3-inline .bp3-input-group .bp3-input {
      margin-left: 0; }
    label.bp3-label.bp3-inline.bp3-large {
      line-height: 40px; }
  label.bp3-label:not(.bp3-inline) .bp3-popover-target {
    display: block; }
  .bp3-dark label.bp3-label {
    color: #3c3c3b; }
    .bp3-dark label.bp3-label.bp3-disabled,
    .bp3-dark label.bp3-label.bp3-disabled .bp3-text-muted {
      color: rgba(233, 78, 27, 0.6); }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.bp3-numeric-input .bp3-button-group.bp3-vertical > .bp3-button {
  flex: 1 1 14px;
  min-height: 0;
  padding: 0;
  width: 30px; }
  .bp3-numeric-input .bp3-button-group.bp3-vertical > .bp3-button:first-child {
    border-radius: 0 3px 0 0; }
  .bp3-numeric-input .bp3-button-group.bp3-vertical > .bp3-button:last-child {
    border-radius: 0 0 3px 0; }

.bp3-numeric-input .bp3-button-group.bp3-vertical:first-child > .bp3-button:first-child {
  border-radius: 3px 0 0 0; }

.bp3-numeric-input .bp3-button-group.bp3-vertical:first-child > .bp3-button:last-child {
  border-radius: 0 0 0 3px; }

.bp3-numeric-input.bp3-large .bp3-button-group.bp3-vertical > .bp3-button {
  width: 40px; }

form {
  display: block; }

/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.bp3-html-select select,
.bp3-select select {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  justify-content: center;
  padding: 5px 10px;
  text-align: left;
  vertical-align: middle;
  background-color: #3c3c3b;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  color: #182026;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 3px;
  height: 30px;
  padding: 0 25px 0 10px;
  width: 100%; }
  .bp3-html-select select > *, .bp3-select select > * {
    flex-grow: 0;
    flex-shrink: 0; }
  .bp3-html-select select > .bp3-fill, .bp3-select select > .bp3-fill {
    flex-grow: 1;
    flex-shrink: 1; }
  .bp3-html-select select::before,
  .bp3-select select::before, .bp3-html-select select > *, .bp3-select select > * {
    margin-right: 7px; }
  .bp3-html-select select:empty::before,
  .bp3-select select:empty::before,
  .bp3-html-select select > :last-child,
  .bp3-select select > :last-child {
    margin-right: 0; }
  .bp3-html-select select:hover,
  .bp3-select select:hover {
    background-clip: padding-box;
    background-color: #e94e1b;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1); }
  .bp3-html-select select:active,
  .bp3-select select:active, .bp3-html-select select.bp3-active,
  .bp3-select select.bp3-active {
    background-color: #e94e1b;
    background-image: none;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
  .bp3-html-select select:disabled,
  .bp3-select select:disabled, .bp3-html-select select.bp3-disabled,
  .bp3-select select.bp3-disabled {
    background-color: rgba(255, 255, 255, 0.5);
    background-image: none;
    box-shadow: none;
    color: rgba(255, 255, 255, 0.6);
    cursor: not-allowed;
    outline: none; }
    .bp3-html-select select:disabled.bp3-active,
    .bp3-select select:disabled.bp3-active, .bp3-html-select select:disabled.bp3-active:hover,
    .bp3-select select:disabled.bp3-active:hover, .bp3-html-select select.bp3-disabled.bp3-active,
    .bp3-select select.bp3-disabled.bp3-active, .bp3-html-select select.bp3-disabled.bp3-active:hover,
    .bp3-select select.bp3-disabled.bp3-active:hover {
      background: rgba(255, 255, 255, 0.7); }

.bp3-html-select.bp3-minimal select,
.bp3-select.bp3-minimal select {
  background: none;
  box-shadow: none; }
  .bp3-html-select.bp3-minimal select:hover,
  .bp3-select.bp3-minimal select:hover {
    background: rgba(233, 78, 27, 0.3);
    box-shadow: none;
    color: #182026;
    text-decoration: none; }
  .bp3-html-select.bp3-minimal select:active,
  .bp3-select.bp3-minimal select:active, .bp3-html-select.bp3-minimal select.bp3-active,
  .bp3-select.bp3-minimal select.bp3-active {
    background: rgba(233, 78, 27, 0.3);
    box-shadow: none;
    color: #182026; }
  .bp3-html-select.bp3-minimal select:disabled,
  .bp3-select.bp3-minimal select:disabled, .bp3-html-select.bp3-minimal select:disabled:hover,
  .bp3-select.bp3-minimal select:disabled:hover, .bp3-html-select.bp3-minimal select.bp3-disabled,
  .bp3-select.bp3-minimal select.bp3-disabled, .bp3-html-select.bp3-minimal select.bp3-disabled:hover,
  .bp3-select.bp3-minimal select.bp3-disabled:hover {
    background: none;
    color: rgba(255, 255, 255, 0.6);
    cursor: not-allowed; }
    .bp3-html-select.bp3-minimal select:disabled.bp3-active,
    .bp3-select.bp3-minimal select:disabled.bp3-active, .bp3-html-select.bp3-minimal select:disabled:hover.bp3-active,
    .bp3-select.bp3-minimal select:disabled:hover.bp3-active, .bp3-html-select.bp3-minimal select.bp3-disabled.bp3-active,
    .bp3-select.bp3-minimal select.bp3-disabled.bp3-active, .bp3-html-select.bp3-minimal select.bp3-disabled:hover.bp3-active,
    .bp3-select.bp3-minimal select.bp3-disabled:hover.bp3-active {
      background: rgba(233, 78, 27, 0.3); }
  .bp3-dark .bp3-html-select.bp3-minimal select, .bp3-html-select.bp3-minimal .bp3-dark select,
  .bp3-dark .bp3-select.bp3-minimal select, .bp3-select.bp3-minimal .bp3-dark select {
    background: none;
    box-shadow: none;
    color: inherit; }
    .bp3-dark .bp3-html-select.bp3-minimal select:hover, .bp3-html-select.bp3-minimal .bp3-dark select:hover,
    .bp3-dark .bp3-select.bp3-minimal select:hover, .bp3-select.bp3-minimal .bp3-dark select:hover, .bp3-dark .bp3-html-select.bp3-minimal select:active, .bp3-html-select.bp3-minimal .bp3-dark select:active,
    .bp3-dark .bp3-select.bp3-minimal select:active, .bp3-select.bp3-minimal .bp3-dark select:active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-active,
    .bp3-dark .bp3-select.bp3-minimal select.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-active {
      background: none;
      box-shadow: none; }
    .bp3-dark .bp3-html-select.bp3-minimal select:hover, .bp3-html-select.bp3-minimal .bp3-dark select:hover,
    .bp3-dark .bp3-select.bp3-minimal select:hover, .bp3-select.bp3-minimal .bp3-dark select:hover {
      background: rgba(233, 78, 27, 0.15); }
    .bp3-dark .bp3-html-select.bp3-minimal select:active, .bp3-html-select.bp3-minimal .bp3-dark select:active,
    .bp3-dark .bp3-select.bp3-minimal select:active, .bp3-select.bp3-minimal .bp3-dark select:active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-active,
    .bp3-dark .bp3-select.bp3-minimal select.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-active {
      background: rgba(233, 78, 27, 0.3);
      color: #3c3c3b; }
    .bp3-dark .bp3-html-select.bp3-minimal select:disabled, .bp3-html-select.bp3-minimal .bp3-dark select:disabled,
    .bp3-dark .bp3-select.bp3-minimal select:disabled, .bp3-select.bp3-minimal .bp3-dark select:disabled, .bp3-dark .bp3-html-select.bp3-minimal select:disabled:hover, .bp3-html-select.bp3-minimal .bp3-dark select:disabled:hover,
    .bp3-dark .bp3-select.bp3-minimal select:disabled:hover, .bp3-select.bp3-minimal .bp3-dark select:disabled:hover, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-disabled,
    .bp3-dark .bp3-select.bp3-minimal select.bp3-disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-disabled, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-disabled:hover, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-disabled:hover,
    .bp3-dark .bp3-select.bp3-minimal select.bp3-disabled:hover, .bp3-select.bp3-minimal .bp3-dark select.bp3-disabled:hover {
      background: none;
      color: rgba(233, 78, 27, 0.6);
      cursor: not-allowed; }
      .bp3-dark .bp3-html-select.bp3-minimal select:disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select:disabled.bp3-active,
      .bp3-dark .bp3-select.bp3-minimal select:disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select:disabled.bp3-active, .bp3-dark .bp3-html-select.bp3-minimal select:disabled:hover.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select:disabled:hover.bp3-active,
      .bp3-dark .bp3-select.bp3-minimal select:disabled:hover.bp3-active, .bp3-select.bp3-minimal .bp3-dark select:disabled:hover.bp3-active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-disabled.bp3-active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-disabled.bp3-active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-disabled:hover.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-disabled:hover.bp3-active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-disabled:hover.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-disabled:hover.bp3-active {
        background: rgba(233, 78, 27, 0.3); }
  .bp3-html-select.bp3-minimal select.bp3-intent-primary,
  .bp3-select.bp3-minimal select.bp3-intent-primary {
    color: #e94e1b; }
    .bp3-html-select.bp3-minimal select.bp3-intent-primary:hover,
    .bp3-select.bp3-minimal select.bp3-intent-primary:hover, .bp3-html-select.bp3-minimal select.bp3-intent-primary:active,
    .bp3-select.bp3-minimal select.bp3-intent-primary:active, .bp3-html-select.bp3-minimal select.bp3-intent-primary.bp3-active,
    .bp3-select.bp3-minimal select.bp3-intent-primary.bp3-active {
      background: none;
      box-shadow: none;
      color: #e94e1b; }
    .bp3-html-select.bp3-minimal select.bp3-intent-primary:hover,
    .bp3-select.bp3-minimal select.bp3-intent-primary:hover {
      background: rgba(233, 78, 27, 0.15);
      color: #e94e1b; }
    .bp3-html-select.bp3-minimal select.bp3-intent-primary:active,
    .bp3-select.bp3-minimal select.bp3-intent-primary:active, .bp3-html-select.bp3-minimal select.bp3-intent-primary.bp3-active,
    .bp3-select.bp3-minimal select.bp3-intent-primary.bp3-active {
      background: rgba(233, 78, 27, 0.3);
      color: #e94e1b; }
    .bp3-html-select.bp3-minimal select.bp3-intent-primary:disabled,
    .bp3-select.bp3-minimal select.bp3-intent-primary:disabled, .bp3-html-select.bp3-minimal select.bp3-intent-primary.bp3-disabled,
    .bp3-select.bp3-minimal select.bp3-intent-primary.bp3-disabled {
      background: none;
      color: rgba(233, 78, 27, 0.5); }
      .bp3-html-select.bp3-minimal select.bp3-intent-primary:disabled.bp3-active,
      .bp3-select.bp3-minimal select.bp3-intent-primary:disabled.bp3-active, .bp3-html-select.bp3-minimal select.bp3-intent-primary.bp3-disabled.bp3-active,
      .bp3-select.bp3-minimal select.bp3-intent-primary.bp3-disabled.bp3-active {
        background: rgba(233, 78, 27, 0.3); }
    .bp3-html-select.bp3-minimal select.bp3-intent-primary .bp3-button-spinner .bp3-spinner-head, .bp3-select.bp3-minimal select.bp3-intent-primary .bp3-button-spinner .bp3-spinner-head {
      stroke: #e94e1b; }
    .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary,
    .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary {
      color: #ffffff; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary:hover, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary:hover,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary:hover, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary:hover {
        background: rgba(233, 78, 27, 0.2);
        color: #ffffff; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary:active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary:active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary:active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary:active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary.bp3-active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary.bp3-active {
        background: rgba(233, 78, 27, 0.3);
        color: #ffffff; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary:disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary:disabled,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary:disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary:disabled, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary.bp3-disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary.bp3-disabled,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary.bp3-disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary.bp3-disabled {
        background: none;
        color: rgba(255, 255, 255, 0.5); }
        .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary:disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary:disabled.bp3-active,
        .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary:disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary:disabled.bp3-active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-primary.bp3-disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-primary.bp3-disabled.bp3-active,
        .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-primary.bp3-disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-primary.bp3-disabled.bp3-active {
          background: rgba(233, 78, 27, 0.3); }
  .bp3-html-select.bp3-minimal select.bp3-intent-success,
  .bp3-select.bp3-minimal select.bp3-intent-success {
    color: #0d8050; }
    .bp3-html-select.bp3-minimal select.bp3-intent-success:hover,
    .bp3-select.bp3-minimal select.bp3-intent-success:hover, .bp3-html-select.bp3-minimal select.bp3-intent-success:active,
    .bp3-select.bp3-minimal select.bp3-intent-success:active, .bp3-html-select.bp3-minimal select.bp3-intent-success.bp3-active,
    .bp3-select.bp3-minimal select.bp3-intent-success.bp3-active {
      background: none;
      box-shadow: none;
      color: #0d8050; }
    .bp3-html-select.bp3-minimal select.bp3-intent-success:hover,
    .bp3-select.bp3-minimal select.bp3-intent-success:hover {
      background: rgba(15, 153, 96, 0.15);
      color: #0d8050; }
    .bp3-html-select.bp3-minimal select.bp3-intent-success:active,
    .bp3-select.bp3-minimal select.bp3-intent-success:active, .bp3-html-select.bp3-minimal select.bp3-intent-success.bp3-active,
    .bp3-select.bp3-minimal select.bp3-intent-success.bp3-active {
      background: rgba(15, 153, 96, 0.3);
      color: #0d8050; }
    .bp3-html-select.bp3-minimal select.bp3-intent-success:disabled,
    .bp3-select.bp3-minimal select.bp3-intent-success:disabled, .bp3-html-select.bp3-minimal select.bp3-intent-success.bp3-disabled,
    .bp3-select.bp3-minimal select.bp3-intent-success.bp3-disabled {
      background: none;
      color: rgba(13, 128, 80, 0.5); }
      .bp3-html-select.bp3-minimal select.bp3-intent-success:disabled.bp3-active,
      .bp3-select.bp3-minimal select.bp3-intent-success:disabled.bp3-active, .bp3-html-select.bp3-minimal select.bp3-intent-success.bp3-disabled.bp3-active,
      .bp3-select.bp3-minimal select.bp3-intent-success.bp3-disabled.bp3-active {
        background: rgba(15, 153, 96, 0.3); }
    .bp3-html-select.bp3-minimal select.bp3-intent-success .bp3-button-spinner .bp3-spinner-head, .bp3-select.bp3-minimal select.bp3-intent-success .bp3-button-spinner .bp3-spinner-head {
      stroke: #0d8050; }
    .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success,
    .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success {
      color: #3dcc91; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success:hover, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success:hover,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success:hover, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success:hover {
        background: rgba(15, 153, 96, 0.2);
        color: #3dcc91; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success:active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success:active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success:active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success:active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success.bp3-active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success.bp3-active {
        background: rgba(15, 153, 96, 0.3);
        color: #3dcc91; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success:disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success:disabled,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success:disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success:disabled, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success.bp3-disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success.bp3-disabled,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success.bp3-disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success.bp3-disabled {
        background: none;
        color: rgba(61, 204, 145, 0.5); }
        .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success:disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success:disabled.bp3-active,
        .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success:disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success:disabled.bp3-active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-success.bp3-disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-success.bp3-disabled.bp3-active,
        .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-success.bp3-disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-success.bp3-disabled.bp3-active {
          background: rgba(15, 153, 96, 0.3); }
  .bp3-html-select.bp3-minimal select.bp3-intent-warning,
  .bp3-select.bp3-minimal select.bp3-intent-warning {
    color: #bf7326; }
    .bp3-html-select.bp3-minimal select.bp3-intent-warning:hover,
    .bp3-select.bp3-minimal select.bp3-intent-warning:hover, .bp3-html-select.bp3-minimal select.bp3-intent-warning:active,
    .bp3-select.bp3-minimal select.bp3-intent-warning:active, .bp3-html-select.bp3-minimal select.bp3-intent-warning.bp3-active,
    .bp3-select.bp3-minimal select.bp3-intent-warning.bp3-active {
      background: none;
      box-shadow: none;
      color: #bf7326; }
    .bp3-html-select.bp3-minimal select.bp3-intent-warning:hover,
    .bp3-select.bp3-minimal select.bp3-intent-warning:hover {
      background: rgba(217, 130, 43, 0.15);
      color: #bf7326; }
    .bp3-html-select.bp3-minimal select.bp3-intent-warning:active,
    .bp3-select.bp3-minimal select.bp3-intent-warning:active, .bp3-html-select.bp3-minimal select.bp3-intent-warning.bp3-active,
    .bp3-select.bp3-minimal select.bp3-intent-warning.bp3-active {
      background: rgba(217, 130, 43, 0.3);
      color: #bf7326; }
    .bp3-html-select.bp3-minimal select.bp3-intent-warning:disabled,
    .bp3-select.bp3-minimal select.bp3-intent-warning:disabled, .bp3-html-select.bp3-minimal select.bp3-intent-warning.bp3-disabled,
    .bp3-select.bp3-minimal select.bp3-intent-warning.bp3-disabled {
      background: none;
      color: rgba(191, 115, 38, 0.5); }
      .bp3-html-select.bp3-minimal select.bp3-intent-warning:disabled.bp3-active,
      .bp3-select.bp3-minimal select.bp3-intent-warning:disabled.bp3-active, .bp3-html-select.bp3-minimal select.bp3-intent-warning.bp3-disabled.bp3-active,
      .bp3-select.bp3-minimal select.bp3-intent-warning.bp3-disabled.bp3-active {
        background: rgba(217, 130, 43, 0.3); }
    .bp3-html-select.bp3-minimal select.bp3-intent-warning .bp3-button-spinner .bp3-spinner-head, .bp3-select.bp3-minimal select.bp3-intent-warning .bp3-button-spinner .bp3-spinner-head {
      stroke: #bf7326; }
    .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning,
    .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning {
      color: #ffb366; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning:hover, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning:hover,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning:hover, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning:hover {
        background: rgba(217, 130, 43, 0.2);
        color: #ffb366; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning:active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning:active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning:active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning:active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning.bp3-active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning.bp3-active {
        background: rgba(217, 130, 43, 0.3);
        color: #ffb366; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning:disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning:disabled,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning:disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning:disabled, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning.bp3-disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning.bp3-disabled,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning.bp3-disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning.bp3-disabled {
        background: none;
        color: rgba(255, 179, 102, 0.5); }
        .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning:disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning:disabled.bp3-active,
        .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning:disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning:disabled.bp3-active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-warning.bp3-disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-warning.bp3-disabled.bp3-active,
        .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-warning.bp3-disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-warning.bp3-disabled.bp3-active {
          background: rgba(217, 130, 43, 0.3); }
  .bp3-html-select.bp3-minimal select.bp3-intent-danger,
  .bp3-select.bp3-minimal select.bp3-intent-danger {
    color: #c23030; }
    .bp3-html-select.bp3-minimal select.bp3-intent-danger:hover,
    .bp3-select.bp3-minimal select.bp3-intent-danger:hover, .bp3-html-select.bp3-minimal select.bp3-intent-danger:active,
    .bp3-select.bp3-minimal select.bp3-intent-danger:active, .bp3-html-select.bp3-minimal select.bp3-intent-danger.bp3-active,
    .bp3-select.bp3-minimal select.bp3-intent-danger.bp3-active {
      background: none;
      box-shadow: none;
      color: #c23030; }
    .bp3-html-select.bp3-minimal select.bp3-intent-danger:hover,
    .bp3-select.bp3-minimal select.bp3-intent-danger:hover {
      background: rgba(219, 55, 55, 0.15);
      color: #c23030; }
    .bp3-html-select.bp3-minimal select.bp3-intent-danger:active,
    .bp3-select.bp3-minimal select.bp3-intent-danger:active, .bp3-html-select.bp3-minimal select.bp3-intent-danger.bp3-active,
    .bp3-select.bp3-minimal select.bp3-intent-danger.bp3-active {
      background: rgba(219, 55, 55, 0.3);
      color: #c23030; }
    .bp3-html-select.bp3-minimal select.bp3-intent-danger:disabled,
    .bp3-select.bp3-minimal select.bp3-intent-danger:disabled, .bp3-html-select.bp3-minimal select.bp3-intent-danger.bp3-disabled,
    .bp3-select.bp3-minimal select.bp3-intent-danger.bp3-disabled {
      background: none;
      color: rgba(194, 48, 48, 0.5); }
      .bp3-html-select.bp3-minimal select.bp3-intent-danger:disabled.bp3-active,
      .bp3-select.bp3-minimal select.bp3-intent-danger:disabled.bp3-active, .bp3-html-select.bp3-minimal select.bp3-intent-danger.bp3-disabled.bp3-active,
      .bp3-select.bp3-minimal select.bp3-intent-danger.bp3-disabled.bp3-active {
        background: rgba(219, 55, 55, 0.3); }
    .bp3-html-select.bp3-minimal select.bp3-intent-danger .bp3-button-spinner .bp3-spinner-head, .bp3-select.bp3-minimal select.bp3-intent-danger .bp3-button-spinner .bp3-spinner-head {
      stroke: #c23030; }
    .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger,
    .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger {
      color: #ff7373; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger:hover, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger:hover,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger:hover, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger:hover {
        background: rgba(219, 55, 55, 0.2);
        color: #ff7373; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger:active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger:active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger:active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger:active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger.bp3-active,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger.bp3-active {
        background: rgba(219, 55, 55, 0.3);
        color: #ff7373; }
      .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger:disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger:disabled,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger:disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger:disabled, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger.bp3-disabled, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger.bp3-disabled,
      .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger.bp3-disabled, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger.bp3-disabled {
        background: none;
        color: rgba(255, 115, 115, 0.5); }
        .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger:disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger:disabled.bp3-active,
        .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger:disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger:disabled.bp3-active, .bp3-dark .bp3-html-select.bp3-minimal select.bp3-intent-danger.bp3-disabled.bp3-active, .bp3-html-select.bp3-minimal .bp3-dark select.bp3-intent-danger.bp3-disabled.bp3-active,
        .bp3-dark .bp3-select.bp3-minimal select.bp3-intent-danger.bp3-disabled.bp3-active, .bp3-select.bp3-minimal .bp3-dark select.bp3-intent-danger.bp3-disabled.bp3-active {
          background: rgba(219, 55, 55, 0.3); }

.bp3-html-select.bp3-large select,
.bp3-select.bp3-large select {
  font-size: 16px;
  height: 40px;
  padding-right: 35px; }

.bp3-dark .bp3-html-select select, .bp3-dark .bp3-select select {
  background-color: #ffffff;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  color: #3c3c3b; }
  .bp3-dark .bp3-html-select select:hover, .bp3-dark .bp3-select select:hover, .bp3-dark .bp3-html-select select:active, .bp3-dark .bp3-select select:active, .bp3-dark .bp3-html-select select.bp3-active, .bp3-dark .bp3-select select.bp3-active {
    color: #3c3c3b; }
  .bp3-dark .bp3-html-select select:hover, .bp3-dark .bp3-select select:hover {
    background-color: #dadada;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-html-select select:active, .bp3-dark .bp3-select select:active, .bp3-dark .bp3-html-select select.bp3-active, .bp3-dark .bp3-select select.bp3-active {
    background-color: #ffffff;
    background-image: none;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
  .bp3-dark .bp3-html-select select:disabled, .bp3-dark .bp3-select select:disabled, .bp3-dark .bp3-html-select select.bp3-disabled, .bp3-dark .bp3-select select.bp3-disabled {
    background-color: rgba(255, 255, 255, 0.5);
    background-image: none;
    box-shadow: none;
    color: rgba(233, 78, 27, 0.6); }
    .bp3-dark .bp3-html-select select:disabled.bp3-active, .bp3-dark .bp3-select select:disabled.bp3-active, .bp3-dark .bp3-html-select select.bp3-disabled.bp3-active, .bp3-dark .bp3-select select.bp3-disabled.bp3-active {
      background: rgba(255, 255, 255, 0.7); }
  .bp3-dark .bp3-html-select select .bp3-button-spinner .bp3-spinner-head, .bp3-dark .bp3-select select .bp3-button-spinner .bp3-spinner-head {
    background: rgba(16, 22, 26, 0.5);
    stroke: #e94e1b; }

.bp3-html-select select:disabled,
.bp3-select select:disabled {
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: none;
  color: rgba(255, 255, 255, 0.6);
  cursor: not-allowed; }

.bp3-html-select .bp3-icon,
.bp3-select .bp3-icon, .bp3-select::after {
  color: #ffffff;
  pointer-events: none;
  position: absolute;
  right: 7px;
  top: 7px; }
  .bp3-html-select .bp3-disabled.bp3-icon,
  .bp3-select .bp3-disabled.bp3-icon, .bp3-disabled.bp3-select::after {
    color: rgba(255, 255, 255, 0.6); }

/*
HTML select

Markup:
<div class="bp3-select {{.modifier}}">
  <select {{:modifier}}>
    <option selected>Choose an item...</option>
    <option value="1">One</option>
    <option value="2">Two</option>
    <option value="3">Three</option>
    <option value="4">Four</option>
  </select>
</div>

:disabled - Disabled. Also add <code>.bp3-disabled</code> to <code>.bp3-select</code> for icon coloring (not shown below).
.bp3-fill - Expand to fill parent container
.bp3-large - Large size
.bp3-minimal - Minimal appearance

Styleguide select
*/
.bp3-html-select,
.bp3-select {
  display: inline-block;
  letter-spacing: normal;
  position: relative;
  vertical-align: middle; }
  .bp3-html-select select::-ms-expand,
  .bp3-select select::-ms-expand {
    display: none; }
  .bp3-html-select .bp3-icon,
  .bp3-select .bp3-icon {
    color: #ffffff; }
    .bp3-html-select .bp3-icon:hover,
    .bp3-select .bp3-icon:hover {
      color: #182026; }
    .bp3-dark .bp3-html-select .bp3-icon, .bp3-dark
    .bp3-select .bp3-icon {
      color: #e94e1b; }
      .bp3-dark .bp3-html-select .bp3-icon:hover, .bp3-dark
      .bp3-select .bp3-icon:hover {
        color: #3c3c3b; }
  .bp3-html-select.bp3-large::after,
  .bp3-html-select.bp3-large .bp3-icon,
  .bp3-select.bp3-large::after,
  .bp3-select.bp3-large .bp3-icon {
    right: 12px;
    top: 12px; }
  .bp3-html-select.bp3-fill,
  .bp3-html-select.bp3-fill select,
  .bp3-select.bp3-fill,
  .bp3-select.bp3-fill select {
    width: 100%; }
  .bp3-dark .bp3-html-select option, .bp3-dark
  .bp3-select option {
    background-color: #dadada;
    color: #3c3c3b; }
  .bp3-dark .bp3-html-select option:disabled, .bp3-dark
  .bp3-select option:disabled {
    color: rgba(233, 78, 27, 0.6); }
  .bp3-dark .bp3-html-select::after, .bp3-dark
  .bp3-select::after {
    color: #e94e1b; }

.bp3-select::after {
  font-family: "Icons16", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  content: ""; }

/*
Tables

Markup:
<table class="bp3-html-table {{.modifier}}">
  <thead>
    <tr>
      <th>Project</th>
      <th>Description</th>
      <th>Technologies</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Blueprint</td>
      <td>CSS framework and UI toolkit</td>
      <td>Sass, TypeScript, React</td>
    </tr>
    <tr>
      <td>TSLint</td>
      <td>Static analysis linter for TypeScript</td>
      <td>TypeScript</td>
    </tr>
    <tr>
      <td>Plottable</td>
      <td>Composable charting library built on top of D3</td>
      <td>SVG, TypeScript, D3</td>
    </tr>
  </tbody>
</table>

.bp3-html-table-bordered - Bordered appearance
.bp3-html-table-condensed - Condensed smaller appearance
.bp3-html-table-striped - Striped appearance
.bp3-interactive - Enables hover styles on rows
.bp3-small - Small, condensed appearance for this element _and all child elements_

Styleguide html-table
*/
.bp3-running-text table, table.bp3-html-table {
  border-spacing: 0;
  font-size: 14px; }
  .bp3-running-text table th, table.bp3-html-table th,
  .bp3-running-text table td,
  table.bp3-html-table td {
    padding: 11px;
    text-align: left;
    vertical-align: top; }
  .bp3-running-text table th, table.bp3-html-table th {
    color: #182026;
    font-weight: 600; }
  
  .bp3-running-text table td,
  table.bp3-html-table td {
    color: #182026; }
  .bp3-running-text table tbody tr:first-child th, table.bp3-html-table tbody tr:first-child th,
  .bp3-running-text table tbody tr:first-child td,
  table.bp3-html-table tbody tr:first-child td {
    box-shadow: inset 0 1px 0 0 rgba(16, 22, 26, 0.15); }
  .bp3-dark .bp3-running-text table th, .bp3-running-text .bp3-dark table th, .bp3-dark table.bp3-html-table th {
    color: #3c3c3b; }
  .bp3-dark .bp3-running-text table td, .bp3-running-text .bp3-dark table td, .bp3-dark table.bp3-html-table td {
    color: #3c3c3b; }
  .bp3-dark .bp3-running-text table tbody tr:first-child th, .bp3-running-text .bp3-dark table tbody tr:first-child th, .bp3-dark table.bp3-html-table tbody tr:first-child th,
  .bp3-dark .bp3-running-text table tbody tr:first-child td,
  .bp3-running-text .bp3-dark table tbody tr:first-child td,
  .bp3-dark table.bp3-html-table tbody tr:first-child td {
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.15); }

table.bp3-html-table.bp3-html-table-condensed th,
table.bp3-html-table.bp3-html-table-condensed td, table.bp3-html-table.bp3-small th,
table.bp3-html-table.bp3-small td {
  padding-bottom: 6px;
  padding-top: 6px; }

table.bp3-html-table.bp3-html-table-striped tbody tr:nth-child(odd) td {
  background: rgba(255, 255, 255, 0.15); }

table.bp3-html-table.bp3-html-table-bordered th:not(:first-child) {
  box-shadow: inset 1px 0 0 0 rgba(16, 22, 26, 0.15); }

table.bp3-html-table.bp3-html-table-bordered tbody tr td {
  box-shadow: inset 0 1px 0 0 rgba(16, 22, 26, 0.15); }
  table.bp3-html-table.bp3-html-table-bordered tbody tr td:not(:first-child) {
    box-shadow: inset 1px 1px 0 0 rgba(16, 22, 26, 0.15); }

table.bp3-html-table.bp3-html-table-bordered.bp3-html-table-striped tbody tr:not(:first-child) td {
  box-shadow: none; }
  table.bp3-html-table.bp3-html-table-bordered.bp3-html-table-striped tbody tr:not(:first-child) td:not(:first-child) {
    box-shadow: inset 1px 0 0 0 rgba(16, 22, 26, 0.15); }

table.bp3-html-table.bp3-interactive tbody tr:hover td {
  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer; }

table.bp3-html-table.bp3-interactive tbody tr:active td {
  background-color: rgba(255, 255, 255, 0.4); }

.bp3-dark table.bp3-html-table.bp3-html-table-striped tbody tr:nth-child(odd) td {
  background: rgba(255, 255, 255, 0.15); }

.bp3-dark table.bp3-html-table.bp3-html-table-bordered th:not(:first-child) {
  box-shadow: inset 1px 0 0 0 rgba(255, 255, 255, 0.15); }

.bp3-dark table.bp3-html-table.bp3-html-table-bordered tbody tr td {
  box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.15); }
  .bp3-dark table.bp3-html-table.bp3-html-table-bordered tbody tr td:not(:first-child) {
    box-shadow: inset 1px 1px 0 0 rgba(255, 255, 255, 0.15); }

.bp3-dark table.bp3-html-table.bp3-html-table-bordered.bp3-html-table-striped tbody tr:not(:first-child) td {
  box-shadow: inset 1px 0 0 0 rgba(255, 255, 255, 0.15); }
  .bp3-dark table.bp3-html-table.bp3-html-table-bordered.bp3-html-table-striped tbody tr:not(:first-child) td:first-child {
    box-shadow: none; }

.bp3-dark table.bp3-html-table.bp3-interactive tbody tr:hover td {
  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer; }

.bp3-dark table.bp3-html-table.bp3-interactive tbody tr:active td {
  background-color: rgba(255, 255, 255, 0.4); }

.bp3-key-combo {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .bp3-key-combo > * {
    flex-grow: 0;
    flex-shrink: 0; }
  .bp3-key-combo > .bp3-fill {
    flex-grow: 1;
    flex-shrink: 1; }
  .bp3-key-combo::before,
  .bp3-key-combo > * {
    margin-right: 5px; }
  .bp3-key-combo:empty::before,
  .bp3-key-combo > :last-child {
    margin-right: 0; }

.bp3-hotkey-dialog {
  padding-bottom: 0;
  top: 40px; }
  .bp3-hotkey-dialog .bp3-dialog-body {
    margin: 0;
    padding: 0; }
  .bp3-hotkey-dialog .bp3-hotkey-label {
    flex-grow: 1; }

.bp3-hotkey-column {
  margin: auto;
  max-height: 80vh;
  overflow-y: auto;
  padding: 30px; }
  .bp3-hotkey-column .bp3-heading {
    margin-bottom: 20px; }
    .bp3-hotkey-column .bp3-heading:not(:first-child) {
      margin-top: 40px; }

.bp3-hotkey {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-left: 0;
  margin-right: 0; }
  .bp3-hotkey:not(:last-child) {
    margin-bottom: 10px; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.bp3-icon {
  display: inline-block;
  flex: 0 0 auto;
  vertical-align: text-bottom; }
  .bp3-icon:not(:empty)::before {
    /* stylelint-disable */
    content: "" !important;
    content: unset !important;
    /* stylelint-enable */ }
  .bp3-icon > svg {
    display: block; }
    .bp3-icon > svg:not([fill]) {
      fill: currentColor; }

.bp3-icon.bp3-intent-primary, .bp3-icon-standard.bp3-intent-primary, .bp3-icon-large.bp3-intent-primary {
  color: #e94e1b; }
  .bp3-dark .bp3-icon.bp3-intent-primary, .bp3-dark .bp3-icon-standard.bp3-intent-primary, .bp3-dark .bp3-icon-large.bp3-intent-primary {
    color: #ffffff; }

.bp3-icon.bp3-intent-success, .bp3-icon-standard.bp3-intent-success, .bp3-icon-large.bp3-intent-success {
  color: #0d8050; }
  .bp3-dark .bp3-icon.bp3-intent-success, .bp3-dark .bp3-icon-standard.bp3-intent-success, .bp3-dark .bp3-icon-large.bp3-intent-success {
    color: #3dcc91; }

.bp3-icon.bp3-intent-warning, .bp3-icon-standard.bp3-intent-warning, .bp3-icon-large.bp3-intent-warning {
  color: #bf7326; }
  .bp3-dark .bp3-icon.bp3-intent-warning, .bp3-dark .bp3-icon-standard.bp3-intent-warning, .bp3-dark .bp3-icon-large.bp3-intent-warning {
    color: #ffb366; }

.bp3-icon.bp3-intent-danger, .bp3-icon-standard.bp3-intent-danger, .bp3-icon-large.bp3-intent-danger {
  color: #c23030; }
  .bp3-dark .bp3-icon.bp3-intent-danger, .bp3-dark .bp3-icon-standard.bp3-intent-danger, .bp3-dark .bp3-icon-large.bp3-intent-danger {
    color: #ff7373; }

span.bp3-icon-standard {
  font-family: "Icons16", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block; }

span.bp3-icon-large {
  font-family: "Icons20", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block; }

span.bp3-icon:empty {
  font-family: "Icons20";
  font-size: inherit;
  font-style: normal;
  font-weight: 400;
  line-height: 1; }
  span.bp3-icon:empty::before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased; }

.bp3-icon-add::before {
  content: ""; }

.bp3-icon-add-column-left::before {
  content: ""; }

.bp3-icon-add-column-right::before {
  content: ""; }

.bp3-icon-add-row-bottom::before {
  content: ""; }

.bp3-icon-add-row-top::before {
  content: ""; }

.bp3-icon-add-to-artifact::before {
  content: ""; }

.bp3-icon-add-to-folder::before {
  content: ""; }

.bp3-icon-airplane::before {
  content: ""; }

.bp3-icon-align-center::before {
  content: ""; }

.bp3-icon-align-justify::before {
  content: ""; }

.bp3-icon-align-left::before {
  content: ""; }

.bp3-icon-align-right::before {
  content: ""; }

.bp3-icon-alignment-bottom::before {
  content: ""; }

.bp3-icon-alignment-horizontal-center::before {
  content: ""; }

.bp3-icon-alignment-left::before {
  content: ""; }

.bp3-icon-alignment-right::before {
  content: ""; }

.bp3-icon-alignment-top::before {
  content: ""; }

.bp3-icon-alignment-vertical-center::before {
  content: ""; }

.bp3-icon-annotation::before {
  content: ""; }

.bp3-icon-application::before {
  content: ""; }

.bp3-icon-applications::before {
  content: ""; }

.bp3-icon-archive::before {
  content: ""; }

.bp3-icon-arrow-bottom-left::before {
  content: "↙"; }

.bp3-icon-arrow-bottom-right::before {
  content: "↘"; }

.bp3-icon-arrow-down::before {
  content: "↓"; }

.bp3-icon-arrow-left::before {
  content: "←"; }

.bp3-icon-arrow-right::before {
  content: "→"; }

.bp3-icon-arrow-top-left::before {
  content: "↖"; }

.bp3-icon-arrow-top-right::before {
  content: "↗"; }

.bp3-icon-arrow-up::before {
  content: "↑"; }

.bp3-icon-arrows-horizontal::before {
  content: "↔"; }

.bp3-icon-arrows-vertical::before {
  content: "↕"; }

.bp3-icon-asterisk::before {
  content: "*"; }

.bp3-icon-automatic-updates::before {
  content: ""; }

.bp3-icon-badge::before {
  content: ""; }

.bp3-icon-ban-circle::before {
  content: ""; }

.bp3-icon-bank-account::before {
  content: ""; }

.bp3-icon-barcode::before {
  content: ""; }

.bp3-icon-blank::before {
  content: ""; }

.bp3-icon-blocked-person::before {
  content: ""; }

.bp3-icon-bold::before {
  content: ""; }

.bp3-icon-book::before {
  content: ""; }

.bp3-icon-bookmark::before {
  content: ""; }

.bp3-icon-box::before {
  content: ""; }

.bp3-icon-briefcase::before {
  content: ""; }

.bp3-icon-bring-data::before {
  content: ""; }

.bp3-icon-build::before {
  content: ""; }

.bp3-icon-calculator::before {
  content: ""; }

.bp3-icon-calendar::before {
  content: ""; }

.bp3-icon-camera::before {
  content: ""; }

.bp3-icon-caret-down::before {
  content: "⌄"; }

.bp3-icon-caret-left::before {
  content: "〈"; }

.bp3-icon-caret-right::before {
  content: "〉"; }

.bp3-icon-caret-up::before {
  content: "⌃"; }

.bp3-icon-cell-tower::before {
  content: ""; }

.bp3-icon-changes::before {
  content: ""; }

.bp3-icon-chart::before {
  content: ""; }

.bp3-icon-chat::before {
  content: ""; }

.bp3-icon-chevron-backward::before {
  content: ""; }

.bp3-icon-chevron-down::before {
  content: ""; }

.bp3-icon-chevron-forward::before {
  content: ""; }

.bp3-icon-chevron-left::before {
  content: ""; }

.bp3-icon-chevron-right::before {
  content: ""; }

.bp3-icon-chevron-up::before {
  content: ""; }

.bp3-icon-circle::before {
  content: ""; }

.bp3-icon-circle-arrow-down::before {
  content: ""; }

.bp3-icon-circle-arrow-left::before {
  content: ""; }

.bp3-icon-circle-arrow-right::before {
  content: ""; }

.bp3-icon-circle-arrow-up::before {
  content: ""; }

.bp3-icon-citation::before {
  content: ""; }

.bp3-icon-clean::before {
  content: ""; }

.bp3-icon-clipboard::before {
  content: ""; }

.bp3-icon-cloud::before {
  content: "☁"; }

.bp3-icon-cloud-download::before {
  content: ""; }

.bp3-icon-cloud-upload::before {
  content: ""; }

.bp3-icon-code::before {
  content: ""; }

.bp3-icon-code-block::before {
  content: ""; }

.bp3-icon-cog::before {
  content: ""; }

.bp3-icon-collapse-all::before {
  content: ""; }

.bp3-icon-column-layout::before {
  content: ""; }

.bp3-icon-comment::before {
  content: ""; }

.bp3-icon-comparison::before {
  content: ""; }

.bp3-icon-compass::before {
  content: ""; }

.bp3-icon-compressed::before {
  content: ""; }

.bp3-icon-confirm::before {
  content: ""; }

.bp3-icon-console::before {
  content: ""; }

.bp3-icon-contrast::before {
  content: ""; }

.bp3-icon-control::before {
  content: ""; }

.bp3-icon-credit-card::before {
  content: ""; }

.bp3-icon-cross::before {
  content: "✗"; }

.bp3-icon-crown::before {
  content: ""; }

.bp3-icon-cube::before {
  content: ""; }

.bp3-icon-cube-add::before {
  content: ""; }

.bp3-icon-cube-remove::before {
  content: ""; }

.bp3-icon-curved-range-chart::before {
  content: ""; }

.bp3-icon-cut::before {
  content: ""; }

.bp3-icon-dashboard::before {
  content: ""; }

.bp3-icon-data-lineage::before {
  content: ""; }

.bp3-icon-database::before {
  content: ""; }

.bp3-icon-delete::before {
  content: ""; }

.bp3-icon-delta::before {
  content: "Δ"; }

.bp3-icon-derive-column::before {
  content: ""; }

.bp3-icon-desktop::before {
  content: ""; }

.bp3-icon-diagnosis::before {
  content: ""; }

.bp3-icon-diagram-tree::before {
  content: ""; }

.bp3-icon-direction-left::before {
  content: ""; }

.bp3-icon-direction-right::before {
  content: ""; }

.bp3-icon-disable::before {
  content: ""; }

.bp3-icon-document::before {
  content: ""; }

.bp3-icon-document-open::before {
  content: ""; }

.bp3-icon-document-share::before {
  content: ""; }

.bp3-icon-dollar::before {
  content: "$"; }

.bp3-icon-dot::before {
  content: "•"; }

.bp3-icon-double-caret-horizontal::before {
  content: ""; }

.bp3-icon-double-caret-vertical::before {
  content: ""; }

.bp3-icon-double-chevron-down::before {
  content: ""; }

.bp3-icon-double-chevron-left::before {
  content: ""; }

.bp3-icon-double-chevron-right::before {
  content: ""; }

.bp3-icon-double-chevron-up::before {
  content: ""; }

.bp3-icon-doughnut-chart::before {
  content: ""; }

.bp3-icon-download::before {
  content: ""; }

.bp3-icon-drag-handle-horizontal::before {
  content: ""; }

.bp3-icon-drag-handle-vertical::before {
  content: ""; }

.bp3-icon-draw::before {
  content: ""; }

.bp3-icon-drive-time::before {
  content: ""; }

.bp3-icon-duplicate::before {
  content: ""; }

.bp3-icon-edit::before {
  content: "✎"; }

.bp3-icon-eject::before {
  content: "⏏"; }

.bp3-icon-endorsed::before {
  content: ""; }

.bp3-icon-envelope::before {
  content: "✉"; }

.bp3-icon-equals::before {
  content: ""; }

.bp3-icon-eraser::before {
  content: ""; }

.bp3-icon-error::before {
  content: ""; }

.bp3-icon-euro::before {
  content: "€"; }

.bp3-icon-exchange::before {
  content: ""; }

.bp3-icon-exclude-row::before {
  content: ""; }

.bp3-icon-expand-all::before {
  content: ""; }

.bp3-icon-export::before {
  content: ""; }

.bp3-icon-eye-off::before {
  content: ""; }

.bp3-icon-eye-on::before {
  content: ""; }

.bp3-icon-eye-open::before {
  content: ""; }

.bp3-icon-fast-backward::before {
  content: ""; }

.bp3-icon-fast-forward::before {
  content: ""; }

.bp3-icon-feed::before {
  content: ""; }

.bp3-icon-feed-subscribed::before {
  content: ""; }

.bp3-icon-film::before {
  content: ""; }

.bp3-icon-filter::before {
  content: ""; }

.bp3-icon-filter-keep::before {
  content: ""; }

.bp3-icon-filter-list::before {
  content: ""; }

.bp3-icon-filter-open::before {
  content: ""; }

.bp3-icon-filter-remove::before {
  content: ""; }

.bp3-icon-flag::before {
  content: "⚑"; }

.bp3-icon-flame::before {
  content: ""; }

.bp3-icon-flash::before {
  content: ""; }

.bp3-icon-floppy-disk::before {
  content: ""; }

.bp3-icon-flow-branch::before {
  content: ""; }

.bp3-icon-flow-end::before {
  content: ""; }

.bp3-icon-flow-linear::before {
  content: ""; }

.bp3-icon-flow-review::before {
  content: ""; }

.bp3-icon-flow-review-branch::before {
  content: ""; }

.bp3-icon-flows::before {
  content: ""; }

.bp3-icon-folder-close::before {
  content: ""; }

.bp3-icon-folder-new::before {
  content: ""; }

.bp3-icon-folder-open::before {
  content: ""; }

.bp3-icon-folder-shared::before {
  content: ""; }

.bp3-icon-folder-shared-open::before {
  content: ""; }

.bp3-icon-follower::before {
  content: ""; }

.bp3-icon-following::before {
  content: ""; }

.bp3-icon-font::before {
  content: ""; }

.bp3-icon-fork::before {
  content: ""; }

.bp3-icon-form::before {
  content: ""; }

.bp3-icon-full-circle::before {
  content: ""; }

.bp3-icon-full-stacked-chart::before {
  content: ""; }

.bp3-icon-fullscreen::before {
  content: ""; }

.bp3-icon-function::before {
  content: ""; }

.bp3-icon-gantt-chart::before {
  content: ""; }

.bp3-icon-geolocation::before {
  content: ""; }

.bp3-icon-geosearch::before {
  content: ""; }

.bp3-icon-git-branch::before {
  content: ""; }

.bp3-icon-git-commit::before {
  content: ""; }

.bp3-icon-git-merge::before {
  content: ""; }

.bp3-icon-git-new-branch::before {
  content: ""; }

.bp3-icon-git-pull::before {
  content: ""; }

.bp3-icon-git-push::before {
  content: ""; }

.bp3-icon-git-repo::before {
  content: ""; }

.bp3-icon-glass::before {
  content: ""; }

.bp3-icon-globe::before {
  content: ""; }

.bp3-icon-globe-network::before {
  content: ""; }

.bp3-icon-graph::before {
  content: ""; }

.bp3-icon-graph-remove::before {
  content: ""; }

.bp3-icon-greater-than::before {
  content: ""; }

.bp3-icon-greater-than-or-equal-to::before {
  content: ""; }

.bp3-icon-grid::before {
  content: ""; }

.bp3-icon-grid-view::before {
  content: ""; }

.bp3-icon-group-objects::before {
  content: ""; }

.bp3-icon-grouped-bar-chart::before {
  content: ""; }

.bp3-icon-hand::before {
  content: ""; }

.bp3-icon-hand-down::before {
  content: ""; }

.bp3-icon-hand-left::before {
  content: ""; }

.bp3-icon-hand-right::before {
  content: ""; }

.bp3-icon-hand-up::before {
  content: ""; }

.bp3-icon-header::before {
  content: ""; }

.bp3-icon-header-one::before {
  content: ""; }

.bp3-icon-header-two::before {
  content: ""; }

.bp3-icon-headset::before {
  content: ""; }

.bp3-icon-heart::before {
  content: "♥"; }

.bp3-icon-heart-broken::before {
  content: ""; }

.bp3-icon-heat-grid::before {
  content: ""; }

.bp3-icon-heatmap::before {
  content: ""; }

.bp3-icon-help::before {
  content: "?"; }

.bp3-icon-helper-management::before {
  content: ""; }

.bp3-icon-highlight::before {
  content: ""; }

.bp3-icon-history::before {
  content: ""; }

.bp3-icon-home::before {
  content: "⌂"; }

.bp3-icon-horizontal-bar-chart::before {
  content: ""; }

.bp3-icon-horizontal-bar-chart-asc::before {
  content: ""; }

.bp3-icon-horizontal-bar-chart-desc::before {
  content: ""; }

.bp3-icon-horizontal-distribution::before {
  content: ""; }

.bp3-icon-id-number::before {
  content: ""; }

.bp3-icon-image-rotate-left::before {
  content: ""; }

.bp3-icon-image-rotate-right::before {
  content: ""; }

.bp3-icon-import::before {
  content: ""; }

.bp3-icon-inbox::before {
  content: ""; }

.bp3-icon-inbox-filtered::before {
  content: ""; }

.bp3-icon-inbox-geo::before {
  content: ""; }

.bp3-icon-inbox-search::before {
  content: ""; }

.bp3-icon-inbox-update::before {
  content: ""; }

.bp3-icon-info-sign::before {
  content: "ℹ"; }

.bp3-icon-inheritance::before {
  content: ""; }

.bp3-icon-inner-join::before {
  content: ""; }

.bp3-icon-insert::before {
  content: ""; }

.bp3-icon-intersection::before {
  content: ""; }

.bp3-icon-ip-address::before {
  content: ""; }

.bp3-icon-issue::before {
  content: ""; }

.bp3-icon-issue-closed::before {
  content: ""; }

.bp3-icon-issue-new::before {
  content: ""; }

.bp3-icon-italic::before {
  content: ""; }

.bp3-icon-join-table::before {
  content: ""; }

.bp3-icon-key::before {
  content: ""; }

.bp3-icon-key-backspace::before {
  content: ""; }

.bp3-icon-key-command::before {
  content: ""; }

.bp3-icon-key-control::before {
  content: ""; }

.bp3-icon-key-delete::before {
  content: ""; }

.bp3-icon-key-enter::before {
  content: ""; }

.bp3-icon-key-escape::before {
  content: ""; }

.bp3-icon-key-option::before {
  content: ""; }

.bp3-icon-key-shift::before {
  content: ""; }

.bp3-icon-key-tab::before {
  content: ""; }

.bp3-icon-known-vehicle::before {
  content: ""; }

.bp3-icon-lab-test::before {
  content: ""; }

.bp3-icon-label::before {
  content: ""; }

.bp3-icon-layer::before {
  content: ""; }

.bp3-icon-layers::before {
  content: ""; }

.bp3-icon-layout::before {
  content: ""; }

.bp3-icon-layout-auto::before {
  content: ""; }

.bp3-icon-layout-balloon::before {
  content: ""; }

.bp3-icon-layout-circle::before {
  content: ""; }

.bp3-icon-layout-grid::before {
  content: ""; }

.bp3-icon-layout-group-by::before {
  content: ""; }

.bp3-icon-layout-hierarchy::before {
  content: ""; }

.bp3-icon-layout-linear::before {
  content: ""; }

.bp3-icon-layout-skew-grid::before {
  content: ""; }

.bp3-icon-layout-sorted-clusters::before {
  content: ""; }

.bp3-icon-learning::before {
  content: ""; }

.bp3-icon-left-join::before {
  content: ""; }

.bp3-icon-less-than::before {
  content: ""; }

.bp3-icon-less-than-or-equal-to::before {
  content: ""; }

.bp3-icon-lifesaver::before {
  content: ""; }

.bp3-icon-lightbulb::before {
  content: ""; }

.bp3-icon-link::before {
  content: ""; }

.bp3-icon-list::before {
  content: "☰"; }

.bp3-icon-list-columns::before {
  content: ""; }

.bp3-icon-list-detail-view::before {
  content: ""; }

.bp3-icon-locate::before {
  content: ""; }

.bp3-icon-lock::before {
  content: ""; }

.bp3-icon-log-in::before {
  content: ""; }

.bp3-icon-log-out::before {
  content: ""; }

.bp3-icon-manual::before {
  content: ""; }

.bp3-icon-manually-entered-data::before {
  content: ""; }

.bp3-icon-map::before {
  content: ""; }

.bp3-icon-map-create::before {
  content: ""; }

.bp3-icon-map-marker::before {
  content: ""; }

.bp3-icon-maximize::before {
  content: ""; }

.bp3-icon-media::before {
  content: ""; }

.bp3-icon-menu::before {
  content: ""; }

.bp3-icon-menu-closed::before {
  content: ""; }

.bp3-icon-menu-open::before {
  content: ""; }

.bp3-icon-merge-columns::before {
  content: ""; }

.bp3-icon-merge-links::before {
  content: ""; }

.bp3-icon-minimize::before {
  content: ""; }

.bp3-icon-minus::before {
  content: "−"; }

.bp3-icon-mobile-phone::before {
  content: ""; }

.bp3-icon-mobile-video::before {
  content: ""; }

.bp3-icon-moon::before {
  content: ""; }

.bp3-icon-more::before {
  content: ""; }

.bp3-icon-mountain::before {
  content: ""; }

.bp3-icon-move::before {
  content: ""; }

.bp3-icon-mugshot::before {
  content: ""; }

.bp3-icon-multi-select::before {
  content: ""; }

.bp3-icon-music::before {
  content: ""; }

.bp3-icon-new-drawing::before {
  content: ""; }

.bp3-icon-new-grid-item::before {
  content: ""; }

.bp3-icon-new-layer::before {
  content: ""; }

.bp3-icon-new-layers::before {
  content: ""; }

.bp3-icon-new-link::before {
  content: ""; }

.bp3-icon-new-object::before {
  content: ""; }

.bp3-icon-new-person::before {
  content: ""; }

.bp3-icon-new-prescription::before {
  content: ""; }

.bp3-icon-new-text-box::before {
  content: ""; }

.bp3-icon-ninja::before {
  content: ""; }

.bp3-icon-not-equal-to::before {
  content: ""; }

.bp3-icon-notifications::before {
  content: ""; }

.bp3-icon-notifications-updated::before {
  content: ""; }

.bp3-icon-numbered-list::before {
  content: ""; }

.bp3-icon-numerical::before {
  content: ""; }

.bp3-icon-office::before {
  content: ""; }

.bp3-icon-offline::before {
  content: ""; }

.bp3-icon-oil-field::before {
  content: ""; }

.bp3-icon-one-column::before {
  content: ""; }

.bp3-icon-outdated::before {
  content: ""; }

.bp3-icon-page-layout::before {
  content: ""; }

.bp3-icon-panel-stats::before {
  content: ""; }

.bp3-icon-panel-table::before {
  content: ""; }

.bp3-icon-paperclip::before {
  content: ""; }

.bp3-icon-paragraph::before {
  content: ""; }

.bp3-icon-path::before {
  content: ""; }

.bp3-icon-path-search::before {
  content: ""; }

.bp3-icon-pause::before {
  content: ""; }

.bp3-icon-people::before {
  content: ""; }

.bp3-icon-percentage::before {
  content: ""; }

.bp3-icon-person::before {
  content: ""; }

.bp3-icon-phone::before {
  content: "☎"; }

.bp3-icon-pie-chart::before {
  content: ""; }

.bp3-icon-pin::before {
  content: ""; }

.bp3-icon-pivot::before {
  content: ""; }

.bp3-icon-pivot-table::before {
  content: ""; }

.bp3-icon-play::before {
  content: ""; }

.bp3-icon-plus::before {
  content: "+"; }

.bp3-icon-polygon-filter::before {
  content: ""; }

.bp3-icon-power::before {
  content: ""; }

.bp3-icon-predictive-analysis::before {
  content: ""; }

.bp3-icon-prescription::before {
  content: ""; }

.bp3-icon-presentation::before {
  content: ""; }

.bp3-icon-print::before {
  content: "⎙"; }

.bp3-icon-projects::before {
  content: ""; }

.bp3-icon-properties::before {
  content: ""; }

.bp3-icon-property::before {
  content: ""; }

.bp3-icon-publish-function::before {
  content: ""; }

.bp3-icon-pulse::before {
  content: ""; }

.bp3-icon-random::before {
  content: ""; }

.bp3-icon-record::before {
  content: ""; }

.bp3-icon-redo::before {
  content: ""; }

.bp3-icon-refresh::before {
  content: ""; }

.bp3-icon-regression-chart::before {
  content: ""; }

.bp3-icon-remove::before {
  content: ""; }

.bp3-icon-remove-column::before {
  content: ""; }

.bp3-icon-remove-column-left::before {
  content: ""; }

.bp3-icon-remove-column-right::before {
  content: ""; }

.bp3-icon-remove-row-bottom::before {
  content: ""; }

.bp3-icon-remove-row-top::before {
  content: ""; }

.bp3-icon-repeat::before {
  content: ""; }

.bp3-icon-reset::before {
  content: ""; }

.bp3-icon-resolve::before {
  content: ""; }

.bp3-icon-rig::before {
  content: ""; }

.bp3-icon-right-join::before {
  content: ""; }

.bp3-icon-ring::before {
  content: ""; }

.bp3-icon-rotate-document::before {
  content: ""; }

.bp3-icon-rotate-page::before {
  content: ""; }

.bp3-icon-satellite::before {
  content: ""; }

.bp3-icon-saved::before {
  content: ""; }

.bp3-icon-scatter-plot::before {
  content: ""; }

.bp3-icon-search::before {
  content: ""; }

.bp3-icon-search-around::before {
  content: ""; }

.bp3-icon-search-template::before {
  content: ""; }

.bp3-icon-search-text::before {
  content: ""; }

.bp3-icon-segmented-control::before {
  content: ""; }

.bp3-icon-select::before {
  content: ""; }

.bp3-icon-selection::before {
  content: "⦿"; }

.bp3-icon-send-to::before {
  content: ""; }

.bp3-icon-send-to-graph::before {
  content: ""; }

.bp3-icon-send-to-map::before {
  content: ""; }

.bp3-icon-series-add::before {
  content: ""; }

.bp3-icon-series-configuration::before {
  content: ""; }

.bp3-icon-series-derived::before {
  content: ""; }

.bp3-icon-series-filtered::before {
  content: ""; }

.bp3-icon-series-search::before {
  content: ""; }

.bp3-icon-settings::before {
  content: ""; }

.bp3-icon-share::before {
  content: ""; }

.bp3-icon-shield::before {
  content: ""; }

.bp3-icon-shop::before {
  content: ""; }

.bp3-icon-shopping-cart::before {
  content: ""; }

.bp3-icon-signal-search::before {
  content: ""; }

.bp3-icon-sim-card::before {
  content: ""; }

.bp3-icon-slash::before {
  content: ""; }

.bp3-icon-small-cross::before {
  content: ""; }

.bp3-icon-small-minus::before {
  content: ""; }

.bp3-icon-small-plus::before {
  content: ""; }

.bp3-icon-small-tick::before {
  content: ""; }

.bp3-icon-snowflake::before {
  content: ""; }

.bp3-icon-social-media::before {
  content: ""; }

.bp3-icon-sort::before {
  content: ""; }

.bp3-icon-sort-alphabetical::before {
  content: ""; }

.bp3-icon-sort-alphabetical-desc::before {
  content: ""; }

.bp3-icon-sort-asc::before {
  content: ""; }

.bp3-icon-sort-desc::before {
  content: ""; }

.bp3-icon-sort-numerical::before {
  content: ""; }

.bp3-icon-sort-numerical-desc::before {
  content: ""; }

.bp3-icon-split-columns::before {
  content: ""; }

.bp3-icon-square::before {
  content: ""; }

.bp3-icon-stacked-chart::before {
  content: ""; }

.bp3-icon-star::before {
  content: "★"; }

.bp3-icon-star-empty::before {
  content: "☆"; }

.bp3-icon-step-backward::before {
  content: ""; }

.bp3-icon-step-chart::before {
  content: ""; }

.bp3-icon-step-forward::before {
  content: ""; }

.bp3-icon-stop::before {
  content: ""; }

.bp3-icon-stopwatch::before {
  content: ""; }

.bp3-icon-strikethrough::before {
  content: ""; }

.bp3-icon-style::before {
  content: ""; }

.bp3-icon-swap-horizontal::before {
  content: ""; }

.bp3-icon-swap-vertical::before {
  content: ""; }

.bp3-icon-symbol-circle::before {
  content: ""; }

.bp3-icon-symbol-cross::before {
  content: ""; }

.bp3-icon-symbol-diamond::before {
  content: ""; }

.bp3-icon-symbol-square::before {
  content: ""; }

.bp3-icon-symbol-triangle-down::before {
  content: ""; }

.bp3-icon-symbol-triangle-up::before {
  content: ""; }

.bp3-icon-tag::before {
  content: ""; }

.bp3-icon-take-action::before {
  content: ""; }

.bp3-icon-taxi::before {
  content: ""; }

.bp3-icon-text-highlight::before {
  content: ""; }

.bp3-icon-th::before {
  content: ""; }

.bp3-icon-th-derived::before {
  content: ""; }

.bp3-icon-th-disconnect::before {
  content: ""; }

.bp3-icon-th-filtered::before {
  content: ""; }

.bp3-icon-th-list::before {
  content: ""; }

.bp3-icon-thumbs-down::before {
  content: ""; }

.bp3-icon-thumbs-up::before {
  content: ""; }

.bp3-icon-tick::before {
  content: "✓"; }

.bp3-icon-tick-circle::before {
  content: ""; }

.bp3-icon-time::before {
  content: "⏲"; }

.bp3-icon-timeline-area-chart::before {
  content: ""; }

.bp3-icon-timeline-bar-chart::before {
  content: ""; }

.bp3-icon-timeline-events::before {
  content: ""; }

.bp3-icon-timeline-line-chart::before {
  content: ""; }

.bp3-icon-tint::before {
  content: ""; }

.bp3-icon-torch::before {
  content: ""; }

.bp3-icon-tractor::before {
  content: ""; }

.bp3-icon-train::before {
  content: ""; }

.bp3-icon-translate::before {
  content: ""; }

.bp3-icon-trash::before {
  content: ""; }

.bp3-icon-tree::before {
  content: ""; }

.bp3-icon-trending-down::before {
  content: ""; }

.bp3-icon-trending-up::before {
  content: ""; }

.bp3-icon-truck::before {
  content: ""; }

.bp3-icon-two-columns::before {
  content: ""; }

.bp3-icon-unarchive::before {
  content: ""; }

.bp3-icon-underline::before {
  content: "⎁"; }

.bp3-icon-undo::before {
  content: "⎌"; }

.bp3-icon-ungroup-objects::before {
  content: ""; }

.bp3-icon-unknown-vehicle::before {
  content: ""; }

.bp3-icon-unlock::before {
  content: ""; }

.bp3-icon-unpin::before {
  content: ""; }

.bp3-icon-unresolve::before {
  content: ""; }

.bp3-icon-updated::before {
  content: ""; }

.bp3-icon-upload::before {
  content: ""; }

.bp3-icon-user::before {
  content: ""; }

.bp3-icon-variable::before {
  content: ""; }

.bp3-icon-vertical-bar-chart-asc::before {
  content: ""; }

.bp3-icon-vertical-bar-chart-desc::before {
  content: ""; }

.bp3-icon-vertical-distribution::before {
  content: ""; }

.bp3-icon-video::before {
  content: ""; }

.bp3-icon-volume-down::before {
  content: ""; }

.bp3-icon-volume-off::before {
  content: ""; }

.bp3-icon-volume-up::before {
  content: ""; }

.bp3-icon-walk::before {
  content: ""; }

.bp3-icon-warning-sign::before {
  content: ""; }

.bp3-icon-waterfall-chart::before {
  content: ""; }

.bp3-icon-widget::before {
  content: ""; }

.bp3-icon-widget-button::before {
  content: ""; }

.bp3-icon-widget-footer::before {
  content: ""; }

.bp3-icon-widget-header::before {
  content: ""; }

.bp3-icon-wrench::before {
  content: ""; }

.bp3-icon-zoom-in::before {
  content: ""; }

.bp3-icon-zoom-out::before {
  content: ""; }

.bp3-icon-zoom-to-fit::before {
  content: ""; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.bp3-submenu > .bp3-popover-wrapper {
  display: block; }

.bp3-submenu .bp3-popover-target {
  display: block; }

.bp3-submenu.bp3-popover {
  box-shadow: none;
  padding: 0 5px; }
  .bp3-submenu.bp3-popover > .bp3-popover-content {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2); }
  .bp3-dark .bp3-submenu.bp3-popover, .bp3-submenu.bp3-popover.bp3-dark {
    box-shadow: none; }
    .bp3-dark .bp3-submenu.bp3-popover > .bp3-popover-content, .bp3-submenu.bp3-popover.bp3-dark > .bp3-popover-content {
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4); }

/*
Menus

Markup:
<ul class="bp3-menu {{.modifier}} bp3-elevation-1">
  <li>
    <a class="bp3-menu-item bp3-icon-people" tabindex="0">Share...</a>
  </li>
  <li>
    <a class="bp3-menu-item bp3-icon-circle-arrow-right" tabindex="0">Move...</a>
  </li>
  <li>
    <a class="bp3-menu-item bp3-icon-edit" tabindex="0">Rename</a>
  </li>
  <li class="bp3-menu-divider"></li>
  <li>
    <a class="bp3-menu-item bp3-icon-trash bp3-intent-danger" tabindex="0">Delete</a>
  </li>
</ul>

.bp3-large - Large size (only supported on <code>.bp3-menu</code>)

Styleguide menu
*/
.bp3-menu {
  background: #ffffff;
  border-radius: 3px;
  color: #182026;
  list-style: none;
  margin: 0;
  min-width: 180px;
  padding: 5px;
  text-align: left; }

.bp3-menu-divider {
  border-top: 1px solid rgba(16, 22, 26, 0.15);
  display: block;
  margin: 5px; }
  .bp3-dark .bp3-menu-divider {
    border-top-color: rgba(255, 255, 255, 0.15); }

.bp3-menu-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 2px;
  color: inherit;
  line-height: 20px;
  padding: 5px 7px;
  text-decoration: none;
  user-select: none; }
  .bp3-menu-item > * {
    flex-grow: 0;
    flex-shrink: 0; }
  .bp3-menu-item > .bp3-fill {
    flex-grow: 1;
    flex-shrink: 1; }
  .bp3-menu-item::before,
  .bp3-menu-item > * {
    margin-right: 7px; }
  .bp3-menu-item:empty::before,
  .bp3-menu-item > :last-child {
    margin-right: 0; }
  .bp3-menu-item > .bp3-fill {
    word-break: break-word; }
  .bp3-menu-item:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-menu-item {
    background-color: rgba(233, 78, 27, 0.3);
    cursor: pointer;
    text-decoration: none; }
  .bp3-menu-item.bp3-disabled {
    background-color: inherit;
    color: rgba(255, 255, 255, 0.6);
    cursor: not-allowed; }
  .bp3-dark .bp3-menu-item {
    color: inherit; }
    .bp3-dark .bp3-menu-item:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-menu-item {
      background-color: rgba(233, 78, 27, 0.15);
      color: inherit; }
    .bp3-dark .bp3-menu-item.bp3-disabled {
      background-color: inherit;
      color: rgba(233, 78, 27, 0.6); }
  .bp3-menu-item.bp3-intent-primary {
    color: #e94e1b; }
    .bp3-menu-item.bp3-intent-primary .bp3-icon {
      color: inherit; }
    .bp3-menu-item.bp3-intent-primary::before, .bp3-menu-item.bp3-intent-primary::after,
    .bp3-menu-item.bp3-intent-primary .bp3-menu-item-label {
      color: #e94e1b; }
    .bp3-menu-item.bp3-intent-primary:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item, .bp3-menu-item.bp3-intent-primary.bp3-active {
      background-color: #e94e1b; }
    .bp3-menu-item.bp3-intent-primary:active {
      background-color: #e94e1b; }
    .bp3-menu-item.bp3-intent-primary:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item, .bp3-menu-item.bp3-intent-primary:hover::before, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item::before, .bp3-menu-item.bp3-intent-primary:hover::after, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item::after,
    .bp3-menu-item.bp3-intent-primary:hover .bp3-menu-item-label,
    .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item .bp3-menu-item-label, .bp3-menu-item.bp3-intent-primary:active, .bp3-menu-item.bp3-intent-primary:active::before, .bp3-menu-item.bp3-intent-primary:active::after,
    .bp3-menu-item.bp3-intent-primary:active .bp3-menu-item-label, .bp3-menu-item.bp3-intent-primary.bp3-active, .bp3-menu-item.bp3-intent-primary.bp3-active::before, .bp3-menu-item.bp3-intent-primary.bp3-active::after,
    .bp3-menu-item.bp3-intent-primary.bp3-active .bp3-menu-item-label {
      color: #ffffff; }
  .bp3-menu-item.bp3-intent-success {
    color: #0d8050; }
    .bp3-menu-item.bp3-intent-success .bp3-icon {
      color: inherit; }
    .bp3-menu-item.bp3-intent-success::before, .bp3-menu-item.bp3-intent-success::after,
    .bp3-menu-item.bp3-intent-success .bp3-menu-item-label {
      color: #0d8050; }
    .bp3-menu-item.bp3-intent-success:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item, .bp3-menu-item.bp3-intent-success.bp3-active {
      background-color: #0f9960; }
    .bp3-menu-item.bp3-intent-success:active {
      background-color: #0d8050; }
    .bp3-menu-item.bp3-intent-success:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item, .bp3-menu-item.bp3-intent-success:hover::before, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item::before, .bp3-menu-item.bp3-intent-success:hover::after, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item::after,
    .bp3-menu-item.bp3-intent-success:hover .bp3-menu-item-label,
    .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item .bp3-menu-item-label, .bp3-menu-item.bp3-intent-success:active, .bp3-menu-item.bp3-intent-success:active::before, .bp3-menu-item.bp3-intent-success:active::after,
    .bp3-menu-item.bp3-intent-success:active .bp3-menu-item-label, .bp3-menu-item.bp3-intent-success.bp3-active, .bp3-menu-item.bp3-intent-success.bp3-active::before, .bp3-menu-item.bp3-intent-success.bp3-active::after,
    .bp3-menu-item.bp3-intent-success.bp3-active .bp3-menu-item-label {
      color: #ffffff; }
  .bp3-menu-item.bp3-intent-warning {
    color: #bf7326; }
    .bp3-menu-item.bp3-intent-warning .bp3-icon {
      color: inherit; }
    .bp3-menu-item.bp3-intent-warning::before, .bp3-menu-item.bp3-intent-warning::after,
    .bp3-menu-item.bp3-intent-warning .bp3-menu-item-label {
      color: #bf7326; }
    .bp3-menu-item.bp3-intent-warning:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item, .bp3-menu-item.bp3-intent-warning.bp3-active {
      background-color: #d9822b; }
    .bp3-menu-item.bp3-intent-warning:active {
      background-color: #bf7326; }
    .bp3-menu-item.bp3-intent-warning:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item, .bp3-menu-item.bp3-intent-warning:hover::before, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item::before, .bp3-menu-item.bp3-intent-warning:hover::after, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item::after,
    .bp3-menu-item.bp3-intent-warning:hover .bp3-menu-item-label,
    .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item .bp3-menu-item-label, .bp3-menu-item.bp3-intent-warning:active, .bp3-menu-item.bp3-intent-warning:active::before, .bp3-menu-item.bp3-intent-warning:active::after,
    .bp3-menu-item.bp3-intent-warning:active .bp3-menu-item-label, .bp3-menu-item.bp3-intent-warning.bp3-active, .bp3-menu-item.bp3-intent-warning.bp3-active::before, .bp3-menu-item.bp3-intent-warning.bp3-active::after,
    .bp3-menu-item.bp3-intent-warning.bp3-active .bp3-menu-item-label {
      color: #ffffff; }
  .bp3-menu-item.bp3-intent-danger {
    color: #c23030; }
    .bp3-menu-item.bp3-intent-danger .bp3-icon {
      color: inherit; }
    .bp3-menu-item.bp3-intent-danger::before, .bp3-menu-item.bp3-intent-danger::after,
    .bp3-menu-item.bp3-intent-danger .bp3-menu-item-label {
      color: #c23030; }
    .bp3-menu-item.bp3-intent-danger:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item, .bp3-menu-item.bp3-intent-danger.bp3-active {
      background-color: #db3737; }
    .bp3-menu-item.bp3-intent-danger:active {
      background-color: #c23030; }
    .bp3-menu-item.bp3-intent-danger:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item, .bp3-menu-item.bp3-intent-danger:hover::before, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item::before, .bp3-menu-item.bp3-intent-danger:hover::after, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item::after,
    .bp3-menu-item.bp3-intent-danger:hover .bp3-menu-item-label,
    .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item .bp3-menu-item-label, .bp3-menu-item.bp3-intent-danger:active, .bp3-menu-item.bp3-intent-danger:active::before, .bp3-menu-item.bp3-intent-danger:active::after,
    .bp3-menu-item.bp3-intent-danger:active .bp3-menu-item-label, .bp3-menu-item.bp3-intent-danger.bp3-active, .bp3-menu-item.bp3-intent-danger.bp3-active::before, .bp3-menu-item.bp3-intent-danger.bp3-active::after,
    .bp3-menu-item.bp3-intent-danger.bp3-active .bp3-menu-item-label {
      color: #ffffff; }
  .bp3-menu-item::before {
    font-family: "Icons16", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    margin-right: 7px; }
  .bp3-menu-item::before,
  .bp3-menu-item > .bp3-icon {
    color: #ffffff;
    margin-top: 2px; }
  .bp3-menu-item .bp3-menu-item-label {
    color: #ffffff; }
  .bp3-menu-item:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-menu-item {
    color: inherit; }
  .bp3-menu-item.bp3-active, .bp3-menu-item:active {
    background-color: rgba(233, 78, 27, 0.3); }
  .bp3-menu-item.bp3-disabled {
    background-color: inherit !important;
    color: rgba(255, 255, 255, 0.6) !important;
    cursor: not-allowed !important;
    outline: none !important; }
    .bp3-menu-item.bp3-disabled::before,
    .bp3-menu-item.bp3-disabled > .bp3-icon,
    .bp3-menu-item.bp3-disabled .bp3-menu-item-label {
      color: rgba(255, 255, 255, 0.6) !important; }
  .bp3-large .bp3-menu-item {
    font-size: 16px;
    line-height: 22px;
    padding: 9px 7px; }
    .bp3-large .bp3-menu-item .bp3-icon {
      margin-top: 3px; }
    .bp3-large .bp3-menu-item::before {
      font-family: "Icons20", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      margin-right: 10px;
      margin-top: 1px; }

button.bp3-menu-item {
  background: none;
  border: none;
  text-align: left;
  width: 100%; }

/*
Menu headers

Markup:
<ul class="bp3-menu bp3-elevation-1">
  <li class="bp3-menu-header"><h6 class="bp3-heading">Layouts</h6></li>
  <li><button type="button" class="bp3-menu-item bp3-icon-layout-auto">Auto</button></li>
  <li><button type="button" class="bp3-menu-item bp3-icon-layout-circle">Circle</button></li>
  <li><button type="button" class="bp3-menu-item bp3-icon-layout-grid">Grid</button></li>
  <li class="bp3-menu-header"><h6 class="bp3-heading">Views</h6></li>
  <li><button type="button" class="bp3-menu-item bp3-icon-history">History</button></li>
  <li><button type="button" class="bp3-menu-item bp3-icon-star">Favorites</button></li>
  <li><button type="button" class="bp3-menu-item bp3-icon-envelope">Messages</button></li>
</ul>

Styleguide menu-header
*/
.bp3-menu-header {
  border-top: 1px solid rgba(16, 22, 26, 0.15);
  display: block;
  margin: 5px;
  cursor: default;
  padding-left: 2px; }
  .bp3-dark .bp3-menu-header {
    border-top-color: rgba(255, 255, 255, 0.15); }
  .bp3-menu-header:first-of-type {
    border-top: none; }
  .bp3-menu-header > h6 {
    color: #182026;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    line-height: 17px;
    margin: 0;
    padding: 10px 7px 0 1px; }
    .bp3-dark .bp3-menu-header > h6 {
      color: #3c3c3b; }
  .bp3-menu-header:first-of-type > h6 {
    padding-top: 0; }
  .bp3-large .bp3-menu-header > h6 {
    font-size: 18px;
    padding-bottom: 5px;
    padding-top: 15px; }
  .bp3-large .bp3-menu-header:first-of-type > h6 {
    padding-top: 0; }

.bp3-dark .bp3-menu {
  background: #dadada;
  color: #3c3c3b; }

.bp3-dark .bp3-menu-item.bp3-intent-primary {
  color: #ffffff; }
  .bp3-dark .bp3-menu-item.bp3-intent-primary .bp3-icon {
    color: inherit; }
  .bp3-dark .bp3-menu-item.bp3-intent-primary::before, .bp3-dark .bp3-menu-item.bp3-intent-primary::after,
  .bp3-dark .bp3-menu-item.bp3-intent-primary .bp3-menu-item-label {
    color: #ffffff; }
  .bp3-dark .bp3-menu-item.bp3-intent-primary:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-primary.bp3-active {
    background-color: #e94e1b; }
  .bp3-dark .bp3-menu-item.bp3-intent-primary:active {
    background-color: #e94e1b; }
  .bp3-dark .bp3-menu-item.bp3-intent-primary:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-primary:hover::before, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item::before, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item::before, .bp3-dark .bp3-menu-item.bp3-intent-primary:hover::after, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item::after, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item::after,
  .bp3-dark .bp3-menu-item.bp3-intent-primary:hover .bp3-menu-item-label,
  .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item .bp3-menu-item-label,
  .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-primary:active, .bp3-dark .bp3-menu-item.bp3-intent-primary:active::before, .bp3-dark .bp3-menu-item.bp3-intent-primary:active::after,
  .bp3-dark .bp3-menu-item.bp3-intent-primary:active .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-primary.bp3-active, .bp3-dark .bp3-menu-item.bp3-intent-primary.bp3-active::before, .bp3-dark .bp3-menu-item.bp3-intent-primary.bp3-active::after,
  .bp3-dark .bp3-menu-item.bp3-intent-primary.bp3-active .bp3-menu-item-label {
    color: #ffffff; }

.bp3-dark .bp3-menu-item.bp3-intent-success {
  color: #3dcc91; }
  .bp3-dark .bp3-menu-item.bp3-intent-success .bp3-icon {
    color: inherit; }
  .bp3-dark .bp3-menu-item.bp3-intent-success::before, .bp3-dark .bp3-menu-item.bp3-intent-success::after,
  .bp3-dark .bp3-menu-item.bp3-intent-success .bp3-menu-item-label {
    color: #3dcc91; }
  .bp3-dark .bp3-menu-item.bp3-intent-success:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-success.bp3-active {
    background-color: #0f9960; }
  .bp3-dark .bp3-menu-item.bp3-intent-success:active {
    background-color: #0d8050; }
  .bp3-dark .bp3-menu-item.bp3-intent-success:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-success:hover::before, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item::before, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item::before, .bp3-dark .bp3-menu-item.bp3-intent-success:hover::after, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item::after, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item::after,
  .bp3-dark .bp3-menu-item.bp3-intent-success:hover .bp3-menu-item-label,
  .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item .bp3-menu-item-label,
  .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-success.bp3-menu-item .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-success:active, .bp3-dark .bp3-menu-item.bp3-intent-success:active::before, .bp3-dark .bp3-menu-item.bp3-intent-success:active::after,
  .bp3-dark .bp3-menu-item.bp3-intent-success:active .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-success.bp3-active, .bp3-dark .bp3-menu-item.bp3-intent-success.bp3-active::before, .bp3-dark .bp3-menu-item.bp3-intent-success.bp3-active::after,
  .bp3-dark .bp3-menu-item.bp3-intent-success.bp3-active .bp3-menu-item-label {
    color: #ffffff; }

.bp3-dark .bp3-menu-item.bp3-intent-warning {
  color: #ffb366; }
  .bp3-dark .bp3-menu-item.bp3-intent-warning .bp3-icon {
    color: inherit; }
  .bp3-dark .bp3-menu-item.bp3-intent-warning::before, .bp3-dark .bp3-menu-item.bp3-intent-warning::after,
  .bp3-dark .bp3-menu-item.bp3-intent-warning .bp3-menu-item-label {
    color: #ffb366; }
  .bp3-dark .bp3-menu-item.bp3-intent-warning:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-warning.bp3-active {
    background-color: #d9822b; }
  .bp3-dark .bp3-menu-item.bp3-intent-warning:active {
    background-color: #bf7326; }
  .bp3-dark .bp3-menu-item.bp3-intent-warning:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-warning:hover::before, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item::before, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item::before, .bp3-dark .bp3-menu-item.bp3-intent-warning:hover::after, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item::after, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item::after,
  .bp3-dark .bp3-menu-item.bp3-intent-warning:hover .bp3-menu-item-label,
  .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item .bp3-menu-item-label,
  .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-warning.bp3-menu-item .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-warning:active, .bp3-dark .bp3-menu-item.bp3-intent-warning:active::before, .bp3-dark .bp3-menu-item.bp3-intent-warning:active::after,
  .bp3-dark .bp3-menu-item.bp3-intent-warning:active .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-warning.bp3-active, .bp3-dark .bp3-menu-item.bp3-intent-warning.bp3-active::before, .bp3-dark .bp3-menu-item.bp3-intent-warning.bp3-active::after,
  .bp3-dark .bp3-menu-item.bp3-intent-warning.bp3-active .bp3-menu-item-label {
    color: #ffffff; }

.bp3-dark .bp3-menu-item.bp3-intent-danger {
  color: #ff7373; }
  .bp3-dark .bp3-menu-item.bp3-intent-danger .bp3-icon {
    color: inherit; }
  .bp3-dark .bp3-menu-item.bp3-intent-danger::before, .bp3-dark .bp3-menu-item.bp3-intent-danger::after,
  .bp3-dark .bp3-menu-item.bp3-intent-danger .bp3-menu-item-label {
    color: #ff7373; }
  .bp3-dark .bp3-menu-item.bp3-intent-danger:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-danger.bp3-active {
    background-color: #db3737; }
  .bp3-dark .bp3-menu-item.bp3-intent-danger:active {
    background-color: #c23030; }
  .bp3-dark .bp3-menu-item.bp3-intent-danger:hover, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item, .bp3-dark .bp3-menu-item.bp3-intent-danger:hover::before, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item::before, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item::before, .bp3-dark .bp3-menu-item.bp3-intent-danger:hover::after, .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item::after, .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item::after,
  .bp3-dark .bp3-menu-item.bp3-intent-danger:hover .bp3-menu-item-label,
  .bp3-dark .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item .bp3-menu-item-label,
  .bp3-submenu .bp3-dark .bp3-popover-target.bp3-popover-open > .bp3-intent-danger.bp3-menu-item .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-danger:active, .bp3-dark .bp3-menu-item.bp3-intent-danger:active::before, .bp3-dark .bp3-menu-item.bp3-intent-danger:active::after,
  .bp3-dark .bp3-menu-item.bp3-intent-danger:active .bp3-menu-item-label, .bp3-dark .bp3-menu-item.bp3-intent-danger.bp3-active, .bp3-dark .bp3-menu-item.bp3-intent-danger.bp3-active::before, .bp3-dark .bp3-menu-item.bp3-intent-danger.bp3-active::after,
  .bp3-dark .bp3-menu-item.bp3-intent-danger.bp3-active .bp3-menu-item-label {
    color: #ffffff; }

.bp3-dark .bp3-menu-item::before,
.bp3-dark .bp3-menu-item > .bp3-icon {
  color: #e94e1b; }

.bp3-dark .bp3-menu-item .bp3-menu-item-label {
  color: #e94e1b; }

.bp3-dark .bp3-menu-item.bp3-active, .bp3-dark .bp3-menu-item:active {
  background-color: rgba(233, 78, 27, 0.3); }

.bp3-dark .bp3-menu-item.bp3-disabled {
  color: rgba(233, 78, 27, 0.6) !important; }
  .bp3-dark .bp3-menu-item.bp3-disabled::before,
  .bp3-dark .bp3-menu-item.bp3-disabled > .bp3-icon,
  .bp3-dark .bp3-menu-item.bp3-disabled .bp3-menu-item-label {
    color: rgba(233, 78, 27, 0.6) !important; }

.bp3-dark .bp3-menu-divider,
.bp3-dark .bp3-menu-header {
  border-color: rgba(255, 255, 255, 0.15); }

.bp3-dark .bp3-menu-header > h6 {
  color: #3c3c3b; }

.bp3-label .bp3-menu {
  margin-top: 5px; }

/*
Navbars

Markup:
<nav class="bp3-navbar {{.modifier}}">
  <div class="bp3-navbar-group bp3-align-left">
    <div class="bp3-navbar-heading">Blueprint</div>
    <input class="bp3-input" placeholder="Search files..." type="text" />
  </div>
  <div class="bp3-navbar-group bp3-align-right">
    <button class="bp3-button bp3-minimal bp3-icon-home">Home</button>
    <button class="bp3-button bp3-minimal bp3-icon-document">Files</button>
    <span class="bp3-navbar-divider"></span>
    <button class="bp3-button bp3-minimal bp3-icon-user"></button>
    <button class="bp3-button bp3-minimal bp3-icon-notifications"></button>
    <button class="bp3-button bp3-minimal bp3-icon-cog"></button>
  </div>
</nav>

.bp3-dark - Dark theme

Styleguide navbar
*/
.bp3-navbar {
  background-color: #ffffff;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2);
  height: 50px;
  padding: 0 15px;
  position: relative;
  width: 100%;
  z-index: 10; }
  .bp3-navbar.bp3-dark, .bp3-dark .bp3-navbar {
    background-color: #ffffff; }
  .bp3-navbar.bp3-dark {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4); }
  .bp3-dark .bp3-navbar {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4); }
  .bp3-navbar.bp3-fixed-top {
    left: 0;
    position: fixed;
    right: 0;
    top: 0; }

.bp3-navbar-heading {
  font-size: 16px;
  margin-right: 15px; }

.bp3-navbar-group {
  align-items: center;
  display: flex;
  height: 50px; }
  .bp3-navbar-group.bp3-align-left {
    float: left; }
  .bp3-navbar-group.bp3-align-right {
    float: right; }

.bp3-navbar-divider {
  border-left: 1px solid rgba(16, 22, 26, 0.15);
  height: 20px;
  margin: 0 10px; }
  .bp3-dark .bp3-navbar-divider {
    border-left-color: rgba(255, 255, 255, 0.15); }

/*
Fixed width

Markup:
<nav class="bp3-navbar bp3-dark">
  <div style="margin: 0 auto; width: 480px;"> <!-- ADD ME -->
    <div class="bp3-navbar-group bp3-align-left">
      <div class="bp3-navbar-heading">Blueprint</div>
    </div>
    <div class="bp3-navbar-group bp3-align-right">
      <button class="bp3-button bp3-minimal bp3-icon-home">Home</button>
      <button class="bp3-button bp3-minimal bp3-icon-document">Files</button>
      <span class="bp3-navbar-divider"></span>
      <button class="bp3-button bp3-minimal bp3-icon-user"></button>
      <button class="bp3-button bp3-minimal bp3-icon-notifications"></button>
      <button class="bp3-button bp3-minimal bp3-icon-cog"></button>
    </div>
  </div>
</nav>

Styleguide navbar-container
*/
/*
Non-ideal state

Markup:
<div class="bp3-non-ideal-state">
  <div class="bp3-non-ideal-state-visual">
    <span class="bp3-icon bp3-icon-folder-open"></span>
  </div>
  <h4 class="bp3-heading">This folder is empty</h4>
  <div>Create a new file to populate the folder.</div>
  <button class="bp3-button bp3-intent-primary">Create</button>
</div>

Styleguide non-ideal-state
*/
.bp3-non-ideal-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%; }
  .bp3-non-ideal-state > * {
    flex-grow: 0;
    flex-shrink: 0; }
  .bp3-non-ideal-state > .bp3-fill {
    flex-grow: 1;
    flex-shrink: 1; }
  .bp3-non-ideal-state::before,
  .bp3-non-ideal-state > * {
    margin-bottom: 20px; }
  .bp3-non-ideal-state:empty::before,
  .bp3-non-ideal-state > :last-child {
    margin-bottom: 0; }
  .bp3-non-ideal-state > * {
    max-width: 400px; }

.bp3-non-ideal-state-visual {
  color: rgba(255, 255, 255, 0.6);
  font-size: 60px; }
  .bp3-dark .bp3-non-ideal-state-visual {
    color: rgba(233, 78, 27, 0.6); }

.bp3-overflow-list {
  display: flex;
  flex-wrap: nowrap;
  min-width: 0; }

.bp3-overflow-list-spacer {
  flex-shrink: 1;
  width: 1px; }

body.bp3-overlay-open {
  overflow: hidden; }

.bp3-overlay {
  bottom: 0;
  left: 0;
  position: static;
  right: 0;
  top: 0;
  z-index: 20; }
  .bp3-overlay:not(.bp3-overlay-open) {
    pointer-events: none; }
  .bp3-overlay.bp3-overlay-container {
    overflow: hidden;
    position: fixed; }
    .bp3-overlay.bp3-overlay-container.bp3-overlay-inline {
      position: absolute; }
  .bp3-overlay.bp3-overlay-scroll-container {
    overflow: auto;
    position: fixed; }
    .bp3-overlay.bp3-overlay-scroll-container.bp3-overlay-inline {
      position: absolute; }
  .bp3-overlay.bp3-overlay-inline {
    display: inline;
    overflow: visible; }

.bp3-overlay-content {
  position: fixed;
  z-index: 20; }
  .bp3-overlay-inline .bp3-overlay-content,
  .bp3-overlay-scroll-container .bp3-overlay-content {
    position: absolute; }

.bp3-overlay-backdrop {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  opacity: 1;
  background-color: rgba(16, 22, 26, 0.7);
  overflow: auto;
  user-select: none;
  z-index: 20; }
  .bp3-overlay-backdrop.bp3-overlay-enter, .bp3-overlay-backdrop.bp3-overlay-appear {
    opacity: 0; }
  .bp3-overlay-backdrop.bp3-overlay-enter-active, .bp3-overlay-backdrop.bp3-overlay-appear-active {
    opacity: 1;
    transition-delay: 0;
    transition-duration: 200ms;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-overlay-backdrop.bp3-overlay-exit {
    opacity: 1; }
  .bp3-overlay-backdrop.bp3-overlay-exit-active {
    opacity: 0;
    transition-delay: 0;
    transition-duration: 200ms;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-overlay-backdrop:focus {
    outline: none; }
  .bp3-overlay-inline .bp3-overlay-backdrop {
    position: absolute; }

/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.bp3-panel-stack {
  overflow: hidden;
  position: relative; }

.bp3-panel-stack-header {
  align-items: center;
  box-shadow: 0 1px rgba(16, 22, 26, 0.15);
  display: flex;
  flex-shrink: 0;
  height: 30px;
  z-index: 1; }
  .bp3-dark .bp3-panel-stack-header {
    box-shadow: 0 1px rgba(255, 255, 255, 0.15); }
  .bp3-panel-stack-header > span {
    align-items: stretch;
    display: flex;
    flex: 1; }
  .bp3-panel-stack-header .bp3-heading {
    margin: 0 5px; }

.bp3-button.bp3-panel-stack-header-back {
  margin-left: 5px;
  padding-left: 0;
  white-space: nowrap; }
  .bp3-button.bp3-panel-stack-header-back .bp3-icon {
    margin: 0 2px; }

.bp3-panel-stack-view {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #ffffff;
  border-right: 1px solid rgba(16, 22, 26, 0.15);
  display: flex;
  flex-direction: column;
  margin-right: -1px;
  overflow-y: auto;
  z-index: 1; }
  .bp3-dark .bp3-panel-stack-view {
    background-color: #dadada; }
  .bp3-panel-stack-view:nth-last-child(n + 4) {
    display: none; }

.bp3-panel-stack-push .bp3-panel-stack-enter, .bp3-panel-stack-push .bp3-panel-stack-appear {
  transform: translateX(100%);
  opacity: 0; }

.bp3-panel-stack-push .bp3-panel-stack-enter-active, .bp3-panel-stack-push .bp3-panel-stack-appear-active {
  transform: translate(0%);
  opacity: 1;
  transition-delay: 0;
  transition-duration: 400ms;
  transition-property: transform, opacity;
  transition-timing-function: ease; }

.bp3-panel-stack-push .bp3-panel-stack-exit {
  transform: translate(0%);
  opacity: 1; }

.bp3-panel-stack-push .bp3-panel-stack-exit-active {
  transform: translateX(-50%);
  opacity: 0;
  transition-delay: 0;
  transition-duration: 400ms;
  transition-property: transform, opacity;
  transition-timing-function: ease; }

.bp3-panel-stack-pop .bp3-panel-stack-enter, .bp3-panel-stack-pop .bp3-panel-stack-appear {
  transform: translateX(-50%);
  opacity: 0; }

.bp3-panel-stack-pop .bp3-panel-stack-enter-active, .bp3-panel-stack-pop .bp3-panel-stack-appear-active {
  transform: translate(0%);
  opacity: 1;
  transition-delay: 0;
  transition-duration: 400ms;
  transition-property: transform, opacity;
  transition-timing-function: ease; }

.bp3-panel-stack-pop .bp3-panel-stack-exit {
  transform: translate(0%);
  opacity: 1; }

.bp3-panel-stack-pop .bp3-panel-stack-exit-active {
  transform: translateX(100%);
  opacity: 0;
  transition-delay: 0;
  transition-duration: 400ms;
  transition-property: transform, opacity;
  transition-timing-function: ease; }

/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.bp3-popover {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  transform: scale(1);
  border-radius: 3px;
  display: inline-block;
  z-index: 20; }
  .bp3-popover .bp3-popover-arrow {
    height: 30px;
    position: absolute;
    width: 30px; }
    .bp3-popover .bp3-popover-arrow::before {
      height: 20px;
      margin: 5px;
      width: 20px; }
  .bp3-tether-element-attached-bottom.bp3-tether-target-attached-top > .bp3-popover {
    margin-bottom: 17px;
    margin-top: -17px; }
    .bp3-tether-element-attached-bottom.bp3-tether-target-attached-top > .bp3-popover > .bp3-popover-arrow {
      bottom: -11px; }
      .bp3-tether-element-attached-bottom.bp3-tether-target-attached-top > .bp3-popover > .bp3-popover-arrow svg {
        transform: rotate(-90deg); }
  .bp3-tether-element-attached-left.bp3-tether-target-attached-right > .bp3-popover {
    margin-left: 17px; }
    .bp3-tether-element-attached-left.bp3-tether-target-attached-right > .bp3-popover > .bp3-popover-arrow {
      left: -11px; }
      .bp3-tether-element-attached-left.bp3-tether-target-attached-right > .bp3-popover > .bp3-popover-arrow svg {
        transform: rotate(0); }
  .bp3-tether-element-attached-top.bp3-tether-target-attached-bottom > .bp3-popover {
    margin-top: 17px; }
    .bp3-tether-element-attached-top.bp3-tether-target-attached-bottom > .bp3-popover > .bp3-popover-arrow {
      top: -11px; }
      .bp3-tether-element-attached-top.bp3-tether-target-attached-bottom > .bp3-popover > .bp3-popover-arrow svg {
        transform: rotate(90deg); }
  .bp3-tether-element-attached-right.bp3-tether-target-attached-left > .bp3-popover {
    margin-left: -17px;
    margin-right: 17px; }
    .bp3-tether-element-attached-right.bp3-tether-target-attached-left > .bp3-popover > .bp3-popover-arrow {
      right: -11px; }
      .bp3-tether-element-attached-right.bp3-tether-target-attached-left > .bp3-popover > .bp3-popover-arrow svg {
        transform: rotate(180deg); }
  .bp3-tether-element-attached-middle > .bp3-popover > .bp3-popover-arrow {
    top: 50%;
    transform: translateY(-50%); }
  .bp3-tether-element-attached-center > .bp3-popover > .bp3-popover-arrow {
    right: 50%;
    transform: translateX(50%); }
  .bp3-tether-element-attached-top.bp3-tether-target-attached-top > .bp3-popover > .bp3-popover-arrow {
    top: -0.3934px; }
  .bp3-tether-element-attached-right.bp3-tether-target-attached-right > .bp3-popover > .bp3-popover-arrow {
    right: -0.3934px; }
  .bp3-tether-element-attached-left.bp3-tether-target-attached-left > .bp3-popover > .bp3-popover-arrow {
    left: -0.3934px; }
  .bp3-tether-element-attached-bottom.bp3-tether-target-attached-bottom > .bp3-popover > .bp3-popover-arrow {
    bottom: -0.3934px; }
  .bp3-tether-element-attached-top.bp3-tether-element-attached-left > .bp3-popover {
    transform-origin: top left; }
  .bp3-tether-element-attached-top.bp3-tether-element-attached-center > .bp3-popover {
    transform-origin: top center; }
  .bp3-tether-element-attached-top.bp3-tether-element-attached-right > .bp3-popover {
    transform-origin: top right; }
  .bp3-tether-element-attached-middle.bp3-tether-element-attached-left > .bp3-popover {
    transform-origin: center left; }
  .bp3-tether-element-attached-middle.bp3-tether-element-attached-center > .bp3-popover {
    transform-origin: center center; }
  .bp3-tether-element-attached-middle.bp3-tether-element-attached-right > .bp3-popover {
    transform-origin: center right; }
  .bp3-tether-element-attached-bottom.bp3-tether-element-attached-left > .bp3-popover {
    transform-origin: bottom left; }
  .bp3-tether-element-attached-bottom.bp3-tether-element-attached-center > .bp3-popover {
    transform-origin: bottom center; }
  .bp3-tether-element-attached-bottom.bp3-tether-element-attached-right > .bp3-popover {
    transform-origin: bottom right; }
  .bp3-popover .bp3-popover-content {
    background: #ffffff;
    color: inherit; }
  .bp3-popover .bp3-popover-arrow::before {
    box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.2); }
  .bp3-popover .bp3-popover-arrow-border {
    fill: #10161a;
    fill-opacity: 0.1; }
  .bp3-popover .bp3-popover-arrow-fill {
    fill: #ffffff; }
  .bp3-popover-enter > .bp3-popover, .bp3-popover-appear > .bp3-popover {
    transform: scale(0.3); }
  .bp3-popover-enter-active > .bp3-popover, .bp3-popover-appear-active > .bp3-popover {
    transform: scale(1);
    transition-delay: 0;
    transition-duration: 300ms;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11); }
  .bp3-popover-exit > .bp3-popover {
    transform: scale(1); }
  .bp3-popover-exit-active > .bp3-popover {
    transform: scale(0.3);
    transition-delay: 0;
    transition-duration: 300ms;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11); }
  .bp3-popover .bp3-popover-content {
    border-radius: 3px;
    position: relative; }
  .bp3-popover.bp3-popover-content-sizing .bp3-popover-content {
    max-width: 350px;
    padding: 20px; }
  .bp3-popover-target + .bp3-overlay .bp3-popover.bp3-popover-content-sizing {
    width: 350px; }
  .bp3-popover.bp3-minimal {
    margin: 0 !important; }
    .bp3-popover.bp3-minimal .bp3-popover-arrow {
      display: none; }
    .bp3-popover.bp3-minimal.bp3-popover {
      transform: scale(1); }
      .bp3-popover-enter > .bp3-popover.bp3-minimal.bp3-popover, .bp3-popover-appear > .bp3-popover.bp3-minimal.bp3-popover {
        transform: scale(1); }
      .bp3-popover-enter-active > .bp3-popover.bp3-minimal.bp3-popover, .bp3-popover-appear-active > .bp3-popover.bp3-minimal.bp3-popover {
        transform: scale(1);
        transition-delay: 0;
        transition-duration: 100ms;
        transition-property: transform;
        transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
      .bp3-popover-exit > .bp3-popover.bp3-minimal.bp3-popover {
        transform: scale(1); }
      .bp3-popover-exit-active > .bp3-popover.bp3-minimal.bp3-popover {
        transform: scale(1);
        transition-delay: 0;
        transition-duration: 100ms;
        transition-property: transform;
        transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-popover.bp3-dark,
  .bp3-dark .bp3-popover {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4); }
    .bp3-popover.bp3-dark .bp3-popover-content,
    .bp3-dark .bp3-popover .bp3-popover-content {
      background: #dadada;
      color: inherit; }
    .bp3-popover.bp3-dark .bp3-popover-arrow::before,
    .bp3-dark .bp3-popover .bp3-popover-arrow::before {
      box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.4); }
    .bp3-popover.bp3-dark .bp3-popover-arrow-border,
    .bp3-dark .bp3-popover .bp3-popover-arrow-border {
      fill: #10161a;
      fill-opacity: 0.2; }
    .bp3-popover.bp3-dark .bp3-popover-arrow-fill,
    .bp3-dark .bp3-popover .bp3-popover-arrow-fill {
      fill: #dadada; }

.bp3-popover-arrow::before {
  border-radius: 2px;
  content: "";
  display: block;
  position: absolute;
  transform: rotate(45deg); }

.bp3-tether-pinned .bp3-popover-arrow {
  display: none; }

.bp3-popover-backdrop {
  background: rgba(255, 255, 255, 0); }

.bp3-transition-container {
  opacity: 1;
  display: flex;
  z-index: 20; }
  .bp3-transition-container.bp3-popover-enter, .bp3-transition-container.bp3-popover-appear {
    opacity: 0; }
  .bp3-transition-container.bp3-popover-enter-active, .bp3-transition-container.bp3-popover-appear-active {
    opacity: 1;
    transition-delay: 0;
    transition-duration: 100ms;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-transition-container.bp3-popover-exit {
    opacity: 1; }
  .bp3-transition-container.bp3-popover-exit-active {
    opacity: 0;
    transition-delay: 0;
    transition-duration: 100ms;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-transition-container:focus {
    outline: none; }
  .bp3-transition-container.bp3-popover-leave .bp3-popover-content {
    pointer-events: none; }
  .bp3-transition-container[data-x-out-of-boundaries] {
    display: none; }

span.bp3-popover-target {
  display: inline-block; }

.bp3-popover-wrapper.bp3-fill {
  width: 100%; }

.bp3-portal {
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

/*
Progress bars

Markup:
<div class="bp3-progress-bar {{.modifier}}">
  <div class="bp3-progress-meter" style="width: 25%"></div>
</div>
<div class="bp3-progress-bar bp3-intent-primary {{.modifier}}">
  <div class="bp3-progress-meter" style="width: 50%"></div>
</div>
<div class="bp3-progress-bar bp3-intent-success {{.modifier}}">
  <div class="bp3-progress-meter" style="width: 75%"></div>
</div>
<div class="bp3-progress-bar bp3-intent-danger {{.modifier}}">
  <div class="bp3-progress-meter" style="width: 100%"></div>
</div>

.bp3-no-stripes   - No stripes
.bp3-no-animation - No animation

Styleguide progress-bar
*/
@keyframes linear-progress-bar-stripes {
  from {
    background-position: 0 0; }
  to {
    background-position: 30px 0; } }

.bp3-progress-bar {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 40px;
  display: block;
  height: 8px;
  overflow: hidden;
  position: relative;
  width: 100%; }
  .bp3-progress-bar .bp3-progress-meter {
    background: linear-gradient(-45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%);
    background-color: rgba(255, 255, 255, 0.8);
    background-size: 30px 30px;
    border-radius: 40px;
    height: 100%;
    position: absolute;
    transition: width 200ms cubic-bezier(0.4, 1, 0.75, 0.9);
    width: 100%; }
  .bp3-progress-bar:not(.bp3-no-animation):not(.bp3-no-stripes) .bp3-progress-meter {
    animation: linear-progress-bar-stripes 300ms linear infinite reverse; }
  .bp3-progress-bar.bp3-no-stripes .bp3-progress-meter {
    background-image: none; }

.bp3-dark .bp3-progress-bar {
  background: rgba(16, 22, 26, 0.5); }
  .bp3-dark .bp3-progress-bar .bp3-progress-meter {
    background-color: #e94e1b; }

.bp3-progress-bar.bp3-intent-primary .bp3-progress-meter {
  background-color: #e94e1b; }

.bp3-progress-bar.bp3-intent-success .bp3-progress-meter {
  background-color: #0f9960; }

.bp3-progress-bar.bp3-intent-warning .bp3-progress-meter {
  background-color: #d9822b; }

.bp3-progress-bar.bp3-intent-danger .bp3-progress-meter {
  background-color: #db3737; }

/*
Skeletons

Markup:
<div class="bp3-card">
  <h5 class="bp3-heading"><a class="{{.modifier}}" href="#" tabindex="-1">Card heading</a></h5>
  <p class="{{.modifier}}">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eget tortor felis.
    Fusce dapibus metus in dapibus mollis. Quisque eget ex diam.
  </p>
  <button type="button" class="bp3-button bp3-icon-add {{.modifier}}" tabindex="-1">Submit</button>
</div>

.bp3-skeleton - Render this element as a skeleton, an outline of its true self.

Styleguide skeleton
*/
@keyframes skeleton-glow {
  from {
    background: rgba(255, 255, 255, 0.2);
    border-color: rgba(255, 255, 255, 0.2); }
  to {
    background: rgba(255, 255, 255, 0.2);
    border-color: rgba(255, 255, 255, 0.2); } }

/* stylelint-disable declaration-no-important */
.bp3-skeleton {
  animation: 1000ms linear infinite alternate skeleton-glow;
  background: rgba(255, 255, 255, 0.2);
  background-clip: padding-box !important;
  border-color: rgba(255, 255, 255, 0.2) !important;
  border-radius: 2px;
  box-shadow: none !important;
  color: transparent !important;
  cursor: default;
  pointer-events: none;
  user-select: none; }
  .bp3-skeleton::before, .bp3-skeleton::after,
  .bp3-skeleton * {
    visibility: hidden !important; }

/* stylelint-enable declaration-no-important */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.bp3-slider {
  height: 40px;
  min-width: 150px;
  width: 100%;
  cursor: default;
  outline: none;
  position: relative;
  user-select: none; }
  .bp3-slider:hover {
    cursor: pointer; }
  .bp3-slider:active {
    cursor: grabbing; }
  .bp3-slider.bp3-disabled {
    cursor: not-allowed;
    opacity: 0.5; }
  .bp3-slider.bp3-slider-unlabeled {
    height: 16px; }

.bp3-slider-track,
.bp3-slider-progress {
  height: 6px;
  left: 0;
  right: 0;
  top: 5px;
  position: absolute; }

.bp3-slider-track {
  border-radius: 3px;
  overflow: hidden; }

.bp3-slider-progress {
  background: rgba(255, 255, 255, 0.2); }
  .bp3-dark .bp3-slider-progress {
    background: rgba(16, 22, 26, 0.5); }
  .bp3-slider-progress.bp3-intent-primary {
    background-color: #e94e1b; }
  .bp3-slider-progress.bp3-intent-success {
    background-color: #0f9960; }
  .bp3-slider-progress.bp3-intent-warning {
    background-color: #d9822b; }
  .bp3-slider-progress.bp3-intent-danger {
    background-color: #db3737; }

.bp3-slider-handle {
  background-color: #3c3c3b;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  color: #182026;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.2);
  cursor: pointer;
  height: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 16px; }
  .bp3-slider-handle:hover {
    background-clip: padding-box;
    background-color: #e94e1b;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1); }
  .bp3-slider-handle:active, .bp3-slider-handle.bp3-active {
    background-color: #e94e1b;
    background-image: none;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
  .bp3-slider-handle:disabled, .bp3-slider-handle.bp3-disabled {
    background-color: rgba(255, 255, 255, 0.5);
    background-image: none;
    box-shadow: none;
    color: rgba(255, 255, 255, 0.6);
    cursor: not-allowed;
    outline: none; }
    .bp3-slider-handle:disabled.bp3-active, .bp3-slider-handle:disabled.bp3-active:hover, .bp3-slider-handle.bp3-disabled.bp3-active, .bp3-slider-handle.bp3-disabled.bp3-active:hover {
      background: rgba(255, 255, 255, 0.7); }
  .bp3-slider-handle:focus {
    z-index: 1; }
  .bp3-slider-handle:hover {
    background-clip: padding-box;
    background-color: #e94e1b;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.2);
    cursor: grab;
    z-index: 2; }
  .bp3-slider-handle.bp3-active {
    background-color: #e94e1b;
    background-image: none;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 2px rgba(16, 22, 26, 0.2);
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 1px 1px rgba(16, 22, 26, 0.1);
    cursor: grabbing; }
  .bp3-disabled .bp3-slider-handle {
    background: #ffffff;
    box-shadow: none;
    pointer-events: none; }
  .bp3-dark .bp3-slider-handle {
    background-color: #ffffff;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
    color: #3c3c3b; }
    .bp3-dark .bp3-slider-handle:hover, .bp3-dark .bp3-slider-handle:active, .bp3-dark .bp3-slider-handle.bp3-active {
      color: #3c3c3b; }
    .bp3-dark .bp3-slider-handle:hover {
      background-color: #dadada;
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-slider-handle:active, .bp3-dark .bp3-slider-handle.bp3-active {
      background-color: #ffffff;
      background-image: none;
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.6), inset 0 1px 2px rgba(16, 22, 26, 0.2); }
    .bp3-dark .bp3-slider-handle:disabled, .bp3-dark .bp3-slider-handle.bp3-disabled {
      background-color: rgba(255, 255, 255, 0.5);
      background-image: none;
      box-shadow: none;
      color: rgba(233, 78, 27, 0.6); }
      .bp3-dark .bp3-slider-handle:disabled.bp3-active, .bp3-dark .bp3-slider-handle.bp3-disabled.bp3-active {
        background: rgba(255, 255, 255, 0.7); }
    .bp3-dark .bp3-slider-handle .bp3-button-spinner .bp3-spinner-head {
      background: rgba(16, 22, 26, 0.5);
      stroke: #e94e1b; }
    .bp3-dark .bp3-slider-handle, .bp3-dark .bp3-slider-handle:hover {
      background-color: #ffffff; }
    .bp3-dark .bp3-slider-handle.bp3-active {
      background-color: #e94e1b; }
  .bp3-dark .bp3-disabled .bp3-slider-handle {
    background: #ffffff;
    border-color: #ffffff;
    box-shadow: none; }
  .bp3-slider-handle .bp3-slider-label {
    background: #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
    color: #3c3c3b;
    margin-left: 8px; }
    .bp3-dark .bp3-slider-handle .bp3-slider-label {
      background: #3c3c3b;
      box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4);
      color: #ffffff; }
    .bp3-disabled .bp3-slider-handle .bp3-slider-label {
      box-shadow: none; }
  .bp3-slider-handle.bp3-start, .bp3-slider-handle.bp3-end {
    width: 8px; }
  .bp3-slider-handle.bp3-start {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .bp3-slider-handle.bp3-end {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: 8px; }
    .bp3-slider-handle.bp3-end .bp3-slider-label {
      margin-left: 0; }

.bp3-slider-label {
  transform: translate(-50%, 20px);
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  padding: 2px 5px;
  position: absolute;
  vertical-align: top; }

.bp3-slider.bp3-vertical {
  height: 150px;
  min-width: 40px;
  width: 40px; }
  .bp3-slider.bp3-vertical .bp3-slider-track,
  .bp3-slider.bp3-vertical .bp3-slider-progress {
    bottom: 0;
    height: auto;
    left: 5px;
    top: 0;
    width: 6px; }
  .bp3-slider.bp3-vertical .bp3-slider-progress {
    top: auto; }
  .bp3-slider.bp3-vertical .bp3-slider-label {
    transform: translate(20px, 50%); }
  .bp3-slider.bp3-vertical .bp3-slider-handle {
    top: auto; }
    .bp3-slider.bp3-vertical .bp3-slider-handle .bp3-slider-label {
      margin-left: 0;
      margin-top: -8px; }
    .bp3-slider.bp3-vertical .bp3-slider-handle.bp3-end, .bp3-slider.bp3-vertical .bp3-slider-handle.bp3-start {
      height: 8px;
      margin-left: 0;
      width: 16px; }
    .bp3-slider.bp3-vertical .bp3-slider-handle.bp3-start {
      border-bottom-right-radius: 3px;
      border-top-left-radius: 0; }
      .bp3-slider.bp3-vertical .bp3-slider-handle.bp3-start .bp3-slider-label {
        transform: translate(20px); }
    .bp3-slider.bp3-vertical .bp3-slider-handle.bp3-end {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 3px;
      margin-bottom: 8px; }

@keyframes pt-spinner-animation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.bp3-spinner {
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: visible;
  vertical-align: middle; }
  .bp3-spinner svg {
    display: block; }
  .bp3-spinner path {
    fill-opacity: 0; }
  .bp3-spinner .bp3-spinner-head {
    stroke: rgba(255, 255, 255, 0.8);
    stroke-linecap: round;
    transform-origin: center;
    transition: stroke-dashoffset 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-spinner .bp3-spinner-track {
    stroke: rgba(255, 255, 255, 0.2); }

.bp3-spinner-animation {
  animation: pt-spinner-animation 500ms linear infinite; }
  .bp3-no-spin > .bp3-spinner-animation {
    animation: none; }

.bp3-dark .bp3-spinner .bp3-spinner-head {
  stroke: #e94e1b; }

.bp3-dark .bp3-spinner .bp3-spinner-track {
  stroke: rgba(16, 22, 26, 0.5); }

.bp3-spinner.bp3-intent-primary .bp3-spinner-head {
  stroke: #e94e1b; }

.bp3-spinner.bp3-intent-success .bp3-spinner-head {
  stroke: #0f9960; }

.bp3-spinner.bp3-intent-warning .bp3-spinner-head {
  stroke: #d9822b; }

.bp3-spinner.bp3-intent-danger .bp3-spinner-head {
  stroke: #db3737; }

/*
Tabs

Markup:
<div class="bp3-tabs">
    <ul class="bp3-tab-list {{.modifier}}" role="tablist">
        <li class="bp3-tab" role="tab" aria-selected="true">Selected tab</li>
        <li class="bp3-tab" role="tab">Another tab</li>
        <li class="bp3-tab" role="tab" aria-disabled="true">Disabled tab</li>
    </ul>
    <div class="bp3-tab-panel" role="tabpanel">Selected panel</div>
    <div class="bp3-tab-panel" role="tabpanel" aria-hidden="true">Another panel</div>
    <div class="bp3-tab-panel" role="tabpanel" aria-hidden="true">Disabled panel</div>
</div>

.bp3-large - Large tabs

Styleguide tabs
*/
.bp3-tabs.bp3-vertical {
  display: flex; }
  .bp3-tabs.bp3-vertical > .bp3-tab-list {
    align-items: flex-start;
    flex-direction: column; }
    .bp3-tabs.bp3-vertical > .bp3-tab-list .bp3-tab {
      border-radius: 3px;
      padding: 0 10px;
      width: 100%; }
      .bp3-tabs.bp3-vertical > .bp3-tab-list .bp3-tab[aria-selected="true"] {
        background-color: rgba(233, 78, 27, 0.2);
        box-shadow: none; }
    .bp3-tabs.bp3-vertical > .bp3-tab-list .bp3-tab-indicator-wrapper .bp3-tab-indicator {
      background-color: rgba(233, 78, 27, 0.2);
      border-radius: 3px;
      bottom: 0;
      height: auto;
      left: 0;
      right: 0;
      top: 0; }
  .bp3-tabs.bp3-vertical > .bp3-tab-panel {
    margin-top: 0;
    padding-left: 20px; }

.bp3-tab-list {
  align-items: flex-end;
  border: none;
  display: flex;
  flex: 0 0 auto;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative; }
  .bp3-tab-list > *:not(:last-child) {
    margin-right: 20px; }

.bp3-tab {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  color: #182026;
  cursor: pointer;
  flex: 0 0 auto;
  font-size: 14px;
  line-height: 30px;
  max-width: 100%;
  position: relative;
  vertical-align: top; }
  .bp3-tab a {
    color: inherit;
    display: block;
    text-decoration: none; }
  .bp3-tab-indicator-wrapper ~ .bp3-tab {
    background-color: transparent !important;
    box-shadow: none !important; }
  .bp3-tab[aria-disabled="true"] {
    color: rgba(255, 255, 255, 0.6);
    cursor: not-allowed; }
  .bp3-tab[aria-selected="true"] {
    border-radius: 0;
    box-shadow: inset 0 -3px 0 #e94e1b; }
  .bp3-tab[aria-selected="true"], .bp3-tab:not([aria-disabled="true"]):hover {
    color: #e94e1b; }
  .bp3-tab:focus {
    -moz-outline-radius: 0; }
  .bp3-large > .bp3-tab {
    font-size: 16px;
    line-height: 40px; }

.bp3-tab-panel {
  margin-top: 20px; }
  .bp3-tab-panel[aria-hidden="true"] {
    display: none; }

.bp3-tab-indicator-wrapper {
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translateX(0), translateY(0);
  transition: height, transform, width;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-tab-indicator-wrapper .bp3-tab-indicator {
    background-color: #e94e1b;
    bottom: 0;
    height: 3px;
    left: 0;
    position: absolute;
    right: 0; }
  .bp3-tab-indicator-wrapper.bp3-no-animation {
    transition: none; }

.bp3-dark .bp3-tab {
  color: #3c3c3b; }
  .bp3-dark .bp3-tab[aria-disabled="true"] {
    color: rgba(233, 78, 27, 0.6); }
  .bp3-dark .bp3-tab[aria-selected="true"] {
    box-shadow: inset 0 -3px 0 #ffffff; }
  .bp3-dark .bp3-tab[aria-selected="true"], .bp3-dark .bp3-tab:not([aria-disabled="true"]):hover {
    color: #ffffff; }

.bp3-dark .bp3-tab-indicator {
  background-color: #ffffff; }

.bp3-flex-expander {
  flex: 1 1; }

/*
Tags

Markup:
<span class="bp3-tag {{.modifier}}">125</span>
<span class="bp3-tag {{.modifier}}">Done</span>
<span class="bp3-tag {{.modifier}}">
  Tracking
  <button class="bp3-tag-remove"></button>
</span>
<span class="bp3-tag {{.modifier}}">
  Crushed
  <button class="bp3-tag-remove"></button>
</span>
<span class="bp3-tag {{.modifier}}">
  A rather long string of text that wraps to multiple lines
  demonstrates the position of the remove button.
  <button class="bp3-tag-remove"></button>
</span>

.bp3-large - Large
.bp3-minimal - Minimal appearance
.bp3-round - Rounded corners, ideal for badges
.bp3-interactive - Hover and active effects
.bp3-intent-primary - Primary intent
.bp3-intent-success - Success intent
.bp3-intent-warning - Warning intent
.bp3-intent-danger  - Danger intent

Styleguide tag
*/
.bp3-tag {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  border: none;
  border-radius: 3px;
  box-shadow: none;
  color: #3c3c3b;
  font-size: 12px;
  line-height: 16px;
  max-width: 100%;
  min-height: 20px;
  min-width: 20px;
  padding: 2px 6px;
  position: relative; }
  .bp3-tag.bp3-interactive {
    cursor: pointer; }
    .bp3-tag.bp3-interactive:hover {
      background-color: rgba(255, 255, 255, 0.85); }
    .bp3-tag.bp3-interactive.bp3-active, .bp3-tag.bp3-interactive:active {
      background-color: rgba(255, 255, 255, 0.7); }
  .bp3-tag > * {
    flex-grow: 0;
    flex-shrink: 0; }
  .bp3-tag > .bp3-fill {
    flex-grow: 1;
    flex-shrink: 1; }
  .bp3-tag::before,
  .bp3-tag > * {
    margin-right: 4px; }
  .bp3-tag:empty::before,
  .bp3-tag > :last-child {
    margin-right: 0; }
  .bp3-tag:focus {
    outline: rgba(233, 78, 27, 0.6) auto 2px;
    outline-offset: 0;
    -moz-outline-radius: 6px; }
  .bp3-tag.bp3-round {
    border-radius: 30px;
    padding-left: 8px;
    padding-right: 8px; }
  .bp3-dark .bp3-tag {
    background-color: #ffffff;
    color: #182026; }
    .bp3-dark .bp3-tag.bp3-interactive {
      cursor: pointer; }
      .bp3-dark .bp3-tag.bp3-interactive:hover {
        background-color: rgba(255, 255, 255, 0.85); }
      .bp3-dark .bp3-tag.bp3-interactive.bp3-active, .bp3-dark .bp3-tag.bp3-interactive:active {
        background-color: rgba(255, 255, 255, 0.7); }
    .bp3-dark .bp3-tag > .bp3-icon, .bp3-dark .bp3-tag .bp3-icon-standard, .bp3-dark .bp3-tag .bp3-icon-large {
      fill: currentColor; }
  .bp3-tag > .bp3-icon, .bp3-tag .bp3-icon-standard, .bp3-tag .bp3-icon-large {
    fill: #ffffff; }
  .bp3-tag.bp3-large,
  .bp3-large .bp3-tag {
    font-size: 14px;
    line-height: 20px;
    min-height: 30px;
    min-width: 30px;
    padding: 5px 10px; }
    .bp3-tag.bp3-large::before,
    .bp3-tag.bp3-large > *,
    .bp3-large .bp3-tag::before,
    .bp3-large .bp3-tag > * {
      margin-right: 7px; }
    .bp3-tag.bp3-large:empty::before,
    .bp3-tag.bp3-large > :last-child,
    .bp3-large .bp3-tag:empty::before,
    .bp3-large .bp3-tag > :last-child {
      margin-right: 0; }
    .bp3-tag.bp3-large.bp3-round,
    .bp3-large .bp3-tag.bp3-round {
      padding-left: 12px;
      padding-right: 12px; }
  .bp3-tag.bp3-intent-primary {
    background: #e94e1b;
    color: #ffffff; }
    .bp3-tag.bp3-intent-primary.bp3-interactive {
      cursor: pointer; }
      .bp3-tag.bp3-intent-primary.bp3-interactive:hover {
        background-color: rgba(233, 78, 27, 0.85); }
      .bp3-tag.bp3-intent-primary.bp3-interactive.bp3-active, .bp3-tag.bp3-intent-primary.bp3-interactive:active {
        background-color: rgba(233, 78, 27, 0.7); }
  .bp3-tag.bp3-intent-success {
    background: #0f9960;
    color: #ffffff; }
    .bp3-tag.bp3-intent-success.bp3-interactive {
      cursor: pointer; }
      .bp3-tag.bp3-intent-success.bp3-interactive:hover {
        background-color: rgba(15, 153, 96, 0.85); }
      .bp3-tag.bp3-intent-success.bp3-interactive.bp3-active, .bp3-tag.bp3-intent-success.bp3-interactive:active {
        background-color: rgba(15, 153, 96, 0.7); }
  .bp3-tag.bp3-intent-warning {
    background: #d9822b;
    color: #ffffff; }
    .bp3-tag.bp3-intent-warning.bp3-interactive {
      cursor: pointer; }
      .bp3-tag.bp3-intent-warning.bp3-interactive:hover {
        background-color: rgba(217, 130, 43, 0.85); }
      .bp3-tag.bp3-intent-warning.bp3-interactive.bp3-active, .bp3-tag.bp3-intent-warning.bp3-interactive:active {
        background-color: rgba(217, 130, 43, 0.7); }
  .bp3-tag.bp3-intent-danger {
    background: #db3737;
    color: #ffffff; }
    .bp3-tag.bp3-intent-danger.bp3-interactive {
      cursor: pointer; }
      .bp3-tag.bp3-intent-danger.bp3-interactive:hover {
        background-color: rgba(219, 55, 55, 0.85); }
      .bp3-tag.bp3-intent-danger.bp3-interactive.bp3-active, .bp3-tag.bp3-intent-danger.bp3-interactive:active {
        background-color: rgba(219, 55, 55, 0.7); }
  .bp3-tag.bp3-fill {
    display: flex;
    width: 100%; }
  .bp3-tag.bp3-minimal > .bp3-icon, .bp3-tag.bp3-minimal .bp3-icon-standard, .bp3-tag.bp3-minimal .bp3-icon-large {
    fill: #ffffff; }
  .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]) {
    background-color: rgba(233, 78, 27, 0.2);
    color: #182026; }
    .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive {
      cursor: pointer; }
      .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive:hover {
        background-color: rgba(255, 255, 255, 0.3); }
      .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive.bp3-active, .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive:active {
        background-color: rgba(255, 255, 255, 0.4); }
    .bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]) {
      color: #3c3c3b; }
      .bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive {
        cursor: pointer; }
        .bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive:hover {
          background-color: rgba(255, 255, 255, 0.3); }
        .bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive.bp3-active, .bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive:active {
          background-color: rgba(255, 255, 255, 0.4); }
      .bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]) > .bp3-icon, .bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]) .bp3-icon-standard, .bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]) .bp3-icon-large {
        fill: #e94e1b; }
  .bp3-tag.bp3-minimal.bp3-intent-primary {
    background-color: rgba(233, 78, 27, 0.15);
    color: #e94e1b; }
    .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive {
      cursor: pointer; }
      .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive:hover {
        background-color: rgba(233, 78, 27, 0.25); }
      .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive.bp3-active, .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive:active {
        background-color: rgba(233, 78, 27, 0.35); }
    .bp3-tag.bp3-minimal.bp3-intent-primary > .bp3-icon, .bp3-tag.bp3-minimal.bp3-intent-primary .bp3-icon-standard, .bp3-tag.bp3-minimal.bp3-intent-primary .bp3-icon-large {
      fill: #e94e1b; }
    .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-primary {
      background-color: rgba(233, 78, 27, 0.25);
      color: #ffffff; }
      .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive {
        cursor: pointer; }
        .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive:hover {
          background-color: rgba(233, 78, 27, 0.35); }
        .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive.bp3-active, .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive:active {
          background-color: rgba(233, 78, 27, 0.45); }
  .bp3-tag.bp3-minimal.bp3-intent-success {
    background-color: rgba(15, 153, 96, 0.15);
    color: #0d8050; }
    .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive {
      cursor: pointer; }
      .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive:hover {
        background-color: rgba(15, 153, 96, 0.25); }
      .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive.bp3-active, .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive:active {
        background-color: rgba(15, 153, 96, 0.35); }
    .bp3-tag.bp3-minimal.bp3-intent-success > .bp3-icon, .bp3-tag.bp3-minimal.bp3-intent-success .bp3-icon-standard, .bp3-tag.bp3-minimal.bp3-intent-success .bp3-icon-large {
      fill: #0f9960; }
    .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-success {
      background-color: rgba(15, 153, 96, 0.25);
      color: #3dcc91; }
      .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive {
        cursor: pointer; }
        .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive:hover {
          background-color: rgba(15, 153, 96, 0.35); }
        .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive.bp3-active, .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive:active {
          background-color: rgba(15, 153, 96, 0.45); }
  .bp3-tag.bp3-minimal.bp3-intent-warning {
    background-color: rgba(217, 130, 43, 0.15);
    color: #bf7326; }
    .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive {
      cursor: pointer; }
      .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive:hover {
        background-color: rgba(217, 130, 43, 0.25); }
      .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive.bp3-active, .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive:active {
        background-color: rgba(217, 130, 43, 0.35); }
    .bp3-tag.bp3-minimal.bp3-intent-warning > .bp3-icon, .bp3-tag.bp3-minimal.bp3-intent-warning .bp3-icon-standard, .bp3-tag.bp3-minimal.bp3-intent-warning .bp3-icon-large {
      fill: #d9822b; }
    .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-warning {
      background-color: rgba(217, 130, 43, 0.25);
      color: #ffb366; }
      .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive {
        cursor: pointer; }
        .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive:hover {
          background-color: rgba(217, 130, 43, 0.35); }
        .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive.bp3-active, .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive:active {
          background-color: rgba(217, 130, 43, 0.45); }
  .bp3-tag.bp3-minimal.bp3-intent-danger {
    background-color: rgba(219, 55, 55, 0.15);
    color: #c23030; }
    .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive {
      cursor: pointer; }
      .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive:hover {
        background-color: rgba(219, 55, 55, 0.25); }
      .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive.bp3-active, .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive:active {
        background-color: rgba(219, 55, 55, 0.35); }
    .bp3-tag.bp3-minimal.bp3-intent-danger > .bp3-icon, .bp3-tag.bp3-minimal.bp3-intent-danger .bp3-icon-standard, .bp3-tag.bp3-minimal.bp3-intent-danger .bp3-icon-large {
      fill: #db3737; }
    .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-danger {
      background-color: rgba(219, 55, 55, 0.25);
      color: #ff7373; }
      .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive {
        cursor: pointer; }
        .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive:hover {
          background-color: rgba(219, 55, 55, 0.35); }
        .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive.bp3-active, .bp3-dark .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive:active {
          background-color: rgba(219, 55, 55, 0.45); }

.bp3-tag-remove {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  margin-bottom: -2px;
  margin-right: -6px !important;
  margin-top: -2px;
  opacity: 0.5;
  padding: 2px;
  padding-left: 0; }
  .bp3-tag-remove:hover {
    background: none;
    opacity: 0.8;
    text-decoration: none; }
  .bp3-tag-remove:active {
    opacity: 1; }
  .bp3-tag-remove:empty::before {
    font-family: "Icons16", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    content: ""; }
  .bp3-large .bp3-tag-remove {
    margin-right: -10px !important;
    padding: 5px;
    padding-left: 0; }
    .bp3-large .bp3-tag-remove:empty::before {
      font-family: "Icons20", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 1; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
CSS `border` property issues:
- An element can only have one border.
- Borders can't stack with shadows.
- Borders modify the size of the element they're applied to.
- Border positioning requires the extra `box-sizing` property.

`box-shadow` doesn't have these issues, we're using it instead of `border`.
*/
/*
Overlay shadows are used for default buttons
floating on top of other elements. This way, the
shadows blend with the colors beneath it.
Switches and slider handles both use these variables.
*/
.bp3-tag-input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: text;
  height: auto;
  line-height: inherit;
  min-height: 30px;
  padding-left: 5px;
  padding-right: 0; }
  .bp3-tag-input > * {
    flex-grow: 0;
    flex-shrink: 0; }
  .bp3-tag-input > .bp3-tag-input-values {
    flex-grow: 1;
    flex-shrink: 1; }
  .bp3-tag-input .bp3-tag-input-icon {
    color: #ffffff;
    margin-left: 2px;
    margin-right: 7px;
    margin-top: 7px; }
  .bp3-tag-input .bp3-tag-input-values {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    flex-wrap: wrap;
    margin-right: 7px;
    margin-top: 5px;
    min-width: 0; }
    .bp3-tag-input .bp3-tag-input-values > * {
      flex-grow: 0;
      flex-shrink: 0; }
    .bp3-tag-input .bp3-tag-input-values > .bp3-fill {
      flex-grow: 1;
      flex-shrink: 1; }
    .bp3-tag-input .bp3-tag-input-values::before,
    .bp3-tag-input .bp3-tag-input-values > * {
      margin-right: 5px; }
    .bp3-tag-input .bp3-tag-input-values:empty::before,
    .bp3-tag-input .bp3-tag-input-values > :last-child {
      margin-right: 0; }
    .bp3-tag-input .bp3-tag-input-values:first-child .bp3-input-ghost:first-child {
      padding-left: 5px; }
    .bp3-tag-input .bp3-tag-input-values > * {
      margin-bottom: 5px; }
  .bp3-tag-input .bp3-tag {
    overflow-wrap: break-word; }
    .bp3-tag-input .bp3-tag.bp3-active {
      outline: rgba(233, 78, 27, 0.6) auto 2px;
      outline-offset: 0;
      -moz-outline-radius: 6px; }
  .bp3-tag-input .bp3-input-ghost {
    flex: 1 1 auto;
    line-height: 20px;
    width: 80px; }
    .bp3-tag-input .bp3-input-ghost:disabled, .bp3-tag-input .bp3-input-ghost.bp3-disabled {
      cursor: not-allowed; }
  .bp3-tag-input .bp3-button,
  .bp3-tag-input .bp3-spinner {
    margin: 3px;
    margin-left: 0; }
  .bp3-tag-input .bp3-button {
    min-height: 24px;
    min-width: 24px;
    padding: 0 7px; }
  .bp3-tag-input.bp3-large {
    height: auto;
    min-height: 40px; }
    .bp3-tag-input.bp3-large::before,
    .bp3-tag-input.bp3-large > * {
      margin-right: 10px; }
    .bp3-tag-input.bp3-large:empty::before,
    .bp3-tag-input.bp3-large > :last-child {
      margin-right: 0; }
    .bp3-tag-input.bp3-large .bp3-tag-input-icon {
      margin-left: 5px;
      margin-top: 10px; }
    .bp3-tag-input.bp3-large .bp3-input-ghost {
      line-height: 30px; }
    .bp3-tag-input.bp3-large .bp3-button {
      min-height: 30px;
      min-width: 30px;
      padding: 5px 10px;
      margin: 5px;
      margin-left: 0; }
    .bp3-tag-input.bp3-large .bp3-spinner {
      margin: 8px;
      margin-left: 0; }
  .bp3-tag-input.bp3-active {
    background-color: #ffffff;
    box-shadow: 0 0 0 1px #e94e1b, 0 0 0 3px rgba(233, 78, 27, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-tag-input.bp3-active.bp3-intent-primary {
      box-shadow: 0 0 0 1px #e94e1b, 0 0 0 3px rgba(233, 78, 27, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-tag-input.bp3-active.bp3-intent-success {
      box-shadow: 0 0 0 1px #0d8050, 0 0 0 3px rgba(13, 128, 80, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-tag-input.bp3-active.bp3-intent-warning {
      box-shadow: 0 0 0 1px #bf7326, 0 0 0 3px rgba(191, 115, 38, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
    .bp3-tag-input.bp3-active.bp3-intent-danger {
      box-shadow: 0 0 0 1px #c23030, 0 0 0 3px rgba(194, 48, 48, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2); }
  .bp3-dark .bp3-tag-input .bp3-tag-input-icon, .bp3-tag-input.bp3-dark .bp3-tag-input-icon {
    color: #e94e1b; }
  .bp3-dark .bp3-tag-input .bp3-input-ghost, .bp3-tag-input.bp3-dark .bp3-input-ghost {
    color: #3c3c3b; }
    .bp3-dark .bp3-tag-input .bp3-input-ghost::placeholder, .bp3-tag-input.bp3-dark .bp3-input-ghost::placeholder {
      color: rgba(233, 78, 27, 0.6); }
  .bp3-dark .bp3-tag-input.bp3-active, .bp3-tag-input.bp3-dark.bp3-active {
    background-color: rgba(16, 22, 26, 0.3);
    box-shadow: 0 0 0 1px #e94e1b, 0 0 0 1px #e94e1b, 0 0 0 3px rgba(233, 78, 27, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-tag-input.bp3-active.bp3-intent-primary, .bp3-tag-input.bp3-dark.bp3-active.bp3-intent-primary {
      box-shadow: 0 0 0 1px #e94e1b, 0 0 0 3px rgba(233, 78, 27, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-tag-input.bp3-active.bp3-intent-success, .bp3-tag-input.bp3-dark.bp3-active.bp3-intent-success {
      box-shadow: 0 0 0 1px #0d8050, 0 0 0 3px rgba(13, 128, 80, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-tag-input.bp3-active.bp3-intent-warning, .bp3-tag-input.bp3-dark.bp3-active.bp3-intent-warning {
      box-shadow: 0 0 0 1px #bf7326, 0 0 0 3px rgba(191, 115, 38, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }
    .bp3-dark .bp3-tag-input.bp3-active.bp3-intent-danger, .bp3-tag-input.bp3-dark.bp3-active.bp3-intent-danger {
      box-shadow: 0 0 0 1px #c23030, 0 0 0 3px rgba(194, 48, 48, 0.3), inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4); }

.bp3-input-ghost {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0; }
  .bp3-input-ghost::placeholder {
    color: rgba(255, 255, 255, 0.6);
    opacity: 1; }
  .bp3-input-ghost:focus {
    outline: none !important; }

/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.bp3-toast {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  display: flex;
  margin: 20px 0 0;
  max-width: 500px;
  min-width: 300px;
  pointer-events: all;
  position: relative !important; }
  .bp3-toast.bp3-toast-enter, .bp3-toast.bp3-toast-appear {
    transform: translateY(-40px); }
  .bp3-toast.bp3-toast-enter-active, .bp3-toast.bp3-toast-appear-active {
    transform: translateY(0);
    transition-delay: 0;
    transition-duration: 300ms;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11); }
  .bp3-toast.bp3-toast-enter ~ .bp3-toast, .bp3-toast.bp3-toast-appear ~ .bp3-toast {
    transform: translateY(-40px); }
  .bp3-toast.bp3-toast-enter-active ~ .bp3-toast, .bp3-toast.bp3-toast-appear-active ~ .bp3-toast {
    transform: translateY(0);
    transition-delay: 0;
    transition-duration: 300ms;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.54, 1.12, 0.38, 1.11); }
  .bp3-toast.bp3-toast-exit {
    opacity: 1;
    filter: blur(0); }
  .bp3-toast.bp3-toast-exit-active {
    opacity: 0;
    filter: blur(10px);
    transition-delay: 0;
    transition-duration: 300ms;
    transition-property: opacity, filter;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-toast.bp3-toast-exit ~ .bp3-toast {
    transform: translateY(0); }
  .bp3-toast.bp3-toast-exit-active ~ .bp3-toast {
    transform: translateY(-40px);
    transition-delay: 50ms;
    transition-duration: 100ms;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-toast .bp3-button-group {
    flex: 0 0 auto;
    padding: 5px;
    padding-left: 0; }
  .bp3-toast > .bp3-icon {
    color: #ffffff;
    margin: 12px;
    margin-right: 0; }
  .bp3-toast.bp3-dark,
  .bp3-dark .bp3-toast {
    background-color: #ffffff;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4); }
    .bp3-toast.bp3-dark > .bp3-icon,
    .bp3-dark .bp3-toast > .bp3-icon {
      color: #e94e1b; }
  .bp3-toast[class*="bp3-intent-"] a {
    color: rgba(255, 255, 255, 0.7); }
    .bp3-toast[class*="bp3-intent-"] a:hover {
      color: #ffffff; }
  .bp3-toast[class*="bp3-intent-"] > .bp3-icon {
    color: #ffffff; }
  .bp3-toast[class*="bp3-intent-"] .bp3-button, .bp3-toast[class*="bp3-intent-"] .bp3-button::before,
  .bp3-toast[class*="bp3-intent-"] .bp3-button .bp3-icon, .bp3-toast[class*="bp3-intent-"] .bp3-button:active {
    color: rgba(255, 255, 255, 0.7) !important; }
  .bp3-toast[class*="bp3-intent-"] .bp3-button:focus {
    outline-color: rgba(255, 255, 255, 0.5); }
  .bp3-toast[class*="bp3-intent-"] .bp3-button:hover {
    background-color: rgba(255, 255, 255, 0.15) !important;
    color: #ffffff !important; }
  .bp3-toast[class*="bp3-intent-"] .bp3-button:active {
    background-color: rgba(255, 255, 255, 0.3) !important;
    color: #ffffff !important; }
  .bp3-toast[class*="bp3-intent-"] .bp3-button::after {
    background: rgba(255, 255, 255, 0.3) !important; }
  .bp3-toast.bp3-intent-primary {
    background-color: #e94e1b;
    color: #ffffff; }
  .bp3-toast.bp3-intent-success {
    background-color: #0f9960;
    color: #ffffff; }
  .bp3-toast.bp3-intent-warning {
    background-color: #d9822b;
    color: #ffffff; }
  .bp3-toast.bp3-intent-danger {
    background-color: #db3737;
    color: #ffffff; }

.bp3-toast-message {
  flex: 1 1 auto;
  padding: 11px;
  word-break: break-word; }

.bp3-toast-container {
  align-items: center;
  display: flex !important;
  flex-direction: column;
  left: 0;
  overflow: hidden;
  padding: 0 20px 20px;
  pointer-events: none;
  position: fixed;
  right: 0;
  z-index: 40; }
  .bp3-toast-container.bp3-toast-container-top {
    top: 0; }
  .bp3-toast-container.bp3-toast-container-bottom {
    bottom: 0;
    flex-direction: column-reverse;
    top: auto; }
  .bp3-toast-container.bp3-toast-container-left {
    align-items: flex-start; }
  .bp3-toast-container.bp3-toast-container-right {
    align-items: flex-end; }

.bp3-toast-container-bottom .bp3-toast.bp3-toast-enter:not(.bp3-toast-enter-active),
.bp3-toast-container-bottom .bp3-toast.bp3-toast-enter:not(.bp3-toast-enter-active) ~ .bp3-toast, .bp3-toast-container-bottom .bp3-toast.bp3-toast-appear:not(.bp3-toast-appear-active),
.bp3-toast-container-bottom .bp3-toast.bp3-toast-appear:not(.bp3-toast-appear-active) ~ .bp3-toast,
.bp3-toast-container-bottom .bp3-toast.bp3-toast-exit-active ~ .bp3-toast,
.bp3-toast-container-bottom .bp3-toast.bp3-toast-leave-active ~ .bp3-toast {
  transform: translateY(60px); }

/*
A mixin to generate the classes for a React CSSTransition which animates any number of CSS
properties at once.

Transitioned properties are specificed as a map of property names to lists of (inital value, final
value). For enter & appear transitions, each property will transition from its initial to its final
value. For exit transitions, each property will transition in reverse, from final to initial.

**Simple example:**
`@include react-transition("popover", (opacity: 0 1), $before: "&");`
Transition named "popover" moves opacity from 0 to 1. `"&"` indicates that the
Transition classes are expected to be applied to this element, where the mixin is invoked.

**Params:**
$name: React transitionName prop
$properties: map of CSS property to (initial, final) values
$duration: transition duration
$easing: transition easing function
$delay: transition delay
$before: selector text to insert before transition name (often to select self: &)
$after: selector text to insert after transiton name (to select children)
*/
/*
A mixin to generate the classes for one phase of a React CSSTransition.
`$phase` must be `appear` or `enter` or `exit`.
If `enter` phase is given then `appear` phase will be generated at the same time.
If `exit` phase is given then property values are animated in reverse, from final to initial.

**Example:**
@include react-transition-phase(toast, enter, $enter-translate, $before: "&");
@include react-transition-phase(toast, leave, $leave-blur, $pt-transition-duration * 3, $before: "&");
*/
/*
Given map of properties to values, set each property to the value at the given index.
(remember that sass indices are 1-based).

Example: `each-prop((opacity: 0 1), 2)` will print "opacity: 1"
*/
/*
Format transition class name with all the bits.
"enter" phase will include "appear" phase in returned name.
*/
.bp3-tooltip {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
  transform: scale(1); }
  .bp3-tooltip .bp3-popover-arrow {
    height: 22px;
    position: absolute;
    width: 22px; }
    .bp3-tooltip .bp3-popover-arrow::before {
      height: 14px;
      margin: 4px;
      width: 14px; }
  .bp3-tether-element-attached-bottom.bp3-tether-target-attached-top > .bp3-tooltip {
    margin-bottom: 11px;
    margin-top: -11px; }
    .bp3-tether-element-attached-bottom.bp3-tether-target-attached-top > .bp3-tooltip > .bp3-popover-arrow {
      bottom: -8px; }
      .bp3-tether-element-attached-bottom.bp3-tether-target-attached-top > .bp3-tooltip > .bp3-popover-arrow svg {
        transform: rotate(-90deg); }
  .bp3-tether-element-attached-left.bp3-tether-target-attached-right > .bp3-tooltip {
    margin-left: 11px; }
    .bp3-tether-element-attached-left.bp3-tether-target-attached-right > .bp3-tooltip > .bp3-popover-arrow {
      left: -8px; }
      .bp3-tether-element-attached-left.bp3-tether-target-attached-right > .bp3-tooltip > .bp3-popover-arrow svg {
        transform: rotate(0); }
  .bp3-tether-element-attached-top.bp3-tether-target-attached-bottom > .bp3-tooltip {
    margin-top: 11px; }
    .bp3-tether-element-attached-top.bp3-tether-target-attached-bottom > .bp3-tooltip > .bp3-popover-arrow {
      top: -8px; }
      .bp3-tether-element-attached-top.bp3-tether-target-attached-bottom > .bp3-tooltip > .bp3-popover-arrow svg {
        transform: rotate(90deg); }
  .bp3-tether-element-attached-right.bp3-tether-target-attached-left > .bp3-tooltip {
    margin-left: -11px;
    margin-right: 11px; }
    .bp3-tether-element-attached-right.bp3-tether-target-attached-left > .bp3-tooltip > .bp3-popover-arrow {
      right: -8px; }
      .bp3-tether-element-attached-right.bp3-tether-target-attached-left > .bp3-tooltip > .bp3-popover-arrow svg {
        transform: rotate(180deg); }
  .bp3-tether-element-attached-middle > .bp3-tooltip > .bp3-popover-arrow {
    top: 50%;
    transform: translateY(-50%); }
  .bp3-tether-element-attached-center > .bp3-tooltip > .bp3-popover-arrow {
    right: 50%;
    transform: translateX(50%); }
  .bp3-tether-element-attached-top.bp3-tether-target-attached-top > .bp3-tooltip > .bp3-popover-arrow {
    top: -0.22183px; }
  .bp3-tether-element-attached-right.bp3-tether-target-attached-right > .bp3-tooltip > .bp3-popover-arrow {
    right: -0.22183px; }
  .bp3-tether-element-attached-left.bp3-tether-target-attached-left > .bp3-tooltip > .bp3-popover-arrow {
    left: -0.22183px; }
  .bp3-tether-element-attached-bottom.bp3-tether-target-attached-bottom > .bp3-tooltip > .bp3-popover-arrow {
    bottom: -0.22183px; }
  .bp3-tether-element-attached-top.bp3-tether-element-attached-left > .bp3-tooltip {
    transform-origin: top left; }
  .bp3-tether-element-attached-top.bp3-tether-element-attached-center > .bp3-tooltip {
    transform-origin: top center; }
  .bp3-tether-element-attached-top.bp3-tether-element-attached-right > .bp3-tooltip {
    transform-origin: top right; }
  .bp3-tether-element-attached-middle.bp3-tether-element-attached-left > .bp3-tooltip {
    transform-origin: center left; }
  .bp3-tether-element-attached-middle.bp3-tether-element-attached-center > .bp3-tooltip {
    transform-origin: center center; }
  .bp3-tether-element-attached-middle.bp3-tether-element-attached-right > .bp3-tooltip {
    transform-origin: center right; }
  .bp3-tether-element-attached-bottom.bp3-tether-element-attached-left > .bp3-tooltip {
    transform-origin: bottom left; }
  .bp3-tether-element-attached-bottom.bp3-tether-element-attached-center > .bp3-tooltip {
    transform-origin: bottom center; }
  .bp3-tether-element-attached-bottom.bp3-tether-element-attached-right > .bp3-tooltip {
    transform-origin: bottom right; }
  .bp3-tooltip .bp3-popover-content {
    background: #ffffff;
    color: #3c3c3b; }
  .bp3-tooltip .bp3-popover-arrow::before {
    box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.2); }
  .bp3-tooltip .bp3-popover-arrow-border {
    fill: #10161a;
    fill-opacity: 0.1; }
  .bp3-tooltip .bp3-popover-arrow-fill {
    fill: #ffffff; }
  .bp3-popover-enter > .bp3-tooltip, .bp3-popover-appear > .bp3-tooltip {
    transform: scale(0.8); }
  .bp3-popover-enter-active > .bp3-tooltip, .bp3-popover-appear-active > .bp3-tooltip {
    transform: scale(1);
    transition-delay: 0;
    transition-duration: 100ms;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-popover-exit > .bp3-tooltip {
    transform: scale(1); }
  .bp3-popover-exit-active > .bp3-tooltip {
    transform: scale(0.8);
    transition-delay: 0;
    transition-duration: 100ms;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-tooltip .bp3-popover-content {
    padding: 10px 12px; }
  .bp3-tooltip.bp3-dark,
  .bp3-dark .bp3-tooltip {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.4), 0 8px 24px rgba(16, 22, 26, 0.4); }
    .bp3-tooltip.bp3-dark .bp3-popover-content,
    .bp3-dark .bp3-tooltip .bp3-popover-content {
      background: #3c3c3b;
      color: #ffffff; }
    .bp3-tooltip.bp3-dark .bp3-popover-arrow::before,
    .bp3-dark .bp3-tooltip .bp3-popover-arrow::before {
      box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.4); }
    .bp3-tooltip.bp3-dark .bp3-popover-arrow-border,
    .bp3-dark .bp3-tooltip .bp3-popover-arrow-border {
      fill: #10161a;
      fill-opacity: 0.2; }
    .bp3-tooltip.bp3-dark .bp3-popover-arrow-fill,
    .bp3-dark .bp3-tooltip .bp3-popover-arrow-fill {
      fill: #3c3c3b; }
  .bp3-tooltip.bp3-intent-primary .bp3-popover-content {
    background: #e94e1b;
    color: #ffffff; }
  .bp3-tooltip.bp3-intent-primary .bp3-popover-arrow-fill {
    fill: #e94e1b; }
  .bp3-tooltip.bp3-intent-success .bp3-popover-content {
    background: #0f9960;
    color: #ffffff; }
  .bp3-tooltip.bp3-intent-success .bp3-popover-arrow-fill {
    fill: #0f9960; }
  .bp3-tooltip.bp3-intent-warning .bp3-popover-content {
    background: #d9822b;
    color: #ffffff; }
  .bp3-tooltip.bp3-intent-warning .bp3-popover-arrow-fill {
    fill: #d9822b; }
  .bp3-tooltip.bp3-intent-danger .bp3-popover-content {
    background: #db3737;
    color: #ffffff; }
  .bp3-tooltip.bp3-intent-danger .bp3-popover-arrow-fill {
    fill: #db3737; }

.bp3-tooltip-indicator {
  border-bottom: dotted 1px;
  cursor: help; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
Trees

Markup:
<div class="bp3-tree bp3-elevation-0">
  <ul class="bp3-tree-node-list bp3-tree-root">
    <li class="bp3-tree-node bp3-tree-node-expanded">
      <div class="bp3-tree-node-content">
        <span class="bp3-tree-node-caret bp3-tree-node-caret-open bp3-icon-standard"></span>
        <span class="bp3-tree-node-icon bp3-icon-standard bp3-icon-folder-close"></span>
        <span class="bp3-tree-node-label">Label</span>
        <span class="bp3-tree-node-secondary-label">Secondary label</span>
      </div>
      <ul class="bp3-tree-node-list">
        <li class="bp3-tree-node">
          <div class="bp3-tree-node-content">
            <span class="bp3-tree-node-caret-none bp3-icon-standard"></span>
            <span class="bp3-tree-node-icon bp3-icon-standard bp3-icon-document"></span>
          <span class="bp3-tree-node-label">A Document</span>
          </div>
        </li>
        <li class="bp3-tree-node">
          <div class="bp3-tree-node-content">
            <span class="bp3-tree-node-caret-none bp3-icon-standard"></span>
            <span class="bp3-tree-node-icon bp3-icon-standard bp3-icon-document"></span>
            <span class="bp3-tree-node-label">Another Document</span>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</div>

Styleguide tree
*/
.bp3-tree .bp3-icon, .bp3-tree .bp3-icon-standard, .bp3-tree .bp3-icon-large {
  color: #ffffff; }
  .bp3-tree .bp3-icon.bp3-intent-primary, .bp3-tree .bp3-icon-standard.bp3-intent-primary, .bp3-tree .bp3-icon-large.bp3-intent-primary {
    color: #e94e1b; }
  .bp3-tree .bp3-icon.bp3-intent-success, .bp3-tree .bp3-icon-standard.bp3-intent-success, .bp3-tree .bp3-icon-large.bp3-intent-success {
    color: #0f9960; }
  .bp3-tree .bp3-icon.bp3-intent-warning, .bp3-tree .bp3-icon-standard.bp3-intent-warning, .bp3-tree .bp3-icon-large.bp3-intent-warning {
    color: #d9822b; }
  .bp3-tree .bp3-icon.bp3-intent-danger, .bp3-tree .bp3-icon-standard.bp3-intent-danger, .bp3-tree .bp3-icon-large.bp3-intent-danger {
    color: #db3737; }

.bp3-tree-node-list {
  list-style: none;
  margin: 0;
  padding-left: 0; }

.bp3-tree-root {
  background-color: transparent;
  cursor: default;
  padding-left: 0;
  position: relative; }

.bp3-tree-node-content-0 {
  padding-left: 0px; }

.bp3-tree-node-content-1 {
  padding-left: 23px; }

.bp3-tree-node-content-2 {
  padding-left: 46px; }

.bp3-tree-node-content-3 {
  padding-left: 69px; }

.bp3-tree-node-content-4 {
  padding-left: 92px; }

.bp3-tree-node-content-5 {
  padding-left: 115px; }

.bp3-tree-node-content-6 {
  padding-left: 138px; }

.bp3-tree-node-content-7 {
  padding-left: 161px; }

.bp3-tree-node-content-8 {
  padding-left: 184px; }

.bp3-tree-node-content-9 {
  padding-left: 207px; }

.bp3-tree-node-content-10 {
  padding-left: 230px; }

.bp3-tree-node-content-11 {
  padding-left: 253px; }

.bp3-tree-node-content-12 {
  padding-left: 276px; }

.bp3-tree-node-content-13 {
  padding-left: 299px; }

.bp3-tree-node-content-14 {
  padding-left: 322px; }

.bp3-tree-node-content-15 {
  padding-left: 345px; }

.bp3-tree-node-content-16 {
  padding-left: 368px; }

.bp3-tree-node-content-17 {
  padding-left: 391px; }

.bp3-tree-node-content-18 {
  padding-left: 414px; }

.bp3-tree-node-content-19 {
  padding-left: 437px; }

.bp3-tree-node-content-20 {
  padding-left: 460px; }

.bp3-tree-node-content {
  align-items: center;
  display: flex;
  height: 30px;
  padding-right: 5px;
  width: 100%; }
  .bp3-tree-node-content:hover {
    background-color: rgba(255, 255, 255, 0.4); }

.bp3-tree-node-caret,
.bp3-tree-node-caret-none {
  min-width: 30px; }

.bp3-tree-node-caret {
  color: #ffffff;
  cursor: pointer;
  padding: 7px;
  transform: rotate(0deg);
  transition: transform 200ms cubic-bezier(0.4, 1, 0.75, 0.9); }
  .bp3-tree-node-caret:hover {
    color: #182026; }
  .bp3-dark .bp3-tree-node-caret {
    color: #e94e1b; }
    .bp3-dark .bp3-tree-node-caret:hover {
      color: #3c3c3b; }
  .bp3-tree-node-caret.bp3-tree-node-caret-open {
    transform: rotate(90deg); }
  .bp3-tree-node-caret.bp3-icon-standard::before {
    content: ""; }

.bp3-tree-node-icon {
  margin-right: 7px;
  position: relative; }

.bp3-tree-node-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  flex: 1 1 auto;
  position: relative;
  user-select: none; }
  .bp3-tree-node-label span {
    display: inline; }

.bp3-tree-node-secondary-label {
  padding: 0 5px;
  user-select: none; }
  .bp3-tree-node-secondary-label .bp3-popover-wrapper,
  .bp3-tree-node-secondary-label .bp3-popover-target {
    align-items: center;
    display: flex; }

.bp3-tree-node.bp3-disabled .bp3-tree-node-content {
  background-color: inherit;
  color: rgba(255, 255, 255, 0.6);
  cursor: not-allowed; }

.bp3-tree-node.bp3-disabled .bp3-tree-node-caret,
.bp3-tree-node.bp3-disabled .bp3-tree-node-icon {
  color: rgba(255, 255, 255, 0.6);
  cursor: not-allowed; }

.bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content {
  background-color: #e94e1b; }
  .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content,
  .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content .bp3-icon, .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content .bp3-icon-standard, .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content .bp3-icon-large {
    color: #ffffff; }
  .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content .bp3-tree-node-caret::before {
    color: rgba(255, 255, 255, 0.7); }
  .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content .bp3-tree-node-caret:hover::before {
    color: #ffffff; }

.bp3-dark .bp3-tree-node-content:hover {
  background-color: rgba(255, 255, 255, 0.3); }

.bp3-dark .bp3-tree .bp3-icon, .bp3-dark .bp3-tree .bp3-icon-standard, .bp3-dark .bp3-tree .bp3-icon-large {
  color: #e94e1b; }
  .bp3-dark .bp3-tree .bp3-icon.bp3-intent-primary, .bp3-dark .bp3-tree .bp3-icon-standard.bp3-intent-primary, .bp3-dark .bp3-tree .bp3-icon-large.bp3-intent-primary {
    color: #e94e1b; }
  .bp3-dark .bp3-tree .bp3-icon.bp3-intent-success, .bp3-dark .bp3-tree .bp3-icon-standard.bp3-intent-success, .bp3-dark .bp3-tree .bp3-icon-large.bp3-intent-success {
    color: #0f9960; }
  .bp3-dark .bp3-tree .bp3-icon.bp3-intent-warning, .bp3-dark .bp3-tree .bp3-icon-standard.bp3-intent-warning, .bp3-dark .bp3-tree .bp3-icon-large.bp3-intent-warning {
    color: #d9822b; }
  .bp3-dark .bp3-tree .bp3-icon.bp3-intent-danger, .bp3-dark .bp3-tree .bp3-icon-standard.bp3-intent-danger, .bp3-dark .bp3-tree .bp3-icon-large.bp3-intent-danger {
    color: #db3737; }

.bp3-dark .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content {
  background-color: #e94e1b; }

.bp3-control.bp3-checkbox input:checked ~ .bp3-control-indicator::before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 0 0-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0 0 12 5z' fill='white'/%3e%3c/svg%3e"); }
